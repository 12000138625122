import { WizardInputUploadQuestion } from './WizardConfig';

export function autocompletePlaceholder(
  subType: WizardInputUploadQuestion['subType']
): string {
  switch (subType) {
    case 'organizations':
      return 'Search for organization names';
    case 'job-titles-keywords':
      return 'Search by job title or keyword';
    case 'people':
      return 'Search for names of people';
    case 'prospect-lists':
      return 'Search for existing prospect lists';
    case 'crm-deal-owner':
      return 'Search for CRM deal owners';
    case 'crm-owner':
      return 'Search for CRM contact owners';
    case 'crm-deal-stage':
      return 'Search for CRM deal stages';
    default:
      return '';
  }
}
