/**
 * @file This file will contain function which do anything related to regular expressions.
 */

export const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

// This fn replaces tabs, multiple spaces with single space.
export const replaceTabsWithSpace = (str: string) =>
  str.replace(/\s{2,}/g, ' ');
