import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

export interface LoadingIconForButtonProps
  extends Omit<CircularProgressProps, 'size'> {}

export function LoadingIconForButton(props: LoadingIconForButtonProps) {
  return <CircularProgress {...props} size={16} />;
}
