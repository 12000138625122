import React, { ReactElement } from 'react';
import { css } from '@emotion/css';
import { useTheme, Theme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { ClickableCard } from 'components/Prospects/ClickableCard';
import { SelectedFilterMap } from 'modules/event/types';
import { defaultStyles } from 'utils/theme';

interface OverflowCardGroupProps {
  objects: {
    title: string;
    subheader: string;
    url?: string;
    avatar: ReactElement;
    uuid: string;
  }[];
  children: ReactElement;
  keyPrefix?: string;
  setFilterState?: (sfm: SelectedFilterMap) => void;
}

// #region styles

const MoreButtonPopperStyle = (theme: Theme) => css`
  max-height: 300px;
  overflow: auto;
  border: 1px solid ${theme.palette.divider};
  border-radius: ${defaultStyles.borderRadius};
  box-shadow: ${defaultStyles.boxShadow};
`;

const MoreButtonTooltipStyle = (theme: Theme) => css`
  background-color: transparent;
  opacity: 1 !important;
  cursor: pointer;
  margin: 0 !important;
  padding: 0;
  .MuiPaper-root:not(:first-child) {
    border-top: 1px solid ${theme.palette.divider};
  }
  .MuiPaper-root {
    border: 0;
    border-radius: 0;
  }
`;

// #endregion

/**
 * OverflowCardGroup component displays a group of overflowable cards with a tooltip.
 *
 * @param {Array} objects - An array of objects containing information for the cards.
 * @param {ReactNode} children - Child components to be wrapped within the tooltip trigger.
 * @param {string} keyPrefix - Prefix for the keys of the cards.
 * @returns {JSX.Element} - The OverflowCardGroup JSX element.
 */
export const OverflowCardGroup = ({
  objects,
  children,
  keyPrefix = '',
  setFilterState,
}: OverflowCardGroupProps) => {
  const theme = useTheme();

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          className: MoreButtonTooltipStyle(theme),
        },
        popper: {
          className: MoreButtonPopperStyle(theme),
        },
      }}
      title={objects.map((object, index) => (
        <ClickableCard
          key={`${keyPrefix}-overflow-card-${index}`}
          title={object.title}
          subheader={object.subheader}
          url={object.url ?? ''}
          avatar={object.avatar}
          filterCallback={() => {
            setFilterState?.(new Map([[object.uuid, true]]));
          }}
        />
      ))}
    >
      {children}
    </Tooltip>
  );
};
