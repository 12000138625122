import { useMemo } from 'react';
import { prospectsApi } from 'redux/reducers/api/prospects';

// Custom hook to check if a UUID is valid
export const useProspectListUUIDValid = (uuid: string) => {
  const { data: myProspectLists } = prospectsApi.useGetMyProspectListsQuery();

  const prospectLists = useMemo(
    () => (!myProspectLists?.success ? [] : myProspectLists.prospect_lists),
    [myProspectLists]
  );

  const isValidUUID = useMemo(() => {
    return prospectLists.some((list) => list.uuid === uuid);
  }, [uuid, prospectLists]);

  return isValidUUID;
};
