import { useDispatch } from 'react-redux';
import { setSnacksQueue } from 'redux/reducers/snacks';

export function useSnacks() {
  const dispatch = useDispatch();

  function showErrorSnack(msg: string) {
    dispatch(
      setSnacksQueue({
        type: 'error',
        message: msg,
      })
    );
  }

  return {
    showErrorSnack,
  };
}
