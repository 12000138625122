import React, { ReactElement } from 'react';
import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import {
  ClickableCard,
  ClickableCardProps,
} from 'components/Prospects/ClickableCard';

interface AvatarTooltipProps extends ClickableCardProps {
  children: ReactElement;
  filterable?: boolean;
}

const TooltipStyle = (theme: Theme) => css`
  background-color: transparent;
  color: ${theme.palette.text.primary};
  opacity: 1 !important;
  cursor: pointer;
  margin: 0 !important;
  padding: 0;
`;

/**
 * AvatarTooltip Component:
 * Creates a themed tooltip with a Clickable Card containing title, subheader, and optional avatar.
 *
 * @param {Object} props - Component props including title, subheader, url, theme, children, and avatar.
 * @returns {ReactNode} The AvatarTooltip component.
 */
export const AvatarTooltip = ({
  title,
  subheader,
  url,
  children,
  avatar,
  filterable,
  filterCallback,
}: AvatarTooltipProps) => {
  const theme = useTheme();
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          className: TooltipStyle(theme),
        },
      }}
      title={
        <ClickableCard
          title={title}
          subheader={subheader}
          url={url}
          avatar={avatar}
          filterable={filterable}
          filterCallback={filterCallback}
        />
      }
    >
      {children}
    </Tooltip>
  );
};
