/**
 * This file defines the Vendelux color palette according to the Figma file:
 * https://www.figma.com/design/h3xa7wBuS5zD5C46Ecz3dU/Design-System
 *
 * Color Key:
 * - 50: White
 * - 100: Tint
 * - 500: Light
 * - 900: Primary
 * - 1700: Shade
 * - 1800: Black
 */

export const whiteKey = 50;
export const tintKey = 100;
export const lightKey = 500;
export const primaryKey = 900;
export const shadeKey = 1700;
export const blackKey = 1800;

export const vdxGray = {
  50: '#FFFFFF', // White
  100: '#F7F7FC', // Tint
  200: '#EDEDF2',
  300: '#DCDCE0',
  400: '#C5C5C9',
  500: '#ACACB0',
  600: '#939396',
  700: '#7A7A7D',
  800: '#626264',
  900: '#4C4C4E', // Primary
  1000: '#3B3B3D',
  1100: '#363638',
  1200: '#2C2C2E',
  1300: '#202022',
  1400: '#171719',
  1500: '#0A0A0C',
  1600: '#050506',
  1700: '#010101', // Shade
  1800: '#000000', // Black
};

export const vdxBlue = {
  50: '#FFFFFF', // White
  100: '#F4F2FC', // Tint
  200: '#E9E6FA',
  300: '#D1CBF5',
  400: '#B9B0F5',
  500: '#A89CF7',
  600: '#9384F5',
  700: '#7E6DF2',
  800: '#6A56F0',
  900: '#553FED', // Primary
  1000: '#432AEB',
  1100: '#3F2AD1',
  1200: '#3A28B8',
  1300: '#35269E',
  1400: '#2F2285',
  1500: '#281E6B',
  1600: '#201852',
  1700: '#130E2E', // Shade
  1800: '#000000', // Black
};

export const vdxPurple = {
  50: '#FFFFFF', // White
  100: '#F9F2FC', // Tint
  200: '#F3E6FA',
  300: '#E6C6F5',
  400: '#CFA1E5',
  500: '#C389E0',
  600: '#B87EDB',
  700: '#AE5CD6',
  800: '#A347D1',
  900: '#9932CC', // Primary
  1000: '#8730B2',
  1100: '#752C99',
  1200: '#622880',
  1300: '#512369',
  1400: '#411D54',
  1500: '#361945',
  1600: '#281333',
  1700: '#1A0D21', // Shade
  1800: '#000000', // Black
};

export const vdxPink = {
  50: '#FFFFFF', // White
  100: '#FCF2F9', // Tint
  200: '#FAE6F2',
  300: '#F5BFE1',
  400: '#F09ED2',
  500: '#F086CA',
  600: '#E067B4',
  700: '#E051AC',
  800: '#D6339B',
  900: '#D02090', // Primary
  1000: '#BD2084',
  1100: '#A82077',
  1200: '#99206D',
  1300: '#851E5F',
  1400: '#701C52',
  1500: '#5C1944',
  1600: '#451434',
  1700: '#290D1F', // Shade
  1800: '#000000', // Black
};

export const vdxRed = {
  50: '#FFFFFF', // White
  100: '#FCF2F3', // Tint
  200: '#FAE6E8',
  300: '#FAB4BA',
  400: '#F79CA3',
  500: '#F5BCBF',
  600: '#F5848E',
  700: '#F26D78',
  800: '#F05663',
  900: '#ED3F4E', // Primary
  1000: '#D43D4A',
  1100: '#BA3A44',
  1200: '#A1353E',
  1300: '#872F37',
  1400: '#6E292E',
  1500: '#542125',
  1600: '#3B181B',
  1700: '#210E10', // Shade
  1800: '#000000', // Black
};

export const vdxOrange = {
  50: '#FFFFFF', // White
  100: '#FCF5F2', // Tint
  200: '#FAEBE6',
  300: '#F7C7B7',
  400: '#F5D7CE',
  500: '#F5B49F',
  600: '#F2A288',
  700: '#F09071',
  800: '#ED7F5A',
  900: '#EB6A3F', // Primary
  1000: '#D1623D',
  1100: '#B85939',
  1200: '#9E4F34',
  1300: '#85442E',
  1400: '#6B3928',
  1500: '#522C20',
  1600: '#3B2018',
  1700: '#24140F', // Shade
  1800: '#000000', // Black
};

export const vdxYellow = {
  50: '#FFFFFF', // White
  100: '#FCF7EB', // Tint
  200: '#FAF0DC',
  300: '#FADB9B',
  400: '#F5E5C4',
  500: '#F5CE7F',
  600: '#F0C265',
  700: '#EBB64B',
  800: '#E5AA32',
  900: '#E09F1B', // Primary
  1000: '#C78E1C',
  1100: '#AD7D1C',
  1200: '#946B1B',
  1300: '#7A5A18',
  1400: '#614815',
  1500: '#473511',
  1600: '#2E230C',
  1700: '#141006', // Shade
  1800: '#000000', // Black
};

export const vdxGreen = {
  50: '#FFFFFF', // White
  100: '#F2FCF6', // Tint
  200: '#E3FAEB',
  300: '#C4F5D5',
  400: '#ABEDC1',
  500: '#91E3AC',
  600: '#7FDB9E',
  700: '#66CC88',
  800: '#51C277',
  900: '#40B868', // Primary
  1000: '#3DA660',
  1100: '#3A9458',
  1200: '#35824F',
  1300: '#307046',
  1400: '#295C3A',
  1500: '#22472E',
  1600: '#1A3624',
  1700: '#122418', // Shade
  1800: '#000000', // Black
};

export const vdxTeal = {
  50: '#FFFFFF', // White
  100: '#F2F9FC', // Tint
  200: '#E3F2FA',
  300: '#C6E5F5',
  400: '#A4D3EB',
  500: '#8AC5E3',
  600: '#73BADE',
  700: '#5FB0D9',
  800: '#4BA4D1',
  900: '#3697C7', // Primary
  1000: '#3186B0',
  1100: '#2C7599',
  1200: '#296887',
  1300: '#245973',
  1400: '#1F4B61',
  1500: '#1A3C4D',
  1600: '#142C38',
  1700: '#102029', // Shade
  1800: '#000000', // Black
};
