import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import BackspaceIcon from '@mui/icons-material/Backspace';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import CopyrightIcon from '@mui/icons-material/Copyright';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EastIcon from '@mui/icons-material/East';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FactoryIcon from '@mui/icons-material/Factory';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HailIcon from '@mui/icons-material/Hail';
import HandshakeIcon from '@mui/icons-material/Handshake';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HistoryIcon from '@mui/icons-material/History';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import IntegrationInstructionsOutlinedIcon from '@mui/icons-material/IntegrationInstructionsOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LanguageIcon from '@mui/icons-material/Language';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LoginIcon from '@mui/icons-material/Login';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MapIcon from '@mui/icons-material/Map';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaidIcon from '@mui/icons-material/Paid';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonIcon from '@mui/icons-material/Person';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PublishIcon from '@mui/icons-material/Publish';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShareIcon from '@mui/icons-material/Share';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import StarIcon from '@mui/icons-material/Star';
import StyleIcon from '@mui/icons-material/Style';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import WarningIcon from '@mui/icons-material/Warning';
import WorkIcon from '@mui/icons-material/Work';

/**
 * Maps strings to MUI icons. Used for selecting icons as a configuration parameter
 */
export const MuiIconManifest = {
  AccessTimeIcon,
  AccountBoxIcon,
  AccountCircleOutlinedIcon,
  AddCircleIcon,
  AddModeratorIcon,
  AddOutlinedIcon,
  ArticleOutlinedIcon,
  ArrowBackIosNewIcon,
  ArrowForwardIosIcon,
  ArrowRightAltIcon,
  AutoAwesomeOutlinedIcon,
  AutoGraphIcon,
  BackspaceIcon,
  BlockOutlinedIcon,
  BookmarkIcon,
  BusinessCenterIcon,
  CalendarMonthIcon,
  CancelScheduleSendIcon,
  CardGiftcardOutlinedIcon,
  CardMembershipOutlinedIcon,
  CategoryOutlinedIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleIcon,
  CloseIcon,
  CloudDownloadIcon,
  CloudUploadOutlinedIcon,
  CompareArrowsIcon,
  ContentCopyIcon,
  CorporateFareOutlinedIcon,
  CopyrightIcon,
  CreditCardIcon,
  DeleteIcon,
  DirectionsRunIcon,
  DriveFileRenameOutlineIcon,
  EastIcon,
  EditAttributesOutlinedIcon,
  EditIcon,
  ErrorOutlineIcon,
  EventNoteIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  FactoryIcon,
  FavoriteIcon,
  FilterAltOutlinedIcon,
  GroupAddIcon,
  GroupsOutlinedIcon,
  HailIcon,
  HandshakeIcon,
  HeartBrokenIcon,
  HelpOutlineIcon,
  HelpOutlineOutlinedIcon,
  HistoryIcon,
  HourglassEmptyIcon,
  InfoOutlinedIcon,
  InsertDriveFileIcon,
  IntegrationInstructionsOutlinedIcon,
  ImportExportIcon,
  LanguageIcon,
  LeaderboardOutlinedIcon,
  LocalActivityIcon,
  LocationCityIcon,
  LocationOnIcon,
  LockOpenIcon,
  LoginIcon,
  LogoutOutlinedIcon,
  ManageAccountsIcon,
  MapIcon,
  MenuIcon,
  MoreVertIcon,
  NavigateNextIcon,
  NotInterestedIcon,
  OpenInNewIcon,
  PaidIcon,
  PeopleOutlineIcon,
  PersonAddAltIcon,
  PersonIcon,
  PostAddIcon,
  PublishIcon,
  QueryStatsIcon,
  RemoveCircleOutlineOutlinedIcon,
  RemoveOutlinedIcon,
  RequestQuoteIcon,
  SaveIcon,
  SearchIcon,
  SettingsOutlinedIcon,
  ShareIcon,
  SkipNextIcon,
  SportsKabaddiIcon,
  StarIcon,
  StyleIcon,
  SupervisorAccountIcon,
  ToggleOffIcon,
  ToggleOnIcon,
  TrackChangesIcon,
  UploadFileOutlinedIcon,
  VisibilityIcon,
  VisibilityRoundedIcon,
  ViewColumnIcon,
  WarningIcon,
  WorkIcon,
};

export type MuiIconName = keyof typeof MuiIconManifest;
