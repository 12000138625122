import Loading from '../Widgets/Loading';
import React from 'react';

export function RedirectingLabel() {
  return (
    <Loading>
      You are being redirected to the login page. Please wait...
    </Loading>
  );
}
