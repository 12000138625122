import React from 'react';
import {
  Avatar,
  AvatarGroup,
  Stack,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material';
import { css } from '@emotion/css';
import { avatarColors } from 'utils/theme';
import { useTheme } from '@mui/material';
import { MuiIconManifest } from 'utils/iconManifest';

const avatarStyle = css`
  width: 32px;
  height: 32px;
`;

// #region Types

interface AvatarMatch {
  name: string;
  image: string;
  initials: string;
}

export interface AvatarData {
  matches: AvatarMatch[];
  additional_result_count: number;
}

export interface Avatars {
  id?: string; // algolia event hit uuid
  show: number;
  error: string;
  data: AvatarData;
  isLoading: boolean;
}

// #endregion

// #region smaller components

const StyledAvatar = ({
  name,
  image,
  initials,
  index,
}: AvatarMatch & { index: number }) => (
  <Tooltip key={`tooltip-${name}-${index}`} arrow title={name}>
    <Avatar
      sx={{ bgcolor: avatarColors[index % avatarColors.length] }}
      className={avatarStyle}
      alt={name}
      src={image}
    >
      <Typography variant="body2">{initials}</Typography>
    </Avatar>
  </Tooltip>
);

const SkeletonAvatar = () => (
  <Avatar className={avatarStyle}>
    <Skeleton variant="circular" animation="wave" width="100%" height="100%" />
  </Avatar>
);

const LoadingAvatars = () => (
  <AvatarGroup max={7}>
    {[...Array(7).keys()].map((_, index) => (
      <SkeletonAvatar key={`skeleton-${index}`} />
    ))}
  </AvatarGroup>
);

const ErrorAvatar = () => {
  const theme = useTheme();
  return (
    <Tooltip title="Something went wrong retrieving this data!" arrow>
      <Avatar
        className={avatarStyle}
        sx={{ bgcolor: theme.palette.error.main }}
      >
        <MuiIconManifest.ErrorOutlineIcon />
      </Avatar>
    </Tooltip>
  );
};

const AvatarsList = ({ data }: { data: AvatarData }) => (
  <>
    {data.additional_result_count ? (
      <Avatar key="additional" className={avatarStyle}>
        <Typography variant="body2">+{data.additional_result_count}</Typography>
      </Avatar>
    ) : null}
    {data.matches.map(({ name, image, initials }, index) => (
      <StyledAvatar
        key={index}
        name={name}
        image={image}
        initials={initials}
        index={index}
      />
    ))}
  </>
);

// #endregion

export const AvatarList = ({ avatars }: { avatars: Avatars }) => {
  const renderContent = () => {
    if (avatars.isLoading) return <LoadingAvatars />;
    if (avatars.error) return <ErrorAvatar />;
    if (avatars.data) return <AvatarsList data={avatars.data} />;
    return null;
  };

  if (!avatars.isLoading && (!avatars?.show || !avatars?.data.matches.length))
    return null;

  return (
    <Stack alignItems="center" direction="row" spacing={4}>
      <Tooltip arrow title="Event attendees">
        <MuiIconManifest.AccountBoxIcon color="primary" />
      </Tooltip>
      <AvatarGroup max={7}>{renderContent()}</AvatarGroup>
    </Stack>
  );
};
