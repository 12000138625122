/**
 * @file Define all paths for the application here. We should make this the source of truth for all paths ultimately.
 *
 * Inspired by Ruby on Rails path helpers.
 *
 * @returns
 */

// #region Prospect Lists

export const newWizardListPath = () => '/app/myprospects/lists/new';
export const editWizardListPath = (uuid: string) =>
  `/app/myprospects/lists/${uuid}/edit`;

export const editICPBuilderListPath = (uuid: string) =>
  `/app/improved-icp-list-interface/${uuid}?new=true&fullmode=true`;

export const editClassicBuilderListPath = (uuid: string) =>
  `/app/prospectlist/${uuid}`;

// #endregion
