import { Chip, SxProps, Theme, Tooltip, useTheme } from '@mui/material';
import React from 'react';

export function BetaBadge({ sx }: { sx?: SxProps<Theme> }) {
  const theme = useTheme();
  return (
    <Tooltip
      title="This feature is in beta and may change in the future."
      arrow
    >
      <Chip
        label="Beta"
        size="small"
        sx={{
          bgcolor: theme.palette.purple.light,
          color: theme.palette.purple.contrastText,
          textTransform: 'uppercase',
          ...sx,
        }}
      />
    </Tooltip>
  );
}
