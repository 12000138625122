import { Stack, Typography } from '@mui/material';
import { TextFieldElement, useForm } from 'react-hook-form-mui';
import {
  WizardConfig,
  WizardTextInputQuestion,
} from 'components/Wizard/WizardConfig';
import { WizardPagination } from 'components/Wizard/WizardPagination';
import { useHistory } from 'react-router';
import { Wizard } from 'components/Wizard/Wizard';
import { useRef } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export interface TextInputQuestionProps<TWizard = Wizard<WizardConfig>> {
  question: WizardTextInputQuestion;
  initialValue: string;
  wizard: TWizard;
  onSubmitForm: (value: string) => void;
}

export function TextInputQuestion({
  question,
  initialValue,
  wizard,
  onSubmitForm,
}: TextInputQuestionProps) {
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null);

  const defaultValues: Record<string, any> = {};
  defaultValues[question.name] = initialValue;

  const schema = z.object({
    [question.name]: z.string().min(1, {
      message: 'Please provide a value.',
    }),
  });

  type Schema = z.infer<typeof schema>;

  // Initialize form with default values from localStorage.
  const form = useForm<Schema>({
    defaultValues,
    resolver: zodResolver(schema),
  });
  function submitForm() {
    onSubmitForm(form.getValues()[question.name]);
  }

  function onNextClick() {
    formRef.current?.requestSubmit();
  }

  function onPreviousClick() {
    history.goBack();
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="h6">
          {question.label}
        </Typography>
        {question.description ? (
          <Typography variant="body2">{question.description}</Typography>
        ) : null}
        <form onSubmit={form.handleSubmit(submitForm)} ref={formRef}>
          <TextFieldElement
            fullWidth
            control={form.control}
            name={question.name}
            placeholder={question.placeholder}
          />
        </form>
      </Stack>
      <WizardPagination
        disabled={!form.formState.isValid}
        wizard={wizard}
        question={question}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />
    </Stack>
  );
}
