import { css } from '@emotion/css';
import {
  AutocompleteRenderInputParams,
  TextField,
  Theme,
  useTheme,
} from '@mui/material';
import React from 'react';

const inputStyle = (theme: Theme) => css`
  outline: none;
  border: none;
  padding: ${theme.spacing(0)}px;
  margin: 0;
  resize: none;
  height: 100%;
  width: 100%;
  & input {
    min-width: 140px !important;
  }
  & .MuiOutlinedInput-root {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    height: 100%;
    & .MuiOutlinedInput-notchedOutline {
      border: none;
      padding: 0;
      margin: 0;
      height: 100%;
    }
  }
`;

export interface TextFieldInputProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  placeholder: string;
  params: AutocompleteRenderInputParams;
}

export function TextFieldInput({
  inputValue,
  setInputValue,
  placeholder,
  params,
}: TextFieldInputProps) {
  const theme = useTheme();

  return (
    <TextField
      {...params}
      size="small"
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
      }}
      placeholder={placeholder}
      className={inputStyle(theme)}
    />
  );
}
