import React, { FunctionComponent } from 'react';
import { MuiIconManifest } from 'utils/iconManifest';

interface VerifiedBadgeProps {
  fontSize: 'medium' | 'large';
}

export const VerifiedBadge: FunctionComponent<VerifiedBadgeProps> = ({
  fontSize,
}) => (
  <MuiIconManifest.CheckCircleIcon
    style={{ color: '#18A2F2', paddingBottom: '0.2em' }}
    fontSize={fontSize}
  />
);
