import { withRouter } from 'react-router-dom';
import React from 'react';
import { AutocompleteImpl, AutocompleteImplProps } from './AutocompleteImpl';
import { RouteComponentProps } from 'react-router';
export type AutocompleteProps = Omit<
  AutocompleteImplProps,
  'routerPush' | 'routerLocationSearch'
>;

export const Autocomplete = withRouter(
  ({
    history: {
      push: routerPush,
      location: { search: routerLocationSearch },
    },
    ...props
  }: RouteComponentProps & AutocompleteProps) => (
    <AutocompleteImpl
      routerPush={routerPush}
      routerLocationSearch={routerLocationSearch}
      {...props}
    />
  )
);
