import React, { ComponentProps, PropsWithChildren } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { LoadingIcon } from './LoadingIcon';

interface LoadingProps {
  color?: ComponentProps<typeof Typography>['color'];
}

const _Loading = ({ children, color }: PropsWithChildren<LoadingProps>) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justifyContent="center"
    sx={{
      height: '100vh',
    }}
  >
    <Grid item xs={12} md={12}>
      <Box textAlign="center" margin={5}>
        <LoadingIcon />
        <Typography variant="h5" color={color}>
          {children}
        </Typography>
      </Box>
    </Grid>
  </Grid>
);

export const Loading = React.memo(_Loading);

export default Loading;
