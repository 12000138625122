import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from './reducers/api';
import modal from './reducers/modal';
import snacks from './reducers/snacks';
import ct_select from './reducers/ct_select';
import stripe_return from './reducers/stripe_return';
import search from './features/search';
import auth from './reducers/auth';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
  reducer: {
    modal,
    snacks,
    ct_select,
    stripe_return,
    search,
    auth,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: true, serializableCheck: false }).concat(
      api.middleware
    ),
});

setupListeners(store.dispatch);

export function useAppDispatch() {
  return useDispatch<typeof store.dispatch>();
}
export type AppDispatch = ReturnType<typeof useAppDispatch>;

export type AppState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
