import React, { useEffect, useMemo, useState } from 'react';
import {
  Tooltip,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SEARCH_DEBOUNCE_MS } from 'variables/constants';
import {
  GridLogicOperator,
  GridFilterModel,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { defaultStyles } from 'utils/theme';
import { debounce } from 'lodash';
import { LoadingIconForButton } from '../../Widgets/LoadingIconForButton';
import { MuiIconManifest } from 'utils/iconManifest';

interface GridSearchbarProps {
  filterModel: GridFilterModel;
  isDisabled?: boolean;
  placeholder?: string;
  mustBeOverThreeChars?: boolean;
  enableMatchAllTerms?: boolean;
}

export const GridSearchbar = ({
  filterModel,
  isDisabled = false,
  placeholder = 'Search for something…',
  mustBeOverThreeChars = false,
  enableMatchAllTerms = false,
}: GridSearchbarProps) => {
  const { current } = useGridApiContext();
  const theme = useTheme();

  const [searchText, setSearchText] = useState<string>(
    filterModel?.quickFilterValues?.join(' ') ?? ''
  );
  const [isSearching, setIsSearching] = useState(false);

  // update searchText when filterModel changes
  useEffect(() => {
    if (!filterModel) return;
    setSearchText(filterModel?.quickFilterValues?.join(' ') ?? '');
  }, [filterModel]);

  // if mustBeOverThreeChars is true, then we need to check if any search term is under 3 chars
  const anySearchTermUnderThreeChars = searchText
    .split(',')
    .map((substring) => substring.trim())
    .filter((substring) => substring !== '')
    .some((substring) => substring.length < 3);

  // debounce setting the filter model when search text changes by 500ms
  const debouncedSetFilterModel = useMemo(() => {
    return debounce((current, filterModel, searchText) => {
      if (!current) return;
      else if (
        filterModel &&
        searchText !== filterModel.quickFilterValues?.join(' ')
      ) {
        current.setFilterModel({
          ...filterModel,
          quickFilterValues: searchText.split(/\s*,\s*/),
        });
        setIsSearching(false);
      }
    }, SEARCH_DEBOUNCE_MS);
  }, []);

  // more options menu open state
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // boolean, must match all terms separated by comma
  const [mustMatchAllTerms, setMustMatchAllTerms] = useState(
    filterModel?.quickFilterLogicOperator === GridLogicOperator.And
  );

  return (
    <Tooltip
      open={mustBeOverThreeChars && anySearchTermUnderThreeChars}
      title="Each search term must be at least 3 characters long!"
      placement="top"
      arrow
      sx={{ height: '100%' }}
      componentsProps={{
        popper: {
          sx: {
            width: 200,
          },
        },
      }}
    >
      <TextField
        disabled={isDisabled}
        sx={{
          height: '100%',
          border: `1px solid ${
            searchText.length > 0
              ? theme.palette.primary.main
              : theme.palette.divider
          }`,
          borderRadius: defaultStyles.buttonBorderRadius,
          backgroundColor: theme.palette.background.paper,
          '.MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover:before':
            {
              borderBottom: 'none',
            },
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
        fullWidth
        placeholder={placeholder}
        // className={SearchBarStyle(theme)}
        value={searchText}
        onChange={(event) => {
          setSearchText(event.target.value);
          setIsSearching(true);
          debouncedSetFilterModel(current, filterModel, event.target.value);
        }}
        inputProps={{
          sx: {
            height: '100%',
            padding: theme.spacing(0.5, 1),
          },
        }}
        InputProps={{
          sx: {
            height: '100%',
          },
          startAdornment: (
            <InputAdornment position="start">
              {isSearching ? (
                <LoadingIconForButton sx={{ mr: 2 }} />
              ) : (
                <MuiIconManifest.SearchIcon />
              )}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={searchText === ''}
                sx={{ opacity: searchText === '' ? '0' : '1' }}
                size="small"
                color="error"
                onClick={() => {
                  setSearchText('');
                  current.setFilterModel({
                    ...filterModel,
                    quickFilterValues: [],
                  });
                }}
              >
                <MuiIconManifest.CloseIcon />
              </IconButton>
              {enableMatchAllTerms && (
                <IconButton size="small" onClick={handleClick}>
                  <MuiIconManifest.MoreVertIcon />
                </IconButton>
              )}
              <Paper>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  <MenuItem disabled onClick={handleClose}>
                    Search bar options
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      if (filterModel) {
                        current.setFilterModel({
                          ...filterModel,
                          quickFilterLogicOperator: mustMatchAllTerms
                            ? GridLogicOperator.Or
                            : GridLogicOperator.And,
                        });
                        setMustMatchAllTerms(!mustMatchAllTerms);
                      }
                    }}
                  >
                    <ListItemIcon>
                      {mustMatchAllTerms ? (
                        <MuiIconManifest.ToggleOnIcon color="success" />
                      ) : (
                        <MuiIconManifest.ToggleOffIcon color="disabled" />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      Match <b>{mustMatchAllTerms ? 'ALL' : 'ANY'}</b> terms,
                      separated by commas
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </Paper>
            </InputAdornment>
          ),
        }}
      />
    </Tooltip>
  );
};
