import { Avatar, Tooltip } from '@mui/material';
import React from 'react';

type ProfilePictureProps = {
  image: string;
  avatar?: string;
  title: string;
  size?: number;
  enableTooltip?: boolean;
  sx?: React.CSSProperties;
};

export function ProfilePhoto({
  image,
  title,
  size = 32,
  enableTooltip = true,
  sx = {},
}: ProfilePictureProps) {
  const avatar = (
    <Avatar
      alt={title}
      src={image}
      sx={{
        width: size,
        height: size,
        ...sx,
      }}
    />
  );

  if (enableTooltip) {
    return (
      <Tooltip title={title} placement="bottom" arrow>
        {avatar}
      </Tooltip>
    );
  }

  return avatar;
}
