import React, { ReactElement } from 'react';
import { css } from '@emotion/css';
import { Link } from 'react-router-dom';
import { Box, Card, CardHeader, IconButton } from '@mui/material';
import { MuiIconManifest } from 'utils/iconManifest';
export interface ClickableCardProps {
  title: string;
  subheader: string;
  url: string;
  avatar?: ReactElement;
  filterable?: boolean;
  filterCallback?: () => void;
}

const CardStyle = css`
  cursor: default;
`;

/**
 * ClickableCard component renders a card with a clickable action area.
 *
 * @param {string} title - The title text for the card.
 * @param {string} subheader - The subheader text for the card.
 * @param {string} url - The URL to navigate to when the card is clicked.
 * @param {string} avatar - The avatar for the card header.
 * @returns {JSX.Element} - The ClickableCard JSX element.
 */
export const ClickableCard = ({
  title,
  subheader,
  url,
  avatar,
  filterCallback,
  filterable,
}: ClickableCardProps) => {
  return (
    <Card variant="outlined">
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h6',
        }}
        subheader={subheader}
        avatar={avatar}
        className={CardStyle}
        action={
          filterable || url ? (
            <Box sx={{ pl: 2 }}>
              {filterable && (
                <IconButton aria-label="settings" onClick={filterCallback}>
                  <MuiIconManifest.FilterAltOutlinedIcon />
                </IconButton>
              )}
              {!!url && (
                <IconButton component={Link} to={url} aria-label="settings">
                  <MuiIconManifest.OpenInNewIcon />
                </IconButton>
              )}
            </Box>
          ) : undefined
        }
      />
    </Card>
  );
};
