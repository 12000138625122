import { createSlice } from '@reduxjs/toolkit';

export const stripe_return = createSlice({
  name: 'stripe_return',
  initialState: {
    return_values: {
      type: '',
      action: '',
      purchase: '',
      quantity: '',
      caller: '',
      session_id: '',
    },
  },
  reducers: {
    setStripeReturnValues: (state, action) => {
      let return_values = action.payload;
      return { ...state, return_values: return_values };
    },
  },
});

export const { setStripeReturnValues } = stripe_return.actions;

export default stripe_return.reducer;
