import dayjs, { Dayjs } from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { DateFilterKind } from 'modules/event/types';
import { MIN_MAX_DATE_NUM } from 'variables/constants';
import { DateRange } from '@mui/x-date-pickers-pro';
import { NonEmptyDateRange } from '@mui/x-date-pickers-pro/internals/models';

dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);

// function to dynamically load a locale
export const loadLocale = async (locale: string) => {
  if (locale !== 'en') {
    // 'en' is included by default in Day.js
    try {
      const importedLocale = await import(`dayjs/locale/${locale}`);
      dayjs.locale(importedLocale.default); // Set the locale for dayjs
    } catch (error) {
      console.error(`Locale ${locale} not found in dayjs`);
    }
  }
};

type DateOrString = Date | string;
type DateOrDayjs = Dayjs | DateOrString;

export function dayJsIsoDateFormat(d: DateOrString): string {
  return dayjs(d).toISOString().split('T')[0];
}

export function isoDateFromDayJs(d: Dayjs): string {
  return d.toISOString().split('T')[0];
}

export function dateRangeIsNonEmpty<T>(
  range: DateRange<T>
): range is NonEmptyDateRange<T> {
  return range[0] != null && range[1] != null;
}

export const getFormattedDateInterval = (
  startDate: DateOrDayjs | null | undefined,
  endDate: DateOrDayjs | null | undefined,
  locale: string = 'default'
): string => {
  const toDate = (input: DateOrDayjs): Date => {
    if (dayjs.isDayjs(input)) {
      return input.toDate();
    } else if (input instanceof Date) {
      return input;
    } else {
      return dayjs(input).toDate();
    }
  };

  // define date formatting options
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  // create a DateTimeFormat object with the provided locale and options
  const formatter = new Intl.DateTimeFormat(locale, options);

  if (startDate == null) {
    if (endDate == null) {
      return '';
    }
    return '? - ' + formatter.format(toDate(endDate));
  } else if (endDate == null) {
    return formatter.format(toDate(startDate)) + ' - ?';
  }

  const [start, end] =
    toDate(startDate) > toDate(endDate)
      ? [toDate(endDate), toDate(startDate)]
      : [toDate(startDate), toDate(endDate)];

  return formatter.formatRange(start, end);
};

export const getDateByDays = (prevDays: number): Date => {
  const now = dayjs();
  return now.subtract(prevDays, 'day').toDate();
};

/**
 * Takes in a Date object or string representation of a date (e.g. 12-3-2021) and returns the epoch timestamp
 */
export const getTimestamp = (d: DateOrString): number => {
  const date = d ? dayjs(d) : dayjs();
  return Math.floor(new Date(date.format('YYYY-MM-DD')).getTime() / 1000);
};

export const dateIsInThePast = (d: DateOrString): boolean => {
  const now = dayjs();
  return now.diff(d) > 0; //negative difference means its in the future
};

export const getUnixTimestamp = (d: DateOrString): number => {
  return dayjs(d || undefined).valueOf();
};

export const validDate = (d: DateOrString): boolean => {
  return dayjs(d).isValid();
};

export interface DateFilterRange {
  from: Dayjs;
  to: Dayjs;
}

export function checkInBetweenDateValues(
  dayToCheck: Dayjs,
  range: DateFilterRange
): boolean {
  return dayToCheck.isSameOrAfter(range.from) && dayToCheck.isBefore(range.to);
}

// check to see if an object is a moment-like object
export function isMomentLike(
  value: unknown | { $d: Date }
): value is { $d: Date } {
  return (
    typeof value === 'object' &&
    value != null &&
    '$d' in value &&
    value.$d instanceof Date
  );
}

export const defaultDateRange: DateFilterRange = {
  from: dayjs(new Date(MIN_MAX_DATE_NUM)),
  to: dayjs(new Date(-MIN_MAX_DATE_NUM)),
};
export const dateFilterValues: Map<DateFilterKind, DateFilterRange> = new Map([
  [
    DateFilterKind.Today,
    {
      from: dayjs().startOf('day'),
      to: dayjs().endOf('day'),
    },
  ],
  [
    DateFilterKind.LastWeek,
    {
      from: dayjs().startOf('day').subtract(7, 'day'),
      to: dayjs().endOf('day').subtract(1, 'day'),
    },
  ],
  [
    DateFilterKind.Last30Days,
    {
      from: dayjs().startOf('day').subtract(30, 'day'),
      to: dayjs().endOf('day').subtract(7, 'day'),
    },
  ],
  [
    DateFilterKind.Last3Months,
    {
      from: dayjs().startOf('day').subtract(90, 'day'),
      to: dayjs().endOf('day').subtract(30, 'day'),
    },
  ],
  [
    DateFilterKind.ThreePlusMonthsAgo,
    {
      from: dayjs(new Date(-MIN_MAX_DATE_NUM)),
      to: dayjs().endOf('day').subtract(90, 'day'),
    },
  ],
  [
    DateFilterKind.NextWeek,
    {
      from: dayjs().startOf('day').add(1, 'day'),
      to: dayjs().endOf('day').add(7, 'day'),
    },
  ],
  [
    DateFilterKind.Next30Days,
    {
      from: dayjs().startOf('day').add(1, 'day'),
      to: dayjs().endOf('day').add(30, 'day'),
    },
  ],
  [
    DateFilterKind.Next3Months,
    {
      from: dayjs().startOf('day').add(1, 'day'),
      to: dayjs().endOf('day').add(90, 'day'),
    },
  ],
  [
    DateFilterKind.ThreePlusMonthsFromNow,
    {
      from: dayjs().startOf('day').add(90, 'day'),
      to: dayjs(new Date(MIN_MAX_DATE_NUM)),
    },
  ],
  [DateFilterKind.None, defaultDateRange], // ensure defaultDateRange is defined
]);
