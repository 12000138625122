import { number_of_events } from 'variables/constants';

export const strings = {
  prospects: {
    error: {
      team_lists: 'Error fetching prospect lists',
      myprospects: 'Error fetching my prospects',
      follow_org_members: 'Error following org members',
      update_icp_params: (uuid: string) =>
        `Error updating ICP params for ${uuid}`,
      update_wizard_params: (uuid: string) => `Error updating list parameters.`,
      delete_list: (listName: string) =>
        `Error removing prospect list ${listName}`,
      create_list: (listName: string) =>
        `Error creating prospect list ${listName}`,
      rename_list: (listName: string) =>
        `Error renaming prospect list to ${listName}`,
      clone_list: (listName: string) =>
        `Error cloning prospect list ${listName}`,
      add_to_list: (listName: string) =>
        `Error adding to prospect list ${listName}`,
      remove_from_list: ({
        profileId,
        uuid,
      }: {
        profileId: string;
        uuid: string;
      }) => `Error removing ${profileId} from prospect list ${uuid}`,
      view_list_profiles: (uuid: string) =>
        `Error fetching profiles for ${uuid}`,
      view_list_organizations: (uuid: string) =>
        `Error fetching orgs for ${uuid}`,
      autocomplete_prospect_name: (term: string) =>
        `Error fetching prospects matching ${term}`,
      export_list: (filename: string) =>
        `Error exporting prospect list ${filename}`,
      enrich_list: (uuid: string) => `Error enriching prospect list ${uuid}`,
      change_roles: (uuid: string) =>
        `Error changing roles for prospect list ${uuid}`,
      transfer_ownership: (uuid: string) =>
        `Error transferring ownership for prospect list ${uuid}`,
      autocomplete_org: (query: string) =>
        `Error fetching organizations matching ${query}`,
      autocomplete_people: (query: string) =>
        `Error fetching people matching ${query}`,
      prospect_profiles: 'Error fetching prospect profiles.',
      prospect_orgs: 'Error fetching prospect organizations.',
      deal_values: 'Error fetching deal values',
    },
    success: {
      update_icp_params:
        'Your ICP parameters were saved successfully. Vendelux Fusion AI is working in the background, and this prospect list will be ready within 3 hours.',
      follow_org_members: (followOrgMembers: boolean) =>
        `Successfully ${
          followOrgMembers ? 'followed' : 'unfollowed'
        } org members.`,
      add_to_list: (listName: string) =>
        `Successfully added to prospect list ${listName}.`,
      change_roles: (uuid: string) =>
        `Successfully changed roles for prospect list ${uuid}.`,
      create_list: (listName: string) =>
        `Successfully created prospect list ${listName}. You will receive an email when your list is ready on events pages.`,
      rename_list: (listName: string) =>
        `Successfully renamed prospect list to ${listName}.`,
      delete_list: (listName: string) =>
        `Successfully removed prospect list ${listName}.`,
      export_list: (filename: string) =>
        `Successfully exported prospect list ${filename}.`,
      enrich_list: (uuid: string) =>
        `Successfully enriched prospect list ${uuid}.`,
      clone_list: (listName: string) =>
        `Successfully followed prospect list ${listName}.`,
      transfer_ownership: (uuid: string) =>
        `Successfully transferred ownership for prospect list ${uuid}`,
    },
  },
  profile: {
    success: {},
    error: {
      profile_summary: 'Error fetching profile summary',
      profile_data: 'Error fetching profile data',
      profile_team: 'Error fetching profile team',
      profile_engagement: 'Error fetching profile engagement',
      profile_top_topics: 'Error fetching profile top topics',
    },
  },
  user: {
    error: {
      state: 'Error fetching user state.',
      capabilities: 'Error fetching user capabilities.',
    },
  },
  action_alerts: {
    error: {
      stripe_checkout_failed:
        'Error creating alert for failed stripe session {sessionId}/',
      contact_sales: 'Error contacting sales for {action}',
    },
  },
  auth: {
    success: {
      resendVerificationEmail: 'Verification email resent.',
    },
    error: {
      login: 'Error logging in',
      resendVerificationEmail: 'Failed to resend verification email.',
    },
  },
  search: {
    algolia: {
      search_box_placeholder: `Search ${number_of_events}+ B2B events`,
    },
  },
  events: {
    number_of_events: number_of_events,
    success: {
      changeAttendance: 'Successfully changed attendance.',
      eventRequest: 'Thank you for your request!',
      eventCorrection: 'Thank you for your feedback!',
      enrichAttendees: (email: string) =>
        `We are preparing the CSV file now. It will be sent to ${email} when ready.`,
      uploadLeads: 'Successfully uploaded leads.',
      activateFusion: (eventName: string) =>
        `Fusion activated for ${eventName}. Please check back later to see new attendee data.`,
    },
    error: {
      logCSVDownload: 'Error logging CSV download.',
      eventSearch: 'Error fetching event search results.',
      eventRequest: 'Error requesting event.',
      eventCorrection: 'An unexpected error occured submitting your report.',
      latestEventAlert: (eventId: string) =>
        `Error creating alert for getting latest EDP for event in a series ${eventId}`,
      sponsorshipAlert: (eventId: string) =>
        `Error creating sponsorship alert for event ${eventId}`,
      sponsors: 'Error fetching sponsors.',
      attendees: 'Error fetching attendees.',
      attendeeSummaries: 'Error fetching attendee summaries.',
      attendeesWeekly: 'Error fetching event attendance timeline.',
      summary: 'Error fetching event summary.',
      changeAttendance: 'Error changing attendance.',
      teamMembers: 'Error fetching team members.',
      teamProspects: 'Error fetching team member prospects.',
      evaluateEvent: (eventId: string) => `Error evaluating event ${eventId}.`,
      enrichAttendees: 'Error exporting enriched attendees.',
      uploadLeads: 'Error uploading leads.',
      activateFusion: 'Error activating Fusion.',
      recommendedEvents: 'Error fetching recommended events.',
    },
  },
  org: {
    error: {
      historical_events: 'Error fetching historical events',
    },
  },
  leads: {
    success: {
      matchFields:
        'Success! You will receive an email when matching is complete.',
    },
    error: {
      upload: 'Error uploading leads',
      matchFields: 'Error matching fields',
    },
  },
  team: {
    error: {
      members: 'Error fetching team members.',
      get: 'Error fetching team data.',
      update: 'Error updating team data.',
    },
  },
  planner: {
    error: {
      events: 'Error fetching planner events.',
      update: 'Error updating planner event.',
      delete: 'Error deleting planner event.',
      views: 'Error fetching planner views.',
      createView: 'Error creating planner view.',
      updateView: 'Error updating planner view.',
      deleteView: 'Error deleting planner view.',
      uploadCSV: 'Error uploading CSV.',
      createColumns: 'Error creating planner columns.',
      updateColumn: 'Error updating planner column.',
      deleteColumn: 'Error deleting planner column.',
      updateColumnValue: 'Error updating the cell value.',
      deleteColumnValue: 'Error deleting the cell value.',
    },
    success: {
      createView: 'Successfully created planner view.',
      updateView: 'Successfully updated planner view.',
      deleteView: 'Successfully deleted planner view.',
      update: 'Successfully updated event.',
      delete: 'Successfully deleted event.',
      uploadCSV: 'Successfully uploaded CSV.',
      createColumns: 'Successfully created planner columns.',
      updateColumn: 'Successfully updated planner column.',
      deleteColumn: 'Successfully deleted planner column.',
      updateColumnValue: 'Successfully updated the cell value.',
      deleteColumnValue: 'Successfully deleted the cell value.',
    },
  },
  teamEvents: {
    error: {
      create: 'An unexpected error occurred while creating the private event.',
      update: 'An unexpected error occurred while editing the private event.',
      retrieve: 'An unexpected error occurred while retrieving the event.',
      venues: 'An unexpected error occurred while retrieving venues.',
    },
    success: {
      create: 'The private event was successfully created.',
      update: 'The private event was successfully updated.',
    },
  },
  settings: {
    preferences: {
      get: {
        defaultErrorMessage:
          'An unexpected error occurred while loading preferences',
      },
      patch: {
        defaultSuccessMessage: 'Preferences saved successfully',
        defaultErrorMessage:
          'An unexpected error occurred while saving preferences',
      },
    },

    error: {
      teamList: 'An unexpected error occurred while fetching team members ',
      inviteUser: 'An unexpected error occurred while inviting user',
      patchUserPermission:
        "An unexpected error occurred while changing user's permission",
      patchUserRole: "An unexpected error occurred while changing user's role",
      deleteUser: (userName: string) =>
        `An unexpected error occurred while deleting user ${userName}`,
      preferences: {
        get: 'An unexpected error occurred while loading team preferences',
        patch: 'An unexpected error occurred while saving team preferences',
      },
      getPreferences: 'Error loading team preferences',
      patchPreferences: 'Error saving team preferences',
    },
    success: {
      teamList: 'Successfully fetched team members',
      inviteUser: 'Successfully invited user',
      patchUserPermission: "Successfully changed user's permission",
      patchUserRole: "Successfully changed user's role",
      deleteUser: (userName: string) =>
        `Successfully deleted user ${userName} `,
      patchPreferences: 'Successfully saved team preferences',
    },
  },
  crm: {
    error: {
      crmFilters: 'Error fetching CRM filters',
    },
  },
  reports: {
    error: {
      eventReportList: 'Error fetching event reports.',
      eventReport: 'Error fetching event report.',
      createReport: 'Error creating event report.',
      deleteReport: 'Error deleting event report.',
      saveState: 'Error saving report filters.',
    },
    success: {
      eventReports: 'Event reports fetched successfully.',
      createReport: 'Event report created successfully.',
      deleteReport: 'Event report deleted successfully.',
      saveState: 'Report filters saved successfully.',
    },
  },
  options: {
    error: {
      prospectList: 'Error loading available prospect lists.',
    },
  },

  industry: {
    error: {
      subindustries: 'Error getting subindustries',
      industries: 'Error getting industries',
      industryGroups: 'Error getting industry groups',
      sectors: 'Error getting sectors',
    },
  },
};
