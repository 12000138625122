import { useEffect, useState } from 'react';
import {
  GridApi,
  gridFilterModelSelector,
  GridFilterModel,
  GridFilterItem,
} from '@mui/x-data-grid-premium';

function combineAndPreferSecond(
  arr1: GridFilterItem[],
  arr2: GridFilterItem[]
) {
  return Array.from(
    new Map([...arr1, ...arr2].map((it) => [it.id, it])).values()
  );
}

// custom hook to update the DataGrid filter model
export function useUpdateFilterModel(
  apiRef: React.MutableRefObject<GridApi>
): [GridFilterModel, (items: GridFilterItem[]) => void] {
  const filterModel = gridFilterModelSelector(
    apiRef.current.state,
    apiRef.current.instanceId
  );
  const [updateFunction, setUpdateFunction] = useState<
    (items: GridFilterItem[]) => void
  >(() => () => {});

  // effect to set the update function
  useEffect(() => {
    if (!apiRef.current) return;

    setUpdateFunction(() => (items: GridFilterItem[]) => {
      if (!apiRef.current) return;
      const filterModel = gridFilterModelSelector(
        apiRef.current.state,
        apiRef.current.instanceId
      );

      const dedupedArray = combineAndPreferSecond(
        filterModel.items,
        items
      ).filter((item) => item.operator !== 'delete');
      apiRef.current.upsertFilterItems(dedupedArray);
    });
  }, [apiRef]);

  return [filterModel, updateFunction];
}
