import { useEffect, useState } from 'react';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import { getRowCounts, RowCounterWorkerProps } from 'workers/getRowCounts';

// #region Types

export interface UseGetRowCountsProps<T extends GridValidRowModel>
  extends RowCounterWorkerProps<T> {
  skip?: boolean;
  loading?: boolean;
}

interface UseGetRowCountsReturn<T extends GridValidRowModel> {
  rowCounts: RowCounts<T>;
  isCalculating: boolean;
}

export type RowCounts<T extends GridValidRowModel> = Map<keyof T, PropCounts>;

export type PropCounts = Map<unknown, NumberCountObject>;

export type NumberCountObject = {
  count: number;
  rowIds: string[];
};

// #endregion

export const useGetRowCounts = <T extends GridValidRowModel>({
  rows,
  columns,
  skip,
  loading,
}: UseGetRowCountsProps<T>): UseGetRowCountsReturn<T> => {
  const [rowCounts, setRowCounts] = useState<RowCounts<T>>(new Map());
  const [isCalculating, setIsCalculating] = useState<boolean>(false);

  useEffect(() => {
    const inline = Boolean(
      localStorage.getItem('vdx_debug:useGetRowCounts:inline')
    );

    if (!rows || !columns) return;
    if (skip || loading) return;
    if (rows.length === 0 || columns.length === 0) return;

    // workers can only get serializable properties so we must clean this
    const cleanedColumns = columns
      .filter(
        (column) =>
          typeof column.filterable === 'undefined' || column.filterable
      ) // only deal with filterable columns (undefined === filterable)
      .map(({ type, field, valueGetterId, valueGetterKey }) => ({
        type,
        field,
        valueGetterId,
        valueGetterKey,
      }));

    if (inline) {
      const counts = getRowCounts({ rows, columns: cleanedColumns });
      setRowCounts(counts);
      return;
    }

    setIsCalculating(true);
    const worker = new Worker(
      new URL('workers/getRowCounts.worker.ts', import.meta.url),
      { type: 'module' }
    );
    worker.postMessage({ rows, columns: cleanedColumns });
    worker.onmessage = (event) => {
      setRowCounts(event.data);
      setIsCalculating(false);
      worker.terminate();
    };

    return () => {
      setIsCalculating(false);
      worker.terminate();
    };
  }, [rows, columns, skip, loading]);

  return {
    rowCounts,
    isCalculating,
  };
};
