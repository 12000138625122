import { setSnacksQueue } from 'redux/reducers/snacks';
import { AppDispatch } from 'redux/store';

const localStorageKey = 'vdx:loginFailed';

export function setLoginFailed(loginError?: string) {
  localStorage.setItem(localStorageKey, loginError ?? 'Login failed.');
}
export function checkLoginFailed(dispatch: AppDispatch) {
  const loginError = localStorage.getItem(localStorageKey);
  if (loginError != null) {
    dispatch(
      setSnacksQueue({
        type: 'error',
        message: loginError,
      })
    );
    localStorage.removeItem(localStorageKey);
  }
}
