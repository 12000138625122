import React from 'react';
import { HitsProvided, BasicDoc } from 'react-instantsearch-core';
import { connectHits } from 'react-instantsearch-dom';
import Grid from '@mui/material/Grid';
import AlgoliaEventCard from './AlgoliaEventCard';
import { EventForCard } from 'modules/search/types';

type EventCardAlgolia = BasicDoc & EventForCard;

function _Hits({ hits }: HitsProvided<EventCardAlgolia>) {
  return (
    <Grid container spacing={3} alignItems="stretch">
      {hits.map((event, index) => {
        return (
          <Grid
            key={`${event.uuid}-grid-${index}`}
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            display="flex"
          >
            <AlgoliaEventCard hit={event} />
          </Grid>
        );
      })}
    </Grid>
  );
}

const Hits = connectHits(_Hits);

export default Hits;
