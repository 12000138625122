import { GridOverlay } from '@mui/x-data-grid-premium';
import React, { ComponentProps, memo, ReactElement } from 'react';
import { Typography } from '@mui/material';
import { MuiIconManifest } from 'utils/iconManifest';

type NoRowsOverlayProps = {
  text: string;
  slotProps?: {
    gridOverlay?: Partial<ComponentProps<typeof GridOverlay>>;
    typography?: Partial<ComponentProps<typeof Typography>>;
    icon?: Partial<ComponentProps<typeof MuiIconManifest.ErrorOutlineIcon>>;
  };
  customIcon?: ReactElement;
};

export const NoRowsOverlay = memo(
  ({ text, slotProps, customIcon }: NoRowsOverlayProps) => {
    return (
      <GridOverlay
        sx={{
          position: 'unset', // override existing position with inline style
          display: 'flex',
          flexDirection: 'column',
        }}
        {...slotProps?.gridOverlay}
      >
        {typeof customIcon === 'undefined' ? (
          <MuiIconManifest.ErrorOutlineIcon
            fontSize="large"
            {...slotProps?.icon}
          />
        ) : (
          customIcon
        )}
        <Typography variant="body1" mt={1} {...slotProps?.typography}>
          {text}
        </Typography>
      </GridOverlay>
    );
  }
);

declare module '@mui/x-data-grid-premium' {
  interface NoRowsOverlayPropsOverrides extends NoRowsOverlayProps {}
}
