import useSWRImmutable from 'swr/immutable';
import cookies from 'js-cookie';

const useCancelableSWR = <Data, Error>(
  key: Parameters<typeof useSWRImmutable<Data, Error>>[0],
  opts: Parameters<typeof useSWRImmutable<Data, Error>>[2] & { url?: string },
  method: string = 'GET',
  body: {
    [key: string]: any;
  } = {}
) => {
  const controller = new AbortController();
  const csrfToken = cookies.get('csrftoken');

  const fetcher = (url: string) => {
    return fetch(opts?.url || url, {
      signal: controller.signal,
      method,
      ...(method === 'POST' && {
        headers: {
          'Content-Type': 'application/json',
          ...(csrfToken ? { 'X-CSRFToken': csrfToken } : {}),
        },
        body: JSON.stringify(body),
      }),
    }).then((res) => res.json());
  };

  const swrResult = useSWRImmutable<Data, Error>(key, fetcher, opts);
  return [
    swrResult,
    { controller, isLoading: swrResult.isValidating },
  ] as const;
};

export { useCancelableSWR };
