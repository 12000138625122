import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { DataGridFilterButton } from 'components/common/DataGrid/Buttons/FilterButton/DataGridFilterButton';
import { DataGridAddFilterButton } from 'components/common/DataGrid/Buttons/FilterButton/DataGridAddFilterButton';
import { GridSearchbar } from './GridSearchbar';
import { DataGridExportButton } from '../Buttons/DataGridExportButton';
import { RowCounts } from '../useGetRowCounts';
import { CustomGridColDef } from '../DataGridProvider';
import { ColumnsVisibilityPanelModalButton } from '../Buttons/ColumnsVisibilityPanelModalButton';
import { ClearButton } from './ClearButton';

export interface DataGridToolbarSlotProps {
  exportButton?: {
    hidden?: boolean;
    disabled?: boolean;
    filename?: string;
    exportLimit?: number;
  };
  columnVisibilityButton?: {
    disabled?: boolean;
    hidden?: boolean;
  };
  searchBar?: {
    mustBeOverThreeChars?: boolean;
    enableMatchAllTerms?: boolean;
  };
}
// props of the toolbar that are exposed to the page level that mounts DataGridProvider
export interface ExposedToolbarProps {
  disabled?: boolean;
  padded?: boolean;
  extraToolBarButtons?: (loading: boolean) => ReactNode; // a function to render any extra buttons to add to the toolbar (next to the export button)
  slotProps?: DataGridToolbarSlotProps;
}

export interface DataGridToolbarProps<T extends GridValidRowModel>
  extends ExposedToolbarProps {
  customColumns: CustomGridColDef<T>[]; // our own custom definition of GridColDef
  columnVisibilityModel: GridColumnVisibilityModel;
  filteredRowCounts: RowCounts<T>;
  totalRowCounts: RowCounts<T>;
  isLoading?: boolean;
  singularRowName?: string; // the singular (not plural) name of each row (e.g. attendee, prospect, etc.)
}

export const DataGridToolbar = <T extends GridValidRowModel>({
  customColumns,
  columnVisibilityModel,
  totalRowCounts,
  filteredRowCounts,
  isLoading = false,
  padded = false,
  disabled,
  slotProps,
  singularRowName,
  extraToolBarButtons,
}: DataGridToolbarProps<T>) => {
  const { current } = useGridApiContext();
  const filterModel = current.state.filter.filterModel;
  const aggregationModel = current.state.aggregation.model;

  const defaultFilterFields = useMemo(
    () =>
      customColumns
        .filter(
          (col) =>
            !!col.defaultFilter ||
            filterModel.items.some((f) => f.field === col.field)
        )
        .map((col) => col.field),
    [customColumns, filterModel]
  );

  const dataGridColumns = current.getAllColumns(); // get the columns from the DataGrid (with datagrid props like "filterable")

  // combine the columns from the DataGrid with the columns passed in
  const columns: CustomGridColDef<T>[] = useMemo(() => {
    return dataGridColumns.map((col) => {
      const customCol = customColumns.find(
        (customCol) => customCol.field === col.field
      );
      return customCol ? { ...col, ...customCol } : col;
    });
  }, [dataGridColumns, customColumns]);

  const filterableColumns = columns.filter((column) => column.filterable);

  // const [visibleFilterFields, setVisibleFilterFields] =
  //   useState<Array<GridColDef<T>['field']>>(defaultFilterFields);

  // ref to keep track of the most recently added filter (so we can immediately display it as open)
  // const mostRecentlyAddedFilterRef = useRef<string | null>(null);
  const [mostRecentlyAddedFilter, setMostRecentlyAddedFilter] =
    useState<string>();

  const visibleFilterFields = useMemo(() => {
    return mostRecentlyAddedFilter
      ? [...defaultFilterFields, mostRecentlyAddedFilter]
      : defaultFilterFields;
  }, [defaultFilterFields, mostRecentlyAddedFilter]);

  const visibleFilterableColumns = filterableColumns.filter((column) =>
    visibleFilterFields.includes(column.field)
  );
  const hiddenFilterableColumns = filterableColumns.filter(
    (column) => !visibleFilterFields.includes(column.field)
  );

  // remove a filter button (didn't select anything after adding a new filter)
  const handleRemoveFilterButton = useCallback(
    (column: CustomGridColDef<T>) => {
      setMostRecentlyAddedFilter(undefined);
      // setVisibleFilterFields((prev) => prev.filter((f) => f !== column.field));

      // if we're deleting a filter for a hidden column, hide the column
      if (column.hidden) {
        current.setColumnVisibility(column.field, false);
      }
    },
    [current]
  );

  // show a filter button
  const handleAddFilterButton = useCallback((field: GridColDef<T>['field']) => {
    setMostRecentlyAddedFilter(field);
    // setVisibleFilterFields((prev) => [...prev, field]);
  }, []);

  const resetFilters = useCallback(() => {
    current.setFilterModel({ items: [], quickFilterValues: [] });
    // setVisibleFilterFields(defaultFilterFields);
  }, [current]);

  const resetAggregation = useCallback(() => {
    current.setAggregationModel({});
  }, [current]);

  const filtersActive = useMemo(() => {
    return (
      filterModel.items.length > 0 ||
      (filterModel.quickFilterValues &&
        filterModel.quickFilterValues.length > 0 &&
        filterModel.quickFilterValues[0] !== '')
    );
  }, [filterModel]);

  return (
    <Stack spacing={2} sx={{ p: padded ? 2 : 0, pb: padded ? 0 : 2 }}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Grid item flexGrow={1}>
          <GridSearchbar
            isDisabled={isLoading || disabled}
            filterModel={filterModel}
            placeholder={`Search for ${singularRowName}…`}
            {...slotProps?.searchBar}
          />
        </Grid>
        {!slotProps?.columnVisibilityButton?.hidden ? (
          <Grid item>
            <ColumnsVisibilityPanelModalButton
              columnVisibilityModel={columnVisibilityModel}
              loading={isLoading}
              slotProps={{
                button: {
                  variant: 'roundedOutlined',
                  disabled:
                    disabled || slotProps?.columnVisibilityButton?.disabled,
                },
              }}
            />
          </Grid>
        ) : null}
        {!slotProps?.exportButton?.hidden ? (
          <Grid item>
            <DataGridExportButton
              exportLimit={slotProps?.exportButton?.exportLimit}
              filename={slotProps?.exportButton?.filename ?? 'export'}
              isLoading={isLoading}
              isDisabled={disabled || slotProps?.exportButton?.disabled}
              singularRowName={singularRowName}
            />{' '}
          </Grid>
        ) : null}
        {/* render any amount of extra buttons as needed */}
        {typeof extraToolBarButtons !== 'undefined'
          ? extraToolBarButtons(isLoading)
          : null}
      </Stack>
      <Box sx={{ ml: -4 }}>
        <Grid container spacing={2} sx={{ flexWrap: 'wrap', flexShrink: 0 }}>
          {visibleFilterableColumns.map((column, index) => {
            return (
              <Grid key={`${column.field}-${index}`} item>
                <DataGridFilterButton
                  column={column}
                  totalCounts={totalRowCounts.get(column.field)}
                  filteredRowCounts={filteredRowCounts.get(column.field)}
                  isLoading={isLoading}
                  isDisabled={disabled}
                  isOpen={mostRecentlyAddedFilter === column.field}
                  handleRemoveFilterButton={handleRemoveFilterButton}
                />
              </Grid>
            );
          })}
          {hiddenFilterableColumns.length > 0 ? (
            <Grid item>
              <DataGridAddFilterButton<T>
                isLoading={isLoading}
                isDisabled={disabled}
                hiddenFilterableColumns={hiddenFilterableColumns}
                handleOnClick={(column) => {
                  handleAddFilterButton(column.field);
                  // any other onclicks
                }}
              />
            </Grid>
          ) : null}
          {filtersActive ? (
            <Grid item>
              <ClearButton text="Clear filters" resetFn={resetFilters} />
            </Grid>
          ) : null}
          {Object.keys(aggregationModel).length > 0 ? (
            <Grid item>
              <ClearButton
                text="Clear aggregation"
                resetFn={resetAggregation}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Stack>
  );
};
