import React, { SyntheticEvent } from 'react';
import { Button, Grid } from '@mui/material';
import { AnyWizardQuestion, WizardConfig } from './WizardConfig';
import { Wizard } from './Wizard';
import { sendAnalyticsEvent, sendPendoEvent } from 'analytics';
import { MuiIconManifest } from 'utils/iconManifest';

export interface WizardPaginationProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  question: AnyWizardQuestion;
  onPreviousClick: (e: SyntheticEvent) => void;
  onNextClick?: (e: SyntheticEvent) => void;
  finalQuestion?: boolean;
  canGoBack?: boolean;
  canGoForward?: boolean;
  disabled?: boolean;
}

export function trackWizardPaginationButtonClick(
  eventLabel: string,
  wizard: Wizard,
  question: AnyWizardQuestion
) {
  const params = {
    questionId: question.id,
    wizardId: wizard.config.id,
    wizardVersion: wizard.config.version,
    questionLabel: question.label,
    questionType: question.type,
    questionSubType: question.subType || '',
  };
  sendPendoEvent(eventLabel, params);
  sendAnalyticsEvent('List Wizard', eventLabel, undefined, undefined, params);
}

export function trackPrevButtonClick(
  wizard: Wizard,
  question: AnyWizardQuestion
) {
  trackWizardPaginationButtonClick(
    'List Wizard > Previous Button Clicked',
    wizard,
    question
  );
}

export function trackNextButtonClick(
  wizard: Wizard,
  question: AnyWizardQuestion
) {
  trackWizardPaginationButtonClick(
    'List Wizard > Next Button Clicked',
    wizard,
    question
  );
}

/**
 * Prev/next buttons for use in wizard question components.
 *
 * This is included manually in each question component since:
 * 1. Not all question types have pagination.
 * 2. Question types operate a bit differently.
 *
 * In the future we may make this more generic and include it once within <AnyWizardQuestionPage />.
 */
export function WizardPagination({
  wizard,
  question,
  onNextClick,
  onPreviousClick,
  canGoBack = true,
  canGoForward = true,
  disabled,
}: WizardPaginationProps) {
  const previousButtonText = wizard.getPreviousButtonText(question.id);
  const nextButtonText = wizard.getNextButtonText(question.id);

  const combinedOnPreviousClick = (e: SyntheticEvent) => {
    trackPrevButtonClick(wizard, question);
    onPreviousClick(e);
  };

  const combinedOnNextClick = (e: SyntheticEvent) => {
    trackNextButtonClick(wizard, question);
    onNextClick?.(e);
  };

  return (
    <Grid container mt={20} alignItems="center" justifyContent="space-between">
      <Grid item>
        {canGoBack ? (
          <Button
            variant="outlined"
            onClick={combinedOnPreviousClick}
            startIcon={<MuiIconManifest.ArrowBackIosNewIcon />}
          >
            {previousButtonText}
          </Button>
        ) : null}
      </Grid>
      <Grid ml={2} item>
        {canGoForward ? (
          <Button
            variant="contained"
            disabled={disabled}
            onClick={combinedOnNextClick}
            endIcon={
              wizard.mode === 'create' ? (
                <MuiIconManifest.ArrowForwardIosIcon />
              ) : null
            }
          >
            {nextButtonText}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
}
