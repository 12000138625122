import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const ensureStringArray = (
  value: string | string[] | qs.ParsedQs | qs.ParsedQs[] | undefined
): string[] => {
  if (Array.isArray(value)) {
    return (value as unknown[]).filter(
      (item): item is string => typeof item === 'string'
    );
  }
  if (typeof value === 'string') {
    return [value];
  }
  return [];
};

export const useQueryParams = (
  paramNames: string[]
): { queryParams: { [key: string]: any } } => {
  const location = useLocation();

  const queryParams = useMemo(() => {
    const parsedParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const result: { [key: string]: any } = { ...parsedParams };

    paramNames.forEach((paramName) => {
      result[paramName] = ensureStringArray(parsedParams[paramName]);
    });

    return result;
  }, [location.search, paramNames]);

  return { queryParams };
};
