import React, { PropsWithChildren } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { appLogout } from 'utils/appLogout';

const LogoutButton = ({ children }: PropsWithChildren<{}>) => {
  const { logout } = useAuth0();
  return (
    <div
      onClick={() => {
        appLogout(logout, { local: true });
      }}
    >
      {children || 'Logout'}
    </div>
  );
};

export default LogoutButton;
