import { createSlice } from '@reduxjs/toolkit';

export interface SearchSliceState {
  selectedProspects: string[];
  selectedProspectLists: string[];
}

const search = createSlice({
  name: 'search',
  initialState: {
    selectedProspects: [],
    selectedProspectLists: [],
  },
  reducers: {
    setSelectedProspects: (state, action) => {
      return {
        ...state,
        selectedProspects: action.payload,
      };
    },
    setSelectedProspectLists: (state, action) => {
      return {
        ...state,
        selectedProspectLists: action.payload,
      };
    },
  },
});

export const { setSelectedProspects, setSelectedProspectLists } =
  search.actions;

export default search.reducer;
