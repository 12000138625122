import React from 'react';
import { Box, BoxProps } from '@mui/material';

export interface LoadingIconProps extends BoxProps {}

export function LoadingIcon(props: LoadingIconProps) {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      height="100%"
      zIndex="4"
      {...props}
    >
      <div
        className="vdxLogo-icon lg loading-fill-blue"
        style={{ width: '160px' }}
      />
    </Box>
  );
}
