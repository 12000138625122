import { api } from './index';
import { strings } from '../../../variables/messages';
import { endpoints } from 'variables/endpoint-urls';
import { reportResultInSnack } from './util';
import { Category } from 'components/common/Inputs/TreeSelectAutocomplete';
import { ApiResponse } from 'utils/api-response';

// #region Types

type Subindustries = string[];
type Industries = { industry: string; subindustries: Subindustries }[];
type IndustryGroups = {
  industry_group: string;
  industries: Industries;
}[];
type Sectors = {
  sector: string;
  industry_groups: IndustryGroups;
}[];

type IndustryTag =
  | 'Subindustries'
  | 'Industries'
  | 'IndustryGroups'
  | 'Sectors';

// #endregion

// #region Helper Functions

// Subindustries are just strings, so we need a separate function to format them
function formatSubindustriesFromApi(
  res: ApiResponse<Subindustries>
): Category<string>[] {
  if (!res.success) throw new TypeError(res.message);
  return res.results.map((subindustry) => ({
    label: subindustry,
    value: subindustry,
  }));
}

// generic function to format Industries, IndustryGroups, and Sectors
function formatCategories<T extends Record<string, any>>(
  res: ApiResponse<T[]>,
  labelKey: keyof T,
  childrenKey?: keyof T,
  formatChildren?: (res: ApiResponse<any>) => Category<string>[]
): Category<string>[] {
  if (!res.success) throw new TypeError(res.message);
  return res.results.map((item) => ({
    label: item[labelKey] as string,
    value: item[labelKey] as string,
    childCategories:
      childrenKey && formatChildren
        ? formatChildren({
            ...res,
            results: item[childrenKey] as any[],
          })
        : undefined,
  }));
}

function formatIndustriesFromApi(
  res: ApiResponse<Industries>
): Category<string>[] {
  return formatCategories(
    res,
    'industry',
    'subindustries',
    formatSubindustriesFromApi
  );
}

function formatIndustryGroupsFromApi(
  res: ApiResponse<IndustryGroups>
): Category<string>[] {
  return formatCategories(
    res,
    'industry_group',
    'industries',
    formatIndustriesFromApi
  );
}

function formatSectorsFromApi(res: ApiResponse<Sectors>): Category<string>[] {
  return formatCategories(
    res,
    'sector',
    'industry_groups',
    formatIndustryGroupsFromApi
  );
}

// Helper function to create endpoint configurations
function createIndustryEndpoint<T>(
  url: string,
  transformResponse: (res: ApiResponse<T>) => Category<string>[],
  defaultErrorMessage: string,
  tag: IndustryTag
) {
  return {
    query: () => ({
      url,
      method: 'GET',
    }),
    providesTags: [tag] as const,
    transformResponse,
    onQueryStarted: (args: void, { dispatch, queryFulfilled }: any) => {
      reportResultInSnack({
        dispatch,
        queryFulfilled,
        forceSuccess: true, // set true if using extractResults above in transformResponse
        defaultErrorMessage,
      });
    },
  };
}

// #endregion

export const industryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubindustries: builder.query<Category<string>[], void>(
      createIndustryEndpoint(
        endpoints.industry.get.subindustries,
        formatSubindustriesFromApi,
        strings.industry.error.subindustries,
        'Subindustries'
      )
    ),
    getIndustries: builder.query<Category<string>[], void>(
      createIndustryEndpoint(
        endpoints.industry.get.industries,
        formatIndustriesFromApi,
        strings.industry.error.industries,
        'Industries'
      )
    ),
    getIndustryGroups: builder.query<Category<string>[], void>(
      createIndustryEndpoint(
        endpoints.industry.get.industryGroups,
        formatIndustryGroupsFromApi,
        strings.industry.error.industryGroups,
        'IndustryGroups'
      )
    ),
    getSectors: builder.query<Category<string>[], void>(
      createIndustryEndpoint(
        endpoints.industry.get.sectors,
        formatSectorsFromApi,
        strings.industry.error.sectors,
        'Sectors'
      )
    ),
  }),
});
