// TODO: change breadcrumbs name to stepper
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  StepContent,
  Box,
  Stack,
} from '@mui/material';
import { AnyWizardQuestion, WizardConfig } from './WizardConfig';
import { Wizard } from './Wizard';
import { formatNumber } from 'utils/util';
import { MuiIconManifest } from 'utils/iconManifest';

interface WizardBreadcrumbsProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  currentQuestionId: AnyWizardQuestion['id'];
}

export function WizardBreadcrumbs({
  wizard,
  currentQuestionId,
}: WizardBreadcrumbsProps) {
  const { push } = useHistory();

  const [activeStep, setActiveStep] = useState(0);
  const storage = wizard.fetchStorage();

  useEffect(() => {
    if (storage && storage.responses) {
      const currentStepIndex = storage.responses.findIndex(
        (response) => response.questionId === currentQuestionId
      );

      if (currentStepIndex !== -1) {
        setActiveStep(currentStepIndex);
      } else {
        //  else, currentQuestionId is not in responses yet so handle what the next index should be if currentQuestionId were added
        setActiveStep(storage.responses.length);
      }
    }
  }, [currentQuestionId, storage]);

  return (
    <Stepper
      sx={{
        '& .MuiStep-root:first-of-type .MuiStepLabel-root': {
          paddingTop: 0,
        },
      }}
      activeStep={activeStep}
      orientation="vertical"
    >
      {storage?.responses.map((response) => {
        const question = wizard.questions.byId(response.questionId);
        const value = response.value;

        //format value for responses
        let formattedValue = Array.isArray(value)
          ? `${formatNumber(value.length)} added`
          : typeof value === 'number'
          ? formatNumber(value)
          : value;

        if (response.questionId === 'value' && typeof value === 'number') {
          formattedValue = `$${formattedValue}`;
        }
        return (
          <Step
            key={response.questionId}
            onClick={() => {
              push(wizard.questions.urlFor(response.questionId));
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Stack direction="row" alignItems="center" sx={{ flexGrow: 1 }}>
                <StepLabel sx={{ cursor: 'pointer' }}>
                  <Box>{question.label}</Box>
                </StepLabel>
              </Stack>
              <MuiIconManifest.EditIcon sx={{ ml: 1 }} fontSize="small" />
            </Stack>
            <StepContent TransitionProps={{ in: true }}>
              <Typography>{formattedValue}</Typography>
            </StepContent>
          </Step>
        );
      })}
      {/* render the current step if not yet in storage responses */}
      {storage &&
        !storage.responses.some(
          (response) => response.questionId === currentQuestionId
        ) && (
          <Step key={currentQuestionId}>
            <StepLabel>
              {wizard.questions.byId(currentQuestionId).label}
            </StepLabel>
          </Step>
        )}
    </Stepper>
  );
}
