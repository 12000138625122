import { useHistory } from 'react-router';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { useForm } from 'react-hook-form';
import { useUserState } from 'hooks/common/useUserState';
import {
  FormModal,
  getInputElementProps,
} from 'components/common/Modal/FormModal';
import { CheckboxElement, TextFieldElement } from 'react-hook-form-mui';
import {
  editClassicBuilderListPath,
  editICPBuilderListPath,
} from 'utils/routes';

export interface CreateProspectListModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function CreateProspectListModal({
  open,
  setOpen,
}: CreateProspectListModalProps) {
  const { push } = useHistory();
  const [createListMutation] = prospectsApi.usePutCreateListMutation();
  const formDefaultValues = {
    name: '',
    icpBuilder: false,
  };
  const createProspectListForm = useForm({
    mode: 'all',
    defaultValues: formDefaultValues,
  });
  const { team, isFreeUser } = useUserState();

  return (
    <FormModal
      title="Create new prospect list"
      subtitle="Please enter a name for your new list."
      open={open}
      setOpen={setOpen}
      confirmText="Add"
      onSubmit={(values) => {
        createListMutation({
          listName: values.name,
          icpBuilder: values.icpBuilder,
          listType: '', // placeholder
          listSource: '', //placeholder
        }).then(async (response) => {
          if ('data' in response && 'uuid' in response.data) {
            const { uuid } = response.data;
            let redirectPath;

            if (values.icpBuilder) {
              redirectPath = editICPBuilderListPath(uuid);
            } else {
              redirectPath = editClassicBuilderListPath(uuid);
            }

            push(redirectPath);
          }
        });
      }}
      form={createProspectListForm}
      defaultValues={formDefaultValues}
    >
      <TextFieldElement
        {...getInputElementProps({
          name: 'name',
          label: 'Prospect list name',
          form: createProspectListForm,
        })}
        placeholder="Enter your prospect list name"
        validation={{
          required: 'Please enter a name for your list',
        }}
      />
      {team.uuid && !isFreeUser && (
        <CheckboxElement
          {...getInputElementProps({
            name: 'icpBuilder',
            label: 'Create list with ICP builder',
            form: createProspectListForm,
          })}
          labelProps={{
            sx: {
              mb: 0,
            },
          }}
        />
      )}
    </FormModal>
  );
}
