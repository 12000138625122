import { useEffect, useMemo, useState } from 'react';
import { ActivatedFilters } from 'components/ProspectList/ICPBuilder/Filters/AvailableFiltersSection';
import {
  activatedFiltersInitialState,
  formatAvailableFilters,
} from 'components/ProspectList/ICPBuilder/presets';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { crmApi } from 'redux/reducers/api/crm';
import { useUserState } from 'hooks/common/useUserState';
import {
  mountPayload,
  formatFormState,
} from 'utils/icp-filter-requests-helpers';
import { teamApi } from 'redux/reducers/api/team';
import stringify from 'safe-stable-stringify';
import { ProspectListType, SmartListStatusType } from 'modules/prospects/types';
import { useProspectListUUIDValid } from './useProspectListUUIDValid';
import { z } from 'zod';
import { sendPendoEvent } from 'analytics';

// #region Types

/**
 * Represents select box options in Autocompletes.
 */
export const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
  disabled: z.boolean().optional(),
  meta: z.object({}).passthrough().optional(),
});
export const optionsSchema = z.array(optionSchema);

export type Option = z.infer<typeof optionSchema>;

export type Filter = {
  title: string;
  description?: string;
  options?: Option[];
  fetch?: boolean;
  fullOptions?: {
    title: string;
    description?: string;
  }[];
  allowCsvUpload: boolean;
  unique?: boolean;
  isLink?: boolean;
  disabled?: (condition?: boolean) => boolean;
  disableCustomValues?: boolean;
  type?: string;
};

export type FilterSection = {
  title: ProspectListType;
  isBeta?: boolean;
  subtitle: string;
  description: string;
  moreFilters?: Filter[];
  filters: Filter[];
};

export type FilterItem = FilterSection['filters'][number];

export type FilterItemFullOptions = FilterItem['fullOptions'];

export type FormState = {
  listName: string;
  status: SmartListStatusType;
  selectedFilterSection: ProspectListType | null;
  activatedFilters: ActivatedFilters;
  dealValues: {
    averageDealSize: number;
  };
};

// #endregion

export function useIcpFilter({ uuid }: { uuid: string }) {
  const isUuidValid = useProspectListUUIDValid(uuid);
  // team and user data
  const { team, subscriptionInfo } = useUserState();
  const { data: directoryData } = teamApi.useGetDirectoryQuery(
    team.uuid || null
  );
  const teamMembers = directoryData?.success ? directoryData?.team : [];

  // get profile data for prospect list
  const {
    data: profileData,
    isLoading,
    error: getProspectListProfilesQueryError,
  } = prospectsApi.useGetProspectListProfilesQuery(uuid, {
    skip: !isUuidValid,
  });
  const sharedWith = profileData?.success ? profileData.shared_with : [];

  // local state for form info before submitting
  const [formState, setFormState] = useState<FormState>({
    listName: '',
    status: null,
    selectedFilterSection: null,
    activatedFilters: activatedFiltersInitialState,
    dealValues: {
      averageDealSize: 0,
    },
  });

  // update the local state with the profile data once retrieved
  useEffect(() => {
    if (profileData?.success) {
      const test = formatFormState(profileData);
      console.log(test);
      setFormState(test);
    }
  }, [profileData]);

  const { data: getCrmFilterOptions } = crmApi.useGetCrmFilterOptionsQuery(
    team.uuid
  );

  const [updateDealValuesMutation] = prospectsApi.usePatchDealValuesMutation();
  const [updateIcpParamsMutation] =
    prospectsApi.usePatchUpdateIcpParamsMutation();
  const [updateCrmListMutation] = prospectsApi.usePatchCrmListMutation();
  const [updateProspectListMutation] = prospectsApi.usePatchListNameMutation();
  const [deleteListMutation] = prospectsApi.useDeleteProspectListMutation();

  const hasFiltersSelected = useMemo(() => {
    return formState.selectedFilterSection &&
      formState.activatedFilters[formState.selectedFilterSection]
      ? Object.values(
          formState.activatedFilters[formState.selectedFilterSection]
        ).flat().length > 0
      : false;
  }, [formState.activatedFilters, formState.selectedFilterSection]);

  const crmFilterOptions = useMemo(() => {
    return getCrmFilterOptions?.success
      ? {
          CONTACT_STATUS:
            getCrmFilterOptions.results.grouped_crm_filters.CONTACT_STATUS ||
            [],
          OWNER: getCrmFilterOptions.results.grouped_crm_filters.OWNER || [],
          DEAL_STAGE:
            getCrmFilterOptions.results.grouped_crm_filters.DEAL_STAGE || [],
        }
      : {
          CONTACT_STATUS: [],
          OWNER: [],
          DEAL_STAGE: [],
        };
  }, [getCrmFilterOptions]);

  // get my prospect lists so we can map them to the filter options
  const { data: myProspectLists, error: getMyProspectListsQueryError } =
    prospectsApi.useGetMyProspectListsQuery();
  const prospectLists = useMemo(
    () =>
      !myProspectLists?.success
        ? []
        : myProspectLists.prospect_lists.filter((list) => list.uuid !== uuid), // filter out this list
    [myProspectLists, uuid]
  );
  const availableFilters: FilterSection[] = useMemo(
    () =>
      formatAvailableFilters(
        prospectLists,
        crmFilterOptions,
        subscriptionInfo.longName,
        (getCrmFilterOptions?.success &&
          !!getCrmFilterOptions.results?.grouped_crm_filters) ||
          false
      ),
    [
      prospectLists,
      crmFilterOptions,
      subscriptionInfo.longName,
      getCrmFilterOptions,
    ]
  );

  const subtitle = useMemo(
    () =>
      availableFilters.find(
        (item) => item.title === formState.selectedFilterSection
      )?.subtitle,
    [formState.selectedFilterSection, availableFilters]
  );

  const hasFormStateChanged = useMemo(
    () =>
      (profileData?.success &&
        stringify(formState) !== stringify(formatFormState(profileData))) ??
      false,
    [profileData, formState]
  );

  const activatedFiltersBySection =
    formState.selectedFilterSection &&
    formState.activatedFilters[formState.selectedFilterSection]
      ? Object.keys(formState.activatedFilters[formState.selectedFilterSection])
      : [];

  function setFilterToActivated(filter: FilterItem) {
    if (!formState.selectedFilterSection) return;
    const key = filter.description
      ? filter.description + '|' + filter.title
      : filter.title;
    handleActivatedFiltersChange({
      ...formState.activatedFilters,
      [formState.selectedFilterSection]: {
        ...formState.activatedFilters[formState.selectedFilterSection],
        [key]: [],
      },
    });
  }

  function disableToProspectList(filter: FilterItem) {
    if (filter.title !== 'Prospect list') return false;

    if (filter.description === 'exclude') {
      return filter.disabled?.(
        !!formState.activatedFilters.Personas['include|Prospect list']
      );
    }

    if (filter.description === 'include') {
      return filter.disabled?.(
        !!formState.activatedFilters.Personas['exclude|Prospect list']
      );
    }
  }

  async function saveDealValues(inputValue: number) {
    try {
      await updateDealValuesMutation({
        uuid,
        dealValues: {
          average_deal_size: inputValue,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function updateIcpParams(isNew: boolean) {
    await updateIcpParamsMutation({
      uuid,
      icpParams: mountPayload(formState),
    });
    await updateCrmListMutation({
      uuid,
      crm_list: formState.selectedFilterSection === 'CRM',
    });

    sendPendoEvent('ICP List Builder -> Update List -> Success');
  }

  async function submitUpload(isNew: boolean) {
    try {
      if (!uuid) return;
      await updateIcpParams(isNew);
    } catch (error) {
      console.error(error);
    }
  }

  async function renameList(new_name: string) {
    try {
      await updateProspectListMutation({ uuid, new_name });
    } catch (error) {
      console.error(error);
    }
  }

  function deactivateFilter(formStateKey: string) {
    if (!formState.selectedFilterSection) return;
    handleActivatedFiltersChange(
      Object.keys(formState.activatedFilters).reduce(
        (acc, section) => ({
          ...acc,
          [section]: Object.keys(
            formState.activatedFilters[formState.selectedFilterSection!]
          ).reduce((acc, filter) => {
            if (filter !== formStateKey) {
              return {
                ...acc,
                [filter]:
                  formState.activatedFilters[formState.selectedFilterSection!][
                    filter
                  ],
              };
            }
            return acc;
          }, {}),
        }),
        {} as ActivatedFilters
      )
    );
  }

  function handleFilterSectionChange(
    section: 'Personas' | 'People' | 'Organization' | 'CRM'
  ) {
    if (section === 'CRM') {
      setFormState({
        ...formState,
        selectedFilterSection: section,
        activatedFilters: {
          Personas: {},
          People: {},
          Organization: {},
          CRM: {
            ...(subscriptionInfo.longName === 'Enterprise'
              ? {
                  'include|Deal owner': [],
                }
              : {
                  'Company Owner': [],
                }),
          },
        },
      });
      return;
    }

    setFormState({
      ...formState,
      selectedFilterSection: section,
      activatedFilters:
        Object.keys(formState.activatedFilters[section]).length > 0
          ? formState.activatedFilters
          : activatedFiltersInitialState,
    });
  }

  function handleActivatedFiltersChange(activatedFilters: ActivatedFilters) {
    setFormState({
      ...formState,
      activatedFilters: activatedFilters,
    });
  }

  function changeActivatedFilters(formStateKey: string, value: Option[]) {
    if (!formState.selectedFilterSection) return;
    handleActivatedFiltersChange({
      ...formState.activatedFilters,
      [formState.selectedFilterSection]: {
        ...formState.activatedFilters[formState.selectedFilterSection],
        [formStateKey]: value,
      },
    });
  }

  return {
    sharedWith,
    formState,
    hasFiltersSelected,
    hasFormStateChanged,
    subtitle,
    availableFilters,
    activatedFiltersBySection,
    teamMembers,
    renameList,
    handleFilterSectionChange,
    handleActivatedFiltersChange,
    deleteListMutation,
    submitUpload,
    disableToProspectList,
    setFilterToActivated,
    deactivateFilter,
    getCrmFilterOptions,
    isLoading,
    changeActivatedFilters,
    saveDealValues,
    getMyProspectListsQueryError,
    getProspectListProfilesQueryError,
  };
}
