import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { LoadingIconForButton } from '../../Widgets/LoadingIconForButton';
import {
  gridExpandedRowCountSelector,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { SimpleModal } from '../../Modal/SimpleModal';
import { useAppDispatch } from 'redux/store';
import { FormModal, getInputElementProps } from '../../Modal/FormModal';
import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { SelectElement } from 'react-hook-form-mui';
import { setSnacksQueue } from 'redux/reducers/snacks';
import { formatNumber, pluralize } from 'utils/util';
import { MuiIconManifest } from 'utils/iconManifest';

interface DataGridExportButtonProps {
  filename: string;
  exportLimit?: number;
  isLoading?: boolean;
  isDisabled?: boolean;
  slotProps?: {
    button?: Partial<React.ComponentProps<typeof LoadingButton>>;
  };
  singularRowName?: string;
}

enum ExportTypes {
  CSV = 'CSV',
  XLSX = 'XLSX',
}

const formDefaultValues = {
  format: ExportTypes.CSV,
};

export const DataGridExportButton = ({
  filename,
  isLoading,
  isDisabled,
  exportLimit,
  slotProps,
  singularRowName = '',
}: DataGridExportButtonProps) => {
  const { current } = useGridApiContext();
  const dispatch = useAppDispatch();

  const [rateLimitModalOpen, setRateLimitModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const form = useForm({
    mode: 'all',
    defaultValues: formDefaultValues,
  });

  const loading = (slotProps?.button?.loading || isLoading) ?? false;
  const anySelectedRows = current?.state.rowSelection.length > 0;
  const numberOfRows = anySelectedRows
    ? current?.state.rowSelection.length
    : gridExpandedRowCountSelector(current?.state, current.instanceId);
  const textForButton =
    numberOfRows > 0
      ? `Export ${formatNumber(numberOfRows)} ${pluralize(
          singularRowName,
          current.state.rowSelection.length,
          '',
          's'
        )}`
      : `Export all ${singularRowName}s`;

  const exportData = useCallback(
    (data: typeof formDefaultValues) => {
      if (!current) return;
      const exportOptions = {
        fileName: `${filename} - ${dayjs().format('ll')}`,
      };
      try {
        switch (data.format) {
          case ExportTypes.CSV:
            current.exportDataAsCsv(exportOptions);
            break;
          case ExportTypes.XLSX:
            current.exportDataAsExcel(exportOptions);
            break;
        }
      } catch (error) {
        dispatch(
          setSnacksQueue({
            type: 'error',
            message:
              'An error occurred while exporting your data. Please try again.',
          })
        );
      } finally {
        dispatch(
          setSnacksQueue({
            type: 'success',
            message: `Successfully exported your ${data.format}! Please check your downloads folder.`,
          })
        );
      }
    },
    [dispatch, filename, current]
  );

  return (
    <>
      <SimpleModal
        open={rateLimitModalOpen}
        setOpen={setRateLimitModalOpen}
        title="Rate limit exceeded"
        subtitle={`You may only export this data up to ${exportLimit} times per rolling 24-hour period. Please try again later.`}
        confirmText="Okay"
        hideCancel
      />

      <FormModal
        open={exportModalOpen}
        setOpen={setExportModalOpen}
        title={textForButton}
        subtitle="Please select your desired file type for the export."
        confirmText="Export"
        confirmIcon={<MuiIconManifest.CloudDownloadIcon />}
        onSubmit={exportData}
        form={form}
        defaultValues={formDefaultValues}
      >
        <SelectElement
          {...getInputElementProps({
            name: 'format',
            label: 'Export file type',
            form,
          })}
          validation={{
            required: 'Please select a file type for the export.',
          }}
          options={[
            { id: ExportTypes.CSV, label: 'CSV' },
            { id: ExportTypes.XLSX, label: 'XLSX' },
          ]}
        />
      </FormModal>

      <Button
        variant="roundedOutlined"
        color="grey"
        disabled={loading || isDisabled}
        startIcon={
          loading ? (
            <LoadingIconForButton />
          ) : (
            <MuiIconManifest.CloudDownloadIcon />
          )
        }
        onClick={() => setExportModalOpen(true)}
        {...slotProps?.button}
      >
        {textForButton}
      </Button>
    </>
  );
};
