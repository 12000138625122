import { createSlice } from '@reduxjs/toolkit';

export const modal = createSlice({
  name: 'modal',
  initialState: {
    global_names: { 'void-global_name': false },
  },
  reducers: {
    setModalGlobalValues: (state, action) => {
      var global_names = action.payload;
      return { ...state, global_names: global_names };
    },
  },
});

export const { setModalGlobalValues } = modal.actions;

export default modal.reducer;
