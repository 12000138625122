import React from 'react';
import {
  GridValidRowModel,
  GridColumnVisibilityModel,
  GridColDef,
  GridFilterItem,
  GridCellParams,
  GridFilterOperator,
} from '@mui/x-data-grid-premium';
import { CustomGridColDef, DEFAULT_VALUES_FOR_PROPS } from './DataGridProvider';
import {
  Avatar,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { ProfilePhoto } from 'components/common/ProfilePhoto/ProfilePhoto';
import { teamApi } from 'redux/reducers/api/team';
import { useCallback, useMemo } from 'react';
import { AvatarTooltipGroup } from 'components/Prospects/AvatarTooltipGroup';
import { Dayjs } from 'dayjs';
import { DateFilterKind, StatusFilterKind } from 'modules/event/types';
import {
  checkInBetweenDateValues,
  dateFilterValues,
  defaultDateRange,
} from 'utils/date';
import { formatNumber } from 'utils/util';
import { MuiIconManifest } from 'utils/iconManifest';

// #region number bucket type columns

const splitBucketIntoRanges = (bucket: string) => {
  const splitBucket = bucket.split('-').map(Number);
  return {
    min: splitBucket[0],
    max: splitBucket[1],
  };
};

const chipSortForNumberBuckets = (a: string, b: string) => {
  const aSplit = splitBucketIntoRanges(a);
  const bSplit = splitBucketIntoRanges(b);
  return aSplit.min - bSplit.min;
};

const formatChipLabelForNumberBuckets = (value: unknown) => {
  return String(value)
    .split('-')
    .map((num) => {
      const number = Number(num);
      if (number === 0) return 1; // if number is 0, use 1 instead
      // if number is more than thousands, use "k" instead of "000"
      if (number >= 1000) {
        return `${Math.round(number / 1000)}k`;
      } else return formatNumber(number);
    })
    .join('-');
};

// check which bucket the number falls into
function checkNumberBuckets<T extends GridValidRowModel>(
  { value }: GridCellParams<T, number[]>, // parameters representing a cell in the data grid
  filterItem: GridFilterItem // filter item containing values to compare with the cell's value
): boolean {
  for (const bucket of filterItem.value) {
    const splitBucket = splitBucketIntoRanges(bucket);
    const number = Number(value);
    if (number >= splitBucket.min && number <= splitBucket.max) return true;
  }
  return false;
}

// #endregion

// #region date type columns

export function checkRowDate(
  dayjs: Dayjs,
  filterByDate: DateFilterKind
): boolean {
  return (
    filterByDate === DateFilterKind.None ||
    checkInBetweenDateValues(
      dayjs,
      dateFilterValues.get(filterByDate) ?? defaultDateRange
    )
  );
}

// #endregion

// #region currency type columns

const parseCurrencyToString = (
  value: string | number | null
): string | null => {
  if (typeof value === 'string') {
    if (Number.isNaN(Number.parseFloat(value))) return null;
    return Number.parseFloat(value).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  if (typeof value === 'number') {
    return String(value);
  }
  return null;
};

const parseCurrencyToNumber = (value: string | number | null): number => {
  if (typeof value === 'string') {
    // remove any non-numeric characters (like '$' and ',') before parsing
    const parsedValue = Number.parseFloat(value.replace(/[^0-9.-]+/g, ''));
    if (Number.isNaN(parsedValue)) return 0;
    else return parsedValue;
  }
  if (typeof value === 'number') {
    return value;
  }
  return 0; // if null or invalid value, return 0 as default
};

const compareCurrency = (a: string, b: string) => {
  const aNum = parseCurrencyToNumber(a);
  const bNum = parseCurrencyToNumber(b);
  return aNum - bNum;
};

// #endregion

// #region helper functions

export const getColumnVisibilityModelFromColumns = <
  T extends GridValidRowModel
>(
  columns: CustomGridColDef<T>[]
): GridColumnVisibilityModel => {
  return Object.fromEntries(
    columns
      .filter((column) => column.hideable !== false)
      .map((column) => [column.field, column.hidden !== true])
  );
};

export const getColumnVisibilityModelForExportOnlyColumns = <
  T extends GridValidRowModel
>(
  columns: CustomGridColDef<T>[]
): GridColumnVisibilityModel => {
  const columnVisibilityModel: GridColumnVisibilityModel = {};
  columns.forEach((column) => {
    if (
      column.hidden &&
      column.hideable === false &&
      typeof column.hideable !== 'undefined'
    ) {
      Object.assign(columnVisibilityModel, {
        [column.field]: false,
      });
    }
  });
  return columnVisibilityModel;
};

/**
 * Generates an array of filter operators for use in the data grid.
 * @returns {GridFilterOperator[]} An array of filter operators for the data grid.
 */
export function getApplyFilterFn<T extends GridValidRowModel, V>(
  nameOfOperator: string,
  fnToCheck: (
    params: GridCellParams<T, V[]>,
    filterItem: GridFilterItem
  ) => boolean // fn that checks if a cell meets the filter criteria
): GridFilterOperator[] {
  return [
    {
      value: nameOfOperator,
      getApplyFilterFn: (filterItem: GridFilterItem, _column: GridColDef) => {
        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
          return null;
        }
        return (params: GridCellParams<T, V[]>): boolean => {
          return fnToCheck(params, filterItem);
        };
      },
    },
  ];
}

// #endregion

interface UseFormatCustomColumnsProps<T extends GridValidRowModel> {
  columns: CustomGridColDef<T>[];
}

export const useFormatCustomColumns = <T extends GridValidRowModel>({
  columns,
}: UseFormatCustomColumnsProps<T>): {
  formattedColumns: CustomGridColDef<T>[];
  teamMembersLoading: boolean;
} => {
  const theme = useTheme();

  // use RTK query to get team members
  const { isLoading: teamMembersLoading, data: teamMembersData } =
    teamApi.useGetTeamMembersQuery();
  const teamMembers = useMemo(
    () =>
      teamMembersData?.success ? teamMembersData.results.team_members : [],
    [teamMembersData]
  );

  const getTeamMember = useCallback(
    (uuid: string) => {
      if (teamMembersLoading) return undefined;
      return teamMembers.find((member) => member.uuid === uuid);
    },
    [teamMembers, teamMembersLoading]
  );

  const getTeamMemberName = useCallback(
    (uuid: string) => {
      if (!uuid) return '';
      const teamMember = getTeamMember(uuid);
      return teamMember?.name ?? '';
    },
    [getTeamMember]
  );

  const getTeamMemberAvatar = useCallback(
    (uuid: string) => {
      if (!uuid) return null;
      const teamMember = getTeamMember(uuid);
      return teamMember ? (
        <Avatar
          sx={{
            width: 24,
            height: 24,
          }}
          src={teamMember.image}
        />
      ) : null;
    },
    [getTeamMember]
  );

  // when the type is owner, assumes that typeof row.owner === 'object' && row.owner.uuid !== null
  const formatOwnerColumn = useCallback(
    (column: CustomGridColDef<T>): CustomGridColDef<T> => ({
      ...column,
      type: 'string',
      valueGetter: ({ row }) => row?.owner.uuid,
      renderCell: ({ row }) => {
        const owner = row?.owner;
        return (
          <Stack direction="row" alignItems="center" spacing={2}>
            <ProfilePhoto
              enableTooltip={false}
              image={owner.avatar}
              title={owner.name}
              size={24}
            />
            <Typography variant="body2" ml={1}>
              {owner.name}
            </Typography>
          </Stack>
        );
      },
      // custom props for CustomGridColDef
      valueGetterKey: 'uuid',
      valueGetterId: 'getValueFromObject' as const,
      getFilterChipLabel: (uuid) => getTeamMemberName(String(uuid)),
      getFilterChipStartIcon: (uuid) => getTeamMemberAvatar(String(uuid)),
    }),
    [getTeamMemberName, getTeamMemberAvatar]
  );

  // when the type is owners, assumes that typeof value === 'array' && row.owners.every(owner => typeof owner === 'object' && owner.uuid !== null)
  const formatOwnersColumn = useCallback(
    (column: CustomGridColDef<T>): CustomGridColDef<T> => ({
      ...column,
      type: 'string',
      renderCell: ({ value }) => (
        <AvatarTooltipGroup
          objects={value.map((uuid: string) => getTeamMember(String(uuid)))}
          max={4}
        />
      ),
      // custom props for CustomGridColDef
      getFilterChipLabel: (uuid) => getTeamMemberName(String(uuid)),
      getFilterChipStartIcon: (uuid) => getTeamMemberAvatar(String(uuid)),
    }),
    [getTeamMemberName, getTeamMemberAvatar, getTeamMember]
  );

  const formatCurrencyColumn = useCallback(
    (column: CustomGridColDef<T>): CustomGridColDef<T> => ({
      ...column,
      valueFormatter: ({ value }) => parseCurrencyToString(value),
      sortComparator: compareCurrency,
      // custom props for CustomGridColDef
      getFilterChipLabel: (value) =>
        parseCurrencyToString(String(value)) ?? '$0',
      filterChipBlankText: '$0',
      filterChipSort: compareCurrency,
    }),
    []
  );

  const formatNumberBucketColumn = useCallback(
    (column: CustomGridColDef<T>): CustomGridColDef<T> => ({
      ...column,
      align: 'right',
      headerAlign: 'right',
      availableAggregationFunctions: ['sum', 'avg', 'min', 'max', 'size'], // can't use type: 'number' because we're using buckets for filtering
      valueFormatter: ({ value }) => formatNumber(value),
      filterOperators: getApplyFilterFn<T, number>(
        'belongsTo',
        checkNumberBuckets<T>
      ),
      // custom props for CustomGridColDef
      valueGetterId: 'sortNumberIntoBuckets',
      filterChipSort: chipSortForNumberBuckets,
      getFilterChipLabel: formatChipLabelForNumberBuckets,
      filterOperatorToUse: 'belongsTo',
    }),
    []
  );

  const formatStatusColumn = useCallback(
    (column: CustomGridColDef<T>): CustomGridColDef<T> => ({
      ...column,
      aggregable: false,
      renderHeader: () => (
        <Tooltip
          arrow
          placement="top"
          title={
            <>
              The attendee status prior to an event. "Predicted" attendees are
              forecast by AI based on past participation. "Confirmed" attendees
              have registered or announced their attendance. "Historical"
              attendees are past participants.{' '}
              <Link
                target="_blank"
                rel="noreferrer"
                underline="always"
                color={theme.palette.common.white}
                href="https://help.vendelux.com/what-does-predicted-vs.-confirmed-mean"
              >
                Click here to learn more
              </Link>
              .
            </>
          }
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="body2" fontWeight={500}>
              Status
            </Typography>
            <MuiIconManifest.HelpOutlineOutlinedIcon
              sx={{ pl: 1 }}
              fontSize="small"
            />
          </Stack>
        </Tooltip>
      ),
      renderCell: ({ value }) => {
        const arrayOfVals = Array.isArray(value) ? value : [value];
        if (
          arrayOfVals &&
          arrayOfVals.length > 0 &&
          arrayOfVals.includes(StatusFilterKind.None)
        ) {
          return null;
        }
        return (
          <Stack direction="row" alignItems="center" spacing={1}>
            {arrayOfVals?.map((status, index) => (
              <React.Fragment key={status}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  {status === StatusFilterKind.Confirmed && (
                    <MuiIconManifest.CheckCircleIcon fontSize="small" />
                  )}
                  {status === StatusFilterKind.Predicted && (
                    <MuiIconManifest.TrackChangesIcon fontSize="small" />
                  )}
                  {status === StatusFilterKind.Historical && (
                    <MuiIconManifest.HistoryIcon fontSize="small" />
                  )}
                  <Typography variant="body2">{status}</Typography>
                </Stack>
                {index < value.length - 1 && arrayOfVals.length > 1 && (
                  <Divider orientation="vertical" flexItem />
                )}
              </React.Fragment>
            ))}
          </Stack>
        );
      },
      // custom props for CustomGridColDef
      getFilterChipStartIcon: (value) => {
        if (value === StatusFilterKind.Confirmed) {
          return <MuiIconManifest.CheckCircleIcon fontSize="small" />;
        }
        if (value === StatusFilterKind.Predicted) {
          return <MuiIconManifest.TrackChangesIcon fontSize="small" />;
        }
        if (value === StatusFilterKind.Historical) {
          return <MuiIconManifest.HistoryIcon fontSize="small" />;
        }
        return null;
      },
    }),
    [theme.palette.common.white]
  );

  const formattedColumns = useMemo(() => {
    return columns
      .map((column) => {
        const defaultAggregableRemovedColumn =
          !column.aggregable &&
          column.type !== 'number' &&
          column.templateType !== 'currency' &&
          column.templateType !== 'numberBucket'
            ? {
                ...column,
                aggregable: false,
              }
            : column;

        if (column.templateType === 'owners') {
          return formatOwnersColumn(defaultAggregableRemovedColumn);
        }
        if (column.templateType === 'owner') {
          return formatOwnerColumn(defaultAggregableRemovedColumn);
        }
        if (column.templateType === 'currency') {
          return formatCurrencyColumn(defaultAggregableRemovedColumn);
        }
        if (column.templateType === 'numberBucket') {
          return formatNumberBucketColumn(defaultAggregableRemovedColumn);
        }
        if (column.templateType === 'status') {
          return formatStatusColumn(defaultAggregableRemovedColumn);
        }
        return defaultAggregableRemovedColumn;
      })
      .sort(
        (a, b) =>
          (a.position ?? DEFAULT_VALUES_FOR_PROPS.position) -
          (b.position ?? DEFAULT_VALUES_FOR_PROPS.position)
      ); // sort by position if available
  }, [
    columns,
    formatOwnerColumn,
    formatCurrencyColumn,
    formatOwnersColumn,
    formatNumberBucketColumn,
    formatStatusColumn,
  ]);

  return {
    formattedColumns,
    teamMembersLoading,
  };
};
