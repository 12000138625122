import React from 'react';
import Button from '@mui/material/Button';
import { SearchState } from 'react-instantsearch-core';
import { DateRangeExplore } from './types';
import { useUserState } from 'hooks/common/useUserState';
import { useQueryParams } from 'hooks/common/useQueryParams';

interface ClearRefinementsProps {
  searchState: SearchState;
  dateFilter: DateRangeExplore;
  resetFilters: () => void;
}

export const ClearRefinements = ({
  searchState,
  dateFilter,
  resetFilters,
}: ClearRefinementsProps) => {
  const { isFreeUser, isAuthenticated } = useUserState();

  // get the selected prospect lists from the URL
  const { queryParams } = useQueryParams(['prospect_lists']);
  const selectedProspectListUuids = queryParams.prospect_lists;

  const allRefinementListsEmpty = Object.values(
    searchState?.refinementList ?? {}
  ).every((list) => list.length === 0);
  const dateFilterIsEmpty =
    !dateFilter ||
    dateFilter.default ||
    !dateFilter.startDate ||
    !dateFilter.endDate;

  return (
    <Button
      disableRipple
      disableFocusRipple
      variant="roundedText"
      onClick={() => {
        resetFilters();
      }}
      disabled={
        (allRefinementListsEmpty &&
          dateFilterIsEmpty &&
          selectedProspectListUuids.length === 0) ||
        isFreeUser ||
        !isAuthenticated
      }
      sx={{ whiteSpace: 'nowrap' }}
    >
      Clear filters
    </Button>
  );
};
