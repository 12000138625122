import { ListWizardConfig } from 'routes/app/lists/wizard/listWizardConfig';
import { Wizard } from '../Wizard';
import { WizardMode } from '../WizardConfig';

export class ListWizard extends Wizard<ListWizardConfig> {
  mode: WizardMode;
  uuid?: string;
  constructor(
    config: ListWizardConfig,
    mode: WizardMode = 'create',
    uuid?: string
  ) {
    super(config);
    this.config = config;
    this.version = config.version;
    this.mode = mode;
    this.uuid = uuid;
  }

  get basePath() {
    return this.mode === 'edit'
      ? this.config.getEditBasePath(this.uuid!)
      : this.config.getCreateBasePath();
  }

  get description(): string | undefined {
    return this.config.description;
  }
}
