import {
  GetUserStateProfile,
  GetUserStateTeam,
  SubscriptionKind,
  userApi,
  GetUserStateTeamInvitation,
} from 'redux/reducers/api/user';
import { SubscriptionInfo, SubscriptionInterval } from 'modules/payments/types';

// #region types and constants

interface UserUserStateResults {
  isFreeUser: boolean;
  isEnterpriseUser: boolean;
  isProUser: boolean;
  isPlusUser: boolean;

  // returns built into RTK query
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;

  // custom returns
  email: string;
  credits: number;
  isNewCreditsModel: boolean;
  subscription: string;
  subscriptionInfo: SubscriptionInfo;
  team: GetUserStateTeam;
  profile: GetUserStateProfile;
  teamInvitation: GetUserStateTeamInvitation | null;
  impersonating: boolean;

  /**
   * Whether the user is authenticated, or was authenticated last time user
   * state was fetched.
   *
   * Also check `isFetching` if you need a little more certainty about this
   * value not being stale.
   */
  isAuthenticated: boolean;
}

const SubscriptionIntervalByKind: Record<
  SubscriptionKind,
  SubscriptionInterval
> = {
  NoSub: 'Never',
  ProfMonthly: 'Monthly',
  ProfAnnual: 'Yearly',
  PlusMonthly: 'Monthly',
  PlusAnnual: 'Yearly',
  PlusNewCredits: 'Monthly',
  Enterprise: 'Yearly',
};

const SubscriptionNameByKind = {
  NoSub: {
    shortName: 'Free',
    longName: 'Free',
  },
  ProfMonthly: {
    shortName: 'Pro',
    longName: 'Professional',
  },
  ProfAnnual: {
    shortName: 'Pro',
    longName: 'Professional',
  },
  PlusMonthly: {
    shortName: 'Plus',
    longName: 'Plus',
  },
  PlusAnnual: {
    shortName: 'Plus',
    longName: 'Plus',
  },
  PlusNewCredits: {
    shortName: 'Plus',
    longName: 'Plus',
  },
  Enterprise: {
    shortName: 'Enterprise',
    longName: 'Enterprise',
  },
};

// #endregion

/**
 * Custom hook for managing user state and authentication.
 * Retrieves user data using RTK Query and handles errors, logouts, and redirects.
 * Provides structured user state information for components.
 *
 * @returns {UserUserStateResults} An object containing user state data and functions.
 */
export const useUserState = (): UserUserStateResults => {
  // carve out everything not exposed by this hook
  const {
    data: rtkData,
    refetch: _refetch,
    ...rest
  } = userApi.useGetUserStateQuery();

  // type of the subscription plan
  const subName =
    (rtkData?.success && rtkData?.results?.useraccess?.subscription_status) ||
    SubscriptionKind.NoSub;

  return {
    ...rest,
    // booleans
    isFreeUser: subName === SubscriptionKind.NoSub,
    isEnterpriseUser: subName === SubscriptionKind.Enterprise,
    isProUser:
      subName === SubscriptionKind.ProfMonthly ||
      subName === SubscriptionKind.ProfAnnual,
    isPlusUser:
      subName === SubscriptionKind.PlusMonthly ||
      subName === SubscriptionKind.PlusAnnual ||
      subName === SubscriptionKind.PlusNewCredits,
    isNewCreditsModel: subName === SubscriptionKind.PlusNewCredits,
    isAuthenticated: Boolean(
      rtkData && rtkData.success && rtkData.results?.email
    ),

    // custom returns
    credits: (rtkData?.success && rtkData?.results?.team.credits) || 0,
    email: (rtkData?.success && rtkData?.results?.email) || '',
    subscription: subName,
    subscriptionInfo: {
      ...SubscriptionNameByKind[subName],
      payInterval: SubscriptionIntervalByKind[subName],
    },

    // profile info
    profile: (rtkData?.success && rtkData?.results?.profile) || {
      image: '',
      name: '',
      uuid: '',
      url: '',
    },

    // team info
    team: (rtkData?.success && rtkData?.results?.team) || {
      credits: 0,
      name: '',
      uuid: '',
    },
    teamInvitation:
      (rtkData?.success && rtkData?.results?.team_invitation) || null,
    impersonating:
      (rtkData?.success && rtkData?.results?.impersonating) || false,
  };
};
