import {
  Autocomplete,
  TextField,
  createFilterOptions,
  MenuItem,
  Typography,
  ListItemIcon,
  ListItemText,
  Stack,
  AutocompleteRenderOptionState,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MuiIconManifest } from 'utils/iconManifest';

// #region Types

export type Category<T> = {
  label: string;
  value: T;
  childCategories?: Category<T>[];
};

export interface Option<T> extends Omit<Category<T>, 'childCategories'> {
  uuid: string;
  depth: number;
  isLeaf: boolean;
  matchTerms: string[]; // the various terms that can be used to match this option
  parentNodeUuids: string[]; // the uuids of the parent nodes
  childOptions: Option<T>[]; // the child options if any
}

interface TreeSelectAutocompleteProps<T> {
  categories: Category<T>[];
  customHandleChange?: (value: Option<T>[]) => void; // custom onChange function
  indentSize?: number; // the size of the indent for each nested level, defaults to 4
  slotProps?: SlotProps<T>;
  initialValue?: Option<T>[];
}

interface SlotProps<T> {
  autocomplete?: Partial<
    React.ComponentProps<typeof Autocomplete<Option<T>, true, boolean, boolean>>
  >;
  menuItem?: React.ComponentProps<typeof MenuItem>;
}

interface BaseOptionProps<T> {
  indentSize: number;
  option: Option<T>;
  inputValue: string;
  slotProps?: SlotProps<T>;
}

interface BranchOptionProps<T> extends BaseOptionProps<T> {
  isOpen: boolean;
  handleToggle: () => void;
  getSelectedChildrenCount: (option: Option<T>) => number;
}

interface LeafOptionProps<T> extends BaseOptionProps<T> {
  handleSelect: (option: Option<T>) => void;
  selected: boolean;
}

interface MapCategoriesToOptionsProps<T> {
  category: Category<T>;
  depth?: number;
  parentUuids?: string[];
}

// #endregion

// #region helper components and functions

export const mapCategoriesToOptions = <T,>({
  category,
  depth = 0,
  parentUuids = [],
}: MapCategoriesToOptionsProps<T>): Option<T>[] => {
  const { label, value, childCategories = [] } = category;
  const uuid = uuidv4();

  // recursively map child categories to options
  const childOptions = childCategories.flatMap((child) =>
    mapCategoriesToOptions({
      category: child,
      depth: depth + 1,
      parentUuids: [...parentUuids, uuid],
    })
  );

  // aggregate match terms from children
  const childMatchTerms = childOptions.flatMap((child) => child.matchTerms);
  const dedupedMatchTerms = Array.from(
    new Set([label.toLowerCase(), ...childMatchTerms])
  );

  const option: Option<T> = {
    uuid,
    value,
    label,
    depth,
    matchTerms: dedupedMatchTerms,
    isLeaf: childCategories.length === 0,
    parentNodeUuids: parentUuids ?? [],
    childOptions,
  };

  return [option, ...childOptions];
};

// use autosuggest-highlight to highlight the matching text
export const HighlightedText = ({
  needleText,
  hayStackText,
}: {
  needleText: string;
  hayStackText: string;
}) => {
  const lowerHayStack = hayStackText.toLowerCase();
  const lowerNeedle = needleText.toLowerCase();

  const startIndex = lowerHayStack.indexOf(lowerNeedle);
  if (startIndex === -1) {
    return <Typography variant="body1">{hayStackText}</Typography>; // no match, return original text
  }

  const endIndex = startIndex + needleText.length;
  return (
    <Typography variant="body1">
      {hayStackText.substring(0, startIndex)}
      <Typography component="span" fontWeight={600}>
        {hayStackText.substring(startIndex, endIndex)}
      </Typography>
      {hayStackText.substring(endIndex)}
    </Typography>
  );
};

const BranchOption = <T,>({
  // common node props
  indentSize,
  option,
  inputValue,
  slotProps,
  // branch specific props
  isOpen,
  handleToggle,
  getSelectedChildrenCount,
}: BranchOptionProps<T>) => {
  const selectedChildrenCount = getSelectedChildrenCount(option);
  return (
    <MenuItem
      {...slotProps?.menuItem}
      onClick={(e) => {
        handleToggle();
        slotProps?.menuItem?.onClick?.(e);
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ pl: indentSize * option.depth }}
      >
        <ListItemIcon>
          {isOpen ? (
            <MuiIconManifest.ExpandMoreIcon fontSize="small" />
          ) : (
            <MuiIconManifest.NavigateNextIcon fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText>
          <Stack direction="row" alignItems="center">
            <HighlightedText
              needleText={inputValue}
              hayStackText={option.label}
            />
            {selectedChildrenCount > 0 && (
              <Typography fontWeight="bold" sx={{ pl: 1 }}>
                ({selectedChildrenCount})
              </Typography>
            )}
          </Stack>
        </ListItemText>
      </Stack>
    </MenuItem>
  );
};

const LeafOption = <T,>({
  // common node props
  indentSize,
  option,
  inputValue,
  slotProps,
  // leaf specific props
  handleSelect,
  selected,
}: LeafOptionProps<T>) => {
  return (
    <MenuItem
      {...slotProps?.menuItem}
      onClick={(e) => {
        handleSelect(option);
        slotProps?.menuItem?.onClick?.(e);
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ pl: indentSize * option.depth }}
      >
        <ListItemIcon>
          {selected ? (
            <MuiIconManifest.CheckBoxIcon color="primary" fontSize="small" />
          ) : (
            <MuiIconManifest.CheckBoxOutlineBlankIcon fontSize="small" />
          )}
        </ListItemIcon>
        <ListItemText>
          <HighlightedText
            needleText={inputValue}
            hayStackText={option.label}
          />
        </ListItemText>
      </Stack>
    </MenuItem>
  );
};

// #endregion

export const TreeSelectAutocomplete = <T,>({
  indentSize = 4,
  categories = [],
  slotProps,
  customHandleChange,
  initialValue,
}: TreeSelectAutocompleteProps<T>) => {
  const [value, setValue] = useState<Option<T>[]>(initialValue || []);
  const [openBranches, setOpenBranches] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (initialValue && initialValue.length > 0) setValue(initialValue || []);
  }, [initialValue]);

  const options: Option<T>[] = useMemo(() => {
    return categories.flatMap((category) =>
      mapCategoriesToOptions({ category })
    );
  }, [categories]);

  // #region handler functions

  const isOptionEqualToValueFn = useMemo(() => {
    return typeof slotProps?.autocomplete?.isOptionEqualToValue === 'function'
      ? slotProps.autocomplete.isOptionEqualToValue
      : (option: Option<T>, value: Option<T>) => option.uuid === value.uuid;
  }, [slotProps?.autocomplete?.isOptionEqualToValue]);

  const handleSelect = useCallback((option: Option<T>) => {
    setValue((prevValue) => {
      const existingIndex = prevValue.findIndex(
        (opt) => opt.uuid === option.uuid
      );
      if (existingIndex !== -1) {
        return prevValue.filter((opt) => opt.uuid !== option.uuid);
      } else {
        return [...prevValue, option];
      }
    });
  }, []);

  // make sure to remove the option from the selectedLeaves when it is removed
  const handleChange = useCallback(
    (event, value, reason, details) => {
      setValue(value);

      // call the custom onChange function if it exists
      if (typeof customHandleChange === 'function') customHandleChange?.(value);
    },
    [customHandleChange]
  );

  // recursively look through all childCategories to get the count of selected children of a node
  const getSelectedChildrenCount = useCallback(
    (option: Option<T>): number => {
      if (option.isLeaf) {
        return value.some((val) => isOptionEqualToValueFn(option, val)) ? 1 : 0;
      }

      return (
        option.childOptions?.reduce(
          (acc, child) =>
            acc + (child.isLeaf ? getSelectedChildrenCount(child) : 0),
          0
        ) ?? 0
      );
    },
    [value, isOptionEqualToValueFn]
  );

  // recursive function to close all children of a node
  const closeChildren = useCallback(
    (option: Option<T>) => {
      if (option.isLeaf) {
        return;
      }

      const children = options.filter(
        (opt) =>
          opt.parentNodeUuids[opt.parentNodeUuids.length - 1] === option.uuid
      );

      children.forEach((child) => {
        openBranches.delete(child.uuid);
        closeChildren(child);
      });
    },
    [options, openBranches]
  );

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: Option<T>,
      { selected, inputValue }: AutocompleteRenderOptionState
    ) => {
      // check if openNodes has any of the parent nodes
      const parentNodeUuids = option.parentNodeUuids || [];
      const descendantMatch =
        inputValue !== '' &&
        option.matchTerms.some((term) => term.includes(inputValue));

      // if the direct parent is not open, then don't render the option
      const shouldRenderOption =
        descendantMatch ||
        openBranches.has(parentNodeUuids[parentNodeUuids.length - 1]) || // direct parent is open
        parentNodeUuids.length === 0; // no parent nodes (aka top level)

      if (!shouldRenderOption) {
        return null;
      } else if (option.isLeaf) {
        return (
          <LeafOption
            key={option.uuid}
            option={option}
            indentSize={indentSize}
            inputValue={inputValue}
            slotProps={{
              ...slotProps,
              menuItem: {
                ...props,
                ...slotProps?.menuItem,
              },
            }}
            // leaf specific props
            selected={selected}
            handleSelect={handleSelect}
          />
        );
      } else {
        return (
          <BranchOption<T>
            key={option.uuid}
            option={option}
            indentSize={indentSize}
            inputValue={inputValue}
            slotProps={slotProps}
            // branch specific props
            isOpen={openBranches.has(option.uuid) || descendantMatch}
            handleToggle={() => {
              if (openBranches.has(option.uuid)) {
                openBranches.delete(option.uuid);
                closeChildren(option); // close all children
              } else {
                openBranches.add(option.uuid);
              }
              setOpenBranches(new Set(openBranches));
            }}
            getSelectedChildrenCount={getSelectedChildrenCount}
          />
        );
      }
    },
    [
      indentSize,
      openBranches,
      slotProps,
      handleSelect,
      closeChildren,
      getSelectedChildrenCount,
    ]
  );

  // #endregion

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      value={value}
      onChange={handleChange}
      renderOption={renderOption}
      renderInput={(params) => <TextField {...params} />}
      filterOptions={createFilterOptions({
        stringify: (option) => option.matchTerms.join('//'),
      })}
      noOptionsText="No options"
      {...slotProps?.autocomplete}
    />
  );
};
