import { Stack, Typography } from '@mui/material';
import { CurrencyInput } from '../../common/Inputs/CurrencyInput';
import { useUserState } from 'hooks/common/useUserState';
import { useEffect, useState } from 'react';
import { teamApi } from 'redux/reducers/api/team';
import { useHistory } from 'react-router-dom';
import { Wizard } from 'components/Wizard/Wizard';
import {
  WizardConfig,
  WizardListValueQuestion,
} from 'components/Wizard/WizardConfig';
import { WizardPagination } from '../WizardPagination';

export interface ListValueQuestionProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  question: WizardListValueQuestion;
  // onSubmit: ({ dealValue }: { dealValue: number }) => void;
  initialValue: string;
}

/**
 * List Wizard-specific question type for average prospect value.
 * @param param0
 * @returns
 */
export function ListValueQuestion({
  wizard,
  question,
  // onSubmit,
  initialValue,
}: ListValueQuestionProps) {
  const history = useHistory();

  const { team } = useUserState();
  const { data: teamData } = teamApi.useGetDirectoryQuery(team.uuid, {
    skip: !!initialValue,
  });
  const [dealValue, setDealValue] = useState<number>(
    initialValue ? Number(initialValue) : 0
  );

  useEffect(() => {
    if (teamData?.success) {
      setDealValue(teamData.average_deal_size);
    }
  }, [teamData]);

  const handleDealValueChange = (newDealValue: number) => {
    setDealValue(newDealValue);
  };

  function onNextClick() {
    wizard.storage.saveResponse(question.id, dealValue);
    history.push(wizard.questions.urlFor(question.nextQuestionId as string));
  }

  function onPreviousClick() {
    history.goBack();
  }

  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Typography fontWeight={500} variant="h6">
          {question.label}
        </Typography>
        {question.description ? (
          <Typography variant="body2">{question.description}</Typography>
        ) : null}
        <CurrencyInput
          // TODO: re-enable
          // disabled={
          //   subscriptionInfo.shortName !== 'Plus' &&
          //   subscriptionInfo.shortName !== 'Enterprise' &&
          //   formData.list_type === 'Competitor'
          // }
          size="medium"
          value={dealValue.toString()}
          onChangeValue={handleDealValueChange}
          placeholder="0"
          fullWidth
          name="deal-size"
          id="deal-size"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onNextClick();
            }
          }}
        />
      </Stack>
      <WizardPagination
        wizard={wizard}
        question={question}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />
    </Stack>
  );
}
