import axios from 'axios';
import { endpoints } from 'variables/endpoint-urls';
import { GetUserStateResponse } from '../redux/reducers/api/user';

/**
 * Fetches the user's authentication state from the server.
 *
 * @details Use this if you need to know the guaranteed current state of whether
 * the user is authenticated or not to redirect a user arriving in the app. For
 * regular navigation within the app, use `useUserState` instead.
 */
export async function fetchIsAuthenticated() {
  try {
    const { data } = await axios.get<GetUserStateResponse>(
      endpoints.user.get.state
    );
    return Boolean(data && data.success && data.results?.email);
  } catch (e) {
    console.error(e);
    return false;
  }
}
