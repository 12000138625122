import React from 'react';
import { AutocompleteRenderGetTagProps, Chip } from '@mui/material';
import { CustomTag } from 'components/ProspectList/AutoCompleteWithUpload/CustomTag';
import { Option } from 'hooks/Prospects/useIcpFilter';

export type AutoCompleteTagsProps = {
  value: Option[];
  isLink: boolean;
  getTagProps: AutocompleteRenderGetTagProps;
  allowCsvUpload?: boolean;
  length: number;
  handleDelete?: (option: Option, index: number) => void;
};

export const AutoCompleteTags = ({
  value,
  isLink,
  getTagProps,
  allowCsvUpload,
  length,
  handleDelete,
}: AutoCompleteTagsProps) => {
  return (
    <>
      {value.map((option, index) => {
        if (!option.value) return null;

        return (
          <CustomTag
            key={index}
            option={option}
            isLink={isLink}
            handleDelete={
              // Use custom handleDelete if provided, otherwise use the default one.
              handleDelete
                ? () => handleDelete(option, index)
                : () => {
                    return getTagProps({ index }).onDelete(undefined);
                  }
            }
          />
        );
      })}
      {allowCsvUpload && length > 100 && (
        <Chip
          variant="outlined"
          color="primary"
          label={`+${length - 100} more`}
        />
      )}
    </>
  );
};
