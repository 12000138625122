import { createSlice } from '@reduxjs/toolkit';

export const ct_select = createSlice({
  name: 'ct_select',
  initialState: {
    select_groups: { void_group: {} },
  },
  reducers: {
    setCTSelectValue: (state, action) => {
      let select_groups = action.payload;
      return { ...state, select_groups: select_groups };
    },
  },
});

export const { setCTSelectValue } = ct_select.actions;

export default ct_select.reducer;
