import React from 'react';
import { StateResultsProvided } from 'react-instantsearch-core';
import { connectStateResults } from 'react-instantsearch-dom';
import { Alert } from '@mui/material';
import Hits from './Hits';
import { LoadingIcon } from 'components/common/Widgets/LoadingIcon';

const _StateResults = ({
  searching,
  searchResults,
  isSearchStalled,
}: StateResultsProvided) => {
  const nbHits = searchResults && searchResults.nbHits;
  return (
    <>
      {searching && <LoadingIcon />}
      <Hits />
      {!isSearchStalled && !nbHits && (
        <Alert
          severity="info"
          variant="filled"
          sx={{
            marginTop: 4,
            marginBottom: 4,
          }}
        >
          Your search didn't lead to any results. Please change your search and
          try again.
        </Alert>
      )}
    </>
  );
};

export const StateResults = connectStateResults(_StateResults);
