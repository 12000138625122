import React, { useState, useCallback, useRef, useMemo } from 'react';
import {
  RefinementListProvided,
  Hit,
  StateResultsProvided,
} from 'react-instantsearch-core';
import {
  connectRefinementList,
  connectStateResults,
} from 'react-instantsearch-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  CardActions,
  Grid,
} from '@mui/material';
import { useDisabledPill } from 'hooks/common/useDisabledPill';
import { useUserState } from 'hooks/common/useUserState';
import { arraysEqual } from 'utils/util';
import { ExploreFilterButtonContent } from './ExploreFilterButtonContent';
import { LoadingIconForButton } from 'components/common/Widgets/LoadingIconForButton';
import { MuiIconManifest } from 'utils/iconManifest';

export interface RefinementHit {
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
}

interface CustomRefinementListProps {
  label: string;
  placeholder: string;
  hideCount?: boolean;
  showSearchBox?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  // custom items / refine that can override the algolia provided ones
  customItems?: RefinementHit[];
  customRefine?: (values: string[]) => void;
  customSearchForItems?: (query: string) => void;
}

const _ExploreFilterButton = ({
  // custom props
  label,
  placeholder,
  hideCount,
  showSearchBox = true,
  isLoading,
  isDisabled,
  // custom props that override algolia ones
  customItems,
  customRefine,
  customSearchForItems,
  // algolia props
  items,
  refine,
  searchForItems,
  currentRefinement,
  isSearchStalled,
}: CustomRefinementListProps &
  RefinementListProvided &
  StateResultsProvided) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  // custom items / refine that can override the algolia provided ones
  const itemsToRefine =
    typeof customItems === 'undefined' ? items : customItems;
  const refineFn = typeof customRefine === 'undefined' ? refine : customRefine;
  const currentRefinementAndIsRefined = useMemo(
    () => [
      ...currentRefinement,
      ...itemsToRefine.filter((i) => i.isRefined).map((i) => i.value[0]),
    ],
    [currentRefinement, itemsToRefine]
  );
  const searchForItemsFn =
    typeof customSearchForItems === 'undefined'
      ? searchForItems
      : customSearchForItems;

  // selected NOT applied or refined yet
  const [selectedItems, setSelectedItems] = useState(
    currentRefinementAndIsRefined
  );

  // disable the button if the user is not logged in or is a free user
  const { isAuthenticated, isFreeUser } = useUserState();
  const disabled = isDisabled || !isAuthenticated || isFreeUser;
  const disabledTooltip = `${
    !isAuthenticated ? 'Log in' : 'Upgrade'
  } to search events by ${label.toLowerCase()}.`;
  const disabledPill = useDisabledPill(
    (disabled || !isAuthenticated || isFreeUser) &&
      (!isLoading || isSearchStalled),
    disabledTooltip
  );

  const buttonHasSelectedItems =
    currentRefinement.length > 0 ||
    itemsToRefine.filter((i) => i.isRefined).length > 0;

  // what property of the item to look for when checking for isRefined
  // can't rely on item.isRefined because it doesn't get refined until the user clicks apply
  const getItemComparator = useCallback(
    (item: Hit | RefinementHit) =>
      typeof customItems === 'undefined'
        ? item.label
        : item.value[item.value.length - 1],
    [customItems]
  );

  const onClickCancel = useCallback(() => {
    setOpen(false);
    setSelectedItems(currentRefinementAndIsRefined);
  }, [currentRefinementAndIsRefined]);

  const onReset = useCallback(() => {
    setSelectedItems([]);
    refineFn([]);
  }, [refineFn]);

  return (
    <>
      <Tooltip {...disabledPill.tooltip}>
        <span {...disabledPill.span}>
          <Button
            color={buttonHasSelectedItems ? 'primary' : 'grey'}
            variant={buttonHasSelectedItems ? 'rounded' : 'roundedOutlined'}
            onClick={() => {
              if (isLoading) return;
              setOpen(!open);
              setSelectedItems(currentRefinementAndIsRefined);
            }}
            disabled={disabled || isLoading}
            ref={buttonRef}
            endIcon={
              isLoading ? (
                <LoadingIconForButton />
              ) : buttonHasSelectedItems ? (
                <MuiIconManifest.CloseIcon onClick={onReset} fontSize="small" />
              ) : null
            }
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {currentRefinement?.[0] ?? label}{' '}
            {currentRefinement.length > 1 &&
              `+ ${currentRefinement.length - 1}`}
          </Button>
        </span>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={buttonRef.current ? buttonRef.current : undefined}
        onClose={() => {
          setOpen(false);
          searchForItemsFn('');
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card sx={{ maxWidth: 500 }}>
          <CardHeader
            title={label}
            titleTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
            action={
              <IconButton size="small" onClick={onClickCancel}>
                <MuiIconManifest.CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <ExploreFilterButtonContent
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            hideCount={hideCount}
            showSearchBox={showSearchBox}
            placeholder={placeholder}
            itemsToRefine={itemsToRefine}
            getItemComparator={getItemComparator}
            searchForItems={searchForItemsFn}
          />
          {itemsToRefine.length === 0 && (
            <CardContent sx={{ maxHeight: 300, overflow: 'auto' }}>
              <Typography variant="body2">
                {isLoading || isSearchStalled
                  ? 'Loading options...'
                  : 'No options found to filter by.'}
              </Typography>
            </CardContent>
          )}
          <CardActions>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="text" onClick={onClickCancel}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  disabled={arraysEqual(
                    selectedItems,
                    currentRefinementAndIsRefined
                  )}
                  onClick={() => {
                    setOpen(false);
                    refineFn(selectedItems);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export const ExploreFilterButton = connectRefinementList(
  connectStateResults(_ExploreFilterButton)
);
