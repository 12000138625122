type dataStr = readonly string[];

export const orgSizeBucket: dataStr = ['SMB', 'Midmarket', 'Enterprise'];
export const orgSizeRanges: dataStr = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1K-5K',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+',
];
export const orgIndustries: dataStr = [
  'Accounting',
  'Agriculture',
  'Architecture and Design',
  'Arts',
  'Automotive',
  'Construction',
  'Consumer Goods',
  'Consumer Services',
  'Education',
  'Electronics and Gadgets',
  'Energy',
  'Fashion',
  'Finance',
  'Food and Beverage',
  'Gambling',
  'Government',
  'Hardware',
  'Healthcare',
  'Hospitality',
  'Insurance',
  'Legal and HR',
  'Management Consulting',
  'Manufacturing',
  'Marketing and Ads',
  'Media Production',
  'Medical Devices and Biotech',
  'News',
  'Nonprofit',
  'Pharmaceuticals',
  'Publishing',
  'Real Estate',
  'Research',
  'Retail',
  'Software',
  'Sports',
  'Tourism and Travel',
  'Trade and Shipping',
  'Venture Capital',
  'Video Games',
  'Telecommunications',
  'Wellness and Fitness',
];

export const tempJobTitles: dataStr = [
  'Analyst of IT',
  'Analyst of Information Security',
  'Analyst of Information Technology',
  'Analyst of Infosec',
  'Analyst of Legal',
  'Analyst of Security',
  'B2B Ecommerce Manager',
  'Back-End Developer',
  'Back-End Engineer',
  'Bioinformatician',
  'Brand Manager',
  'Business Analyst',
  'Business Development Manager',
  'CCO',
  'CDO',
  'CEO',
  'CFO',
  'CHRO',
  'CIO',
  'CISO',
  'CMO',
  'COO',
  'CPO',
  'CRO',
  'CRO Manager',
  'CSO',
  'CTO',
  'CTO Or Director of IT',
  'Campaign Manager',
  'Channel Sales Representative',
  'Chief Automation Officer',
  'Chief Compliance Officer',
  'Chief Data Officer',
  'Chief Digital Officer',
  'Chief Engineering Officer',
  'Chief Executive Officer',
  'Chief Financial Officer',
  'Chief Human Resources Officer',
  'Chief Information Officer',
  'Chief Information Security Officer',
  'Chief Investment Officer',
  'Chief Learning Officer',
  'Chief Marketing Officer',
  'Chief Operating Officer',
  'Chief Operation Officer',
  'Chief Operational Officer',
  'Chief People Officer',
  'Chief Product Officer',
  'Chief Revenue Officer',
  'Chief Risk Officer',
  'Chief Security Officer',
  'Chief Sustainability Officer',
  'Chief Talent Officer',
  'Chief Technical Officer',
  'Chief Technology Officer',
  'Chief Transformation Officer',
  'Chief of Applications',
  'Chief of Compliance',
  'Chief of Corporate Social Responsibility',
  'Chief of Csr',
  'Chief of ESG',
  'Chief of ESG Governance Assurance',
  'Chief of ESG Reporting',
  'Chief of ESG Strategy',
  'Chief of Engineer',
  'Chief of Engineering',
  'Chief of Environmental Social Corporate Governance',
  'Chief of Environmental Social Governance',
  'Chief of Governance',
  'Chief of IT',
  'Chief of Information Officer',
  'Chief of Information Security Officer',
  'Chief of Information Technology',
  'Chief of Knowledge',
  'Chief of Knowledge Management',
  'Chief of Private Credit',
  'Chief of Private Equity',
  'Chief of Product',
  'Chief of Product Development',
  'Chief of Risk',
  'Chief of Security Officer',
  'Chief of Software',
  'Chief of Software Engineer',
  'Chief of Software Engineering',
  'Chief of Strategy',
  'Chief of Sustainability',
  'Chief of Systems Engineering',
  'Chief of Telecom',
  'Chief of Telecommunications',
  'Cloud Architect',
  'Cloud Engineer',
  'Co-Founder',
  'Cofounder',
  'Computational Biologist',
  'Content Marketer',
  'Content Marketing Manager',
  'Content Strategist',
  'Controller',
  'Conversion Rate Optimization Manager',
  'Corporate Finance Manager',
  'Creative Director',
  'Customer Experience Manager',
  'Data Analyst',
  'Data Engineer',
  'Data Scientist',
  'Developer',
  'Digital Marketing Manager',
  'Digital Marketing Specialist',
  'Director of Account Based Marketing',
  'Director of Account-Based Marketing',
  'Director of Ai',
  'Director of Analytics',
  'Director of Applications',
  'Director of Assurance',
  'Director of Brand',
  'Director of Brand Marketing',
  'Director of Broadcasting',
  'Director of Business Development',
  'Director of Community',
  'Director of Community Marketing',
  'Director of Compliance',
  'Director of Content',
  'Director of Corporate Social Responsibility',
  'Director of Crm',
  'Director of Csr',
  'Director of Customer Experience',
  'Director of Customer Loyalty',
  'Director of Customer Marketing',
  'Director of Customer Relationship Management',
  'Director of Customer Success',
  'Director of Data',
  'Director of Data Insights',
  'Director of Data Privacy',
  'Director of Dei',
  'Director of Demand Gen',
  'Director of Demand Generation',
  'Director of Demand-Gen',
  'Director of Design',
  'Director of Digital',
  'Director of Digital Marketing',
  'Director of Digital Media',
  'Director of Digital Partnerships',
  'Director of Diversity',
  'Director of Diversity Inclusion',
  'Director of E-Comm',
  'Director of E-Commerce',
  'Director of ESG',
  'Director of ESG Governance Assurance',
  'Director of ESG Reporting',
  'Director of ESG Strategy',
  'Director of Ecomm',
  'Director of Ecommerce',
  'Director of Employee Experience',
  'Director of Engineer',
  'Director of Engineering',
  'Director of Enterprise Marketing',
  'Director of Environmental Social Corporate Governance',
  'Director of Environmental Social Governance',
  'Director of Event',
  'Director of Event Marketing',
  'Director of Events',
  'Director of Experiential Marketing',
  'Director of Field Marketing',
  'Director of Fraud',
  'Director of Global Dei Strategy',
  'Director of Global Diversity Inclusion',
  'Director of Global Learning',
  'Director of Governance',
  'Director of Governance Risk Compliance',
  'Director of Grc',
  'Director of Growth',
  'Director of Growth Marketing Ops',
  'Director of Hr',
  'Director of Human Resources',
  'Director of IT',
  'Director of IT Infrastructure',
  'Director of IT Infrastructure Operations',
  'Director of IT Operations',
  'Director of Inclusion',
  'Director of Information Security',
  'Director of Information Technology',
  'Director of Infosec',
  'Director of Innovation',
  'Director of Knowledge',
  'Director of Knowledge Management',
  'Director of Leadership Development',
  'Director of Learning',
  'Director of Learning Design',
  'Director of Learning Development',
  'Director of Learning Experience',
  'Director of Learning Performance',
  'Director of Legal',
  'Director of Marketing',
  'Director of Marketing Operations',
  'Director of Marketing Ops',
  'Director of Media',
  'Director of Mobile',
  'Director of Operations',
  'Director of Paid Media',
  'Director of Partner Marketing',
  'Director of Partnership',
  'Director of Partnerships',
  'Director of Payments',
  'Director of Performance Management',
  'Director of Personalization',
  'Director of Privacy',
  'Director of Private Credit',
  'Director of Private Equity',
  'Director of Procurement',
  'Director of Product',
  'Director of Product Development',
  'Director of Product Management',
  'Director of Product Manager',
  'Director of Professional Services',
  'Director of Regional Marketing',
  'Director of Retention',
  'Director of Risk',
  'Director of Sales',
  'Director of Sales Training Enablement',
  'Director of Security',
  'Director of Software',
  'Director of Software Developer',
  'Director of Software Engineer',
  'Director of Software Engineering',
  'Director of Strategy',
  'Director of Sustainability',
  'Director of Systems Engineering',
  'Director of Talent',
  'Director of Talent Development',
  'Director of Talent Management',
  'Director of Telecom',
  'Director of Telecommunications',
  'Director of Tv',
  'Director of Web Developer',
  'Dpo',
  'Ecommerce Business Development Manager',
  'Ecommerce Director',
  'Ecommerce Supply Chain Manager',
  'Ehs',
  'Employee Health and Safety',
  'Engineer',
  'Engineering Executive',
  'Ergonomics Director',
  'Ergonomics Lead',
  'Ergonomics Manager',
  'Evp Supply Chain',
  'Evp of Brand Marketing',
  'Evp of Marketing',
  'Evp of Media',
  'Evp of Paid Media',
  'Experiential',
  'Experimentation Program Manager',
  'Experimentation Strategist',
  'Field Sales Representative',
  'Financial Buyer',
  'Founder',
  'Front-End Developer',
  'Front-End Engineer',
  'Full Stack Engineer',
  'GM',
  'General Manager',
  'Global Campaign Manager',
  'Global Clo',
  'Global Head of Ai',
  'Global Head of Analytics',
  'Global Learning Partner',
  'Graphic Designer',
  'Growth Marketer',
  'Head Grc Privacy',
  'Head of Account Based Marketing',
  'Head of Account-Based Marketing',
  'Head of Ai',
  'Head of Applications',
  'Head of Assurance',
  'Head of Brand',
  'Head of Brand Marketing',
  'Head of Broadcasting',
  'Head of Business Development',
  'Head of Community',
  'Head of Community Marketing',
  'Head of Compliance',
  'Head of Content',
  'Head of Corporate Social Responsibility',
  'Head of Creative',
  'Head of Crm',
  'Head of Csr',
  'Head of Customer Experience',
  'Head of Customer Loyalty',
  'Head of Customer Marketing',
  'Head of Customer Relationship Management',
  'Head of Customer Success',
  'Head of Data',
  'Head of Data Insights',
  'Head of Data Privacy',
  'Head of Dei',
  'Head of Demand Gen',
  'Head of Demand Generation',
  'Head of Demand-Gen',
  'Head of Design',
  'Head of Digital',
  'Head of Digital Marketing',
  'Head of Digital Media',
  'Head of Digital Partnerships',
  'Head of Diversity',
  'Head of Diversity Inclusion',
  'Head of E-Comm',
  'Head of E-Commerce',
  'Head of ESG',
  'Head of ESG Governance Assurance',
  'Head of ESG Reporting',
  'Head of ESG Strategy',
  'Head of Ecomm',
  'Head of Ecommerce',
  'Head of Employee Experience',
  'Head of Engineer',
  'Head of Engineering',
  'Head of Enterprise Marketing',
  'Head of Environmental Social Corporate Governance',
  'Head of Environmental Social Governance',
  'Head of Event',
  'Head of Event Marketing',
  'Head of Events',
  'Head of Experiential Marketing',
  'Head of Experimentation',
  'Head of Field Marketing',
  'Head of Fraud',
  'Head of Global Dei Strategy',
  'Head of Global Diversity Inclusion',
  'Head of Global Learning',
  'Head of Governance',
  'Head of Governance Risk Compliance',
  'Head of Grc',
  'Head of Growth',
  'Head of Growth Marketing Ops',
  'Head of Hr',
  'Head of Human Resources',
  'Head of IT',
  'Head of IT Infrastructure',
  'Head of IT Infrastructure Operations',
  'Head of IT Operations',
  'Head of Inclusion',
  'Head of Information Security',
  'Head of Information Technology',
  'Head of Infosec',
  'Head of Innovation',
  'Head of Knowledge',
  'Head of Knowledge Management',
  'Head of Leadership Development',
  'Head of Learning',
  'Head of Learning Design',
  'Head of Learning Development',
  'Head of Learning Experience',
  'Head of Learning Performance',
  'Head of Legal',
  'Head of Machine Learning',
  'Head of Manufacturing',
  'Head of Marketing',
  'Head of Marketing Operations',
  'Head of Media',
  'Head of Mobile',
  'Head of Online Strategy',
  'Head of Paid Media',
  'Head of Partner Marketing',
  'Head of Partnership',
  'Head of Partnerships',
  'Head of Payments',
  'Head of Performance Management',
  'Head of Personalization',
  'Head of Privacy',
  'Head of Private Credit',
  'Head of Private Equity',
  'Head of Procurement',
  'Head of Product',
  'Head of Product Development',
  'Head of Product Management',
  'Head of Product Manager',
  'Head of Professional Services',
  'Head of Regional Marketing',
  'Head of Retention',
  'Head of Risk',
  'Head of Sales',
  'Head of Sales Training Enablement',
  'Head of Security',
  'Head of Software',
  'Head of Software Developer',
  'Head of Software Engineer',
  'Head of Software Engineering',
  'Head of Strategy',
  'Head of Supply Chain',
  'Head of Sustainability',
  'Head of Systems Engineering',
  'Head of Talent',
  'Head of Talent Development',
  'Head of Talent Management',
  'Head of Telecom',
  'Head of Telecommunications',
  'Head of Tv',
  'Head of Web Developer',
  'Industrial Sales Representative',
  'Key Account Manager',
  'Lead Designer',
  'Lead of Account Based Marketing',
  'Lead of Account-Based Marketing',
  'Lead of Assurance',
  'Lead of Brand',
  'Lead of Brand Marketing',
  'Lead of Community',
  'Lead of Community Marketing',
  'Lead of Compliance',
  'Lead of Customer Marketing',
  'Lead of Demand Generation',
  'Lead of Design',
  'Lead of Digital Marketing',
  'Lead of Engineer',
  'Lead of Engineering',
  'Lead of Enterprise Marketing',
  'Lead of Event',
  'Lead of Event Marketing',
  'Lead of Experiential Marketing',
  'Lead of Field Marketing',
  'Lead of Governance',
  'Lead of Governance Risk Compliance',
  'Lead of Grc',
  'Lead of Growth',
  'Lead of Marketing',
  'Lead of Marketing Operations',
  'Lead of Partner Marketing',
  'Lead of Privacy',
  'Lead of Product Management',
  'Lead of Product Manager',
  'Lead of Regional Marketing',
  'Lead of Risk',
  'Lead of Software Developer',
  'Lead of Software Engineer',
  'Lead of Software Engineering',
  'Lead of Systems Engineering',
  'Lead of Web Developer',
  'Logistics Director',
  'Logistics Manager',
  'Manager of Account Based Marketing',
  'Manager of Account-Based Marketing',
  'Manager of Assurance',
  'Manager of Brand',
  'Manager of Brand Marketing',
  'Manager of Community',
  'Manager of Community Marketing',
  'Manager of Compliance',
  'Manager of Content',
  'Manager of Corporate Social Responsibility',
  'Manager of Csr',
  'Manager of Customer Marketing',
  'Manager of Demand Generation',
  'Manager of Design',
  'Manager of Digital Marketing',
  'Manager of ESG',
  'Manager of ESG Governance Assurance',
  'Manager of ESG Reporting',
  'Manager of ESG Strategy',
  'Manager of Engineering',
  'Manager of Enterprise Marketing',
  'Manager of Environmental Social Corporate Governance',
  'Manager of Environmental Social Governance',
  'Manager of Event',
  'Manager of Event Marketing',
  'Manager of Experiential Marketing',
  'Manager of Field Marketing',
  'Manager of Governance',
  'Manager of Governance Risk Compliance',
  'Manager of Grc',
  'Manager of Growth',
  'Manager of IT',
  'Manager of Information Security',
  'Manager of Information Technology',
  'Manager of Infosec',
  'Manager of Legal',
  'Manager of Marketing',
  'Manager of Marketing Operations',
  'Manager of Partner Marketing',
  'Manager of Privacy',
  'Manager of Private Credit',
  'Manager of Private Equity',
  'Manager of Product',
  'Manager of Regional Marketing',
  'Manager of Risk',
  'Manager of Security',
  'Manager of Sustainability',
  'Manager of Telecom',
  'Manager of Telecommunications',
  'Manager, Digital Commerce and Personalization',
  'Managing Director',
  'Manufacturing Lead',
  'Marketing Manager',
  'Marketing Ops Analyst',
  'Marketing Ops Manager',
  'Marketing Project Manager',
  'Media Buyer',
  'Media Manager',
  'Media Planner',
  'Ml Architect',
  'Ml Engineer',
  'Online Channel Manager',
  'Online Sales Manager',
  'Ooh',
  'Optimization Manager',
  'Out of Home',
  'Owner',
  'Payments Manager',
  'Portfolio Director',
  'Portfolio Manager',
  'Portfolio Managing Director',
  'President',
  'President of Business Development',
  'President of Compliance',
  'President of Data Privacy',
  'President of Dei',
  'President of Diversity',
  'President of Diversity Inclusion',
  'President of E-Commerce',
  'President of Ecommerce',
  'President of Employee Experience',
  'President of Engineer',
  'President of Engineering',
  'President of Fraud',
  'President of Global Dei Strategy',
  'President of Global Diversity Inclusion',
  'President of Global Learning',
  'President of Hr',
  'President of Human Resources',
  'President of Inclusion',
  'President of Innovation',
  'President of Leadership Development',
  'President of Learning',
  'President of Learning Design',
  'President of Learning Development',
  'President of Learning Experience',
  'President of Learning Performance',
  'President of Partnership',
  'President of Partnerships',
  'President of Payments',
  'President of Performance Management',
  'President of Product',
  'President of Risk',
  'President of Sales Training Enablement',
  'President of Software Engineer',
  'President of Software Engineering',
  'President of Strategy',
  'President of Systems Engineering',
  'President of Talent',
  'President of Talent Development',
  'President of Talent Management',
  'Principal Data Scientist',
  'Principal of Engineer',
  'Principal of Engineering',
  'Principal of Software Engineer',
  'Principal of Software Engineering',
  'Principal of Systems Engineering',
  'Product Manager',
  'Product Owner',
  'Quality Controller',
  'Regional Sales Manager',
  'Research Analyst',
  'SVP Ecommerce',
  'SVP Supply Chain',
  'SVP of Account Based Marketing',
  'SVP of Account-Based Marketing',
  'SVP of Ai',
  'SVP of Analytics',
  'SVP of Applications',
  'SVP of Brand',
  'SVP of Brand Marketing',
  'SVP of Broadcasting',
  'SVP of Business Development',
  'SVP of Community',
  'SVP of Community Marketing',
  'SVP of Compliance',
  'SVP of Customer Experience',
  'SVP of Customer Loyalty',
  'SVP of Customer Marketing',
  'SVP of Data Insights',
  'SVP of Data Privacy',
  'SVP of Dei',
  'SVP of Demand Generation',
  'SVP of Design',
  'SVP of Digital',
  'SVP of Digital Marketing',
  'SVP of Digital Media',
  'SVP of Digital Partnerships',
  'SVP of Diversity',
  'SVP of Diversity Inclusion',
  'SVP of E-Comm',
  'SVP of E-Commerce',
  'SVP of Ecomm',
  'SVP of Ecommerce',
  'SVP of Employee Experience',
  'SVP of Engineer',
  'SVP of Engineering',
  'SVP of Enterprise Marketing',
  'SVP of Event',
  'SVP of Event Marketing',
  'SVP of Experiential Marketing',
  'SVP of Field Marketing',
  'SVP of Fraud',
  'SVP of Global Dei Strategy',
  'SVP of Global Diversity Inclusion',
  'SVP of Global Learning',
  'SVP of Growth',
  'SVP of Hr',
  'SVP of Human Resources',
  'SVP of IT',
  'SVP of IT Infrastructure',
  'SVP of IT Infrastructure Operations',
  'SVP of IT Operations',
  'SVP of Inclusion',
  'SVP of Information Technology',
  'SVP of Innovation',
  'SVP of Knowledge',
  'SVP of Knowledge Management',
  'SVP of Leadership Development',
  'SVP of Learning',
  'SVP of Learning Design',
  'SVP of Learning Development',
  'SVP of Learning Experience',
  'SVP of Learning Performance',
  'SVP of Marketing',
  'SVP of Marketing Operations',
  'SVP of Media',
  'SVP of Mobile',
  'SVP of Paid Media',
  'SVP of Partner Marketing',
  'SVP of Partnership',
  'SVP of Partnerships',
  'SVP of Payments',
  'SVP of Performance Management',
  'SVP of Personalization',
  'SVP of Procurement',
  'SVP of Product',
  'SVP of Product Development',
  'SVP of Regional Marketing',
  'SVP of Risk',
  'SVP of Sales Training Enablement',
  'SVP of Software',
  'SVP of Software Engineer',
  'SVP of Software Engineering',
  'SVP of Strategy',
  'SVP of Systems Engineering',
  'SVP of Talent',
  'SVP of Talent Development',
  'SVP of Talent Management',
  'SVP of Telecom',
  'SVP of Telecommunications',
  'SVP of Tv',
  'Safety Lead',
  'Sales Account Manager',
  'Sales Manager',
  'Search Engine Optimization Manager',
  'Security Lead',
  'Senior Digital Merchandiser',
  'Senior Director of Ai',
  'Senior Director of Analytics',
  'Senior Director of Applications',
  'Senior Director of Engineer',
  'Senior Director of Engineering',
  'Senior Director of IT',
  'Senior Director of Information Technology',
  'Senior Director of Knowledge',
  'Senior Director of Knowledge Management',
  'Senior Director of Product',
  'Senior Director of Product Development',
  'Senior Director of Software',
  'Senior Director of Strategy',
  'Senior Director of Telecom',
  'Senior Director of Telecommunications',
  'Senior Manager of Account Based Marketing',
  'Senior Manager of Account-Based Marketing',
  'Senior Manager of Brand',
  'Senior Manager of Brand Marketing',
  'Senior Manager of Community',
  'Senior Manager of Community Marketing',
  'Senior Manager of Customer Marketing',
  'Senior Manager of Demand Generation',
  'Senior Manager of Design',
  'Senior Manager of Digital Marketing',
  'Senior Manager of Enterprise Marketing',
  'Senior Manager of Event',
  'Senior Manager of Event Marketing',
  'Senior Manager of Experiential Marketing',
  'Senior Manager of Field Marketing',
  'Senior Manager of Growth',
  'Senior Manager of Marketing',
  'Senior Manager of Marketing Operations',
  'Senior Manager of Partner Marketing',
  'Senior Manager of Regional Marketing',
  'Senior Marketing Manager',
  'Senior Marketing Ops Manager',
  'Senior Merchandise Planner',
  'Senior Product Manager',
  'Senior Vice President of Applications',
  'Senior Vice President of Engineer',
  'Senior Vice President of Engineering',
  'Senior Vice President of IT',
  'Senior Vice President of Information Technology',
  'Senior Vice President of Knowledge',
  'Senior Vice President of Knowledge Management',
  'Senior Vice President of Product',
  'Senior Vice President of Product Development',
  'Senior Vice President of Software',
  'Senior Vice President of Strategy',
  'Senior Vice President of Telecom',
  'Senior Vice President of Telecommunications',
  'Senior of Marketing',
  'Senior of Product Management',
  'Senior of Product Manager',
  'Senior of Software Developer',
  'Senior of Software Engineer',
  'Senior of Software Engineering',
  'Senior of Web Developer',
  'Seo Manager',
  'Software Engineer',
  'Specialist of IT',
  'Specialist of Information Security',
  'Specialist of Information Technology',
  'Specialist of Infosec',
  'Specialist of Legal',
  'Specialist of Security',
  'Supply Chain Manager',
  'Territory Manager',
  'VP Digital Transformation',
  'VP Ecommerce',
  'VP Marketing',
  'VP Procurement',
  'VP of Account Based Marketing',
  'VP of Account-Based Marketing',
  'VP of Ai',
  'VP of Analytics',
  'VP of Applications',
  'VP of Brand',
  'VP of Brand Marketing',
  'VP of Broadcasting',
  'VP of Commerce',
  'VP of Commerce and Digital Marketing',
  'VP of Community',
  'VP of Community Marketing',
  'VP of Compliance',
  'VP of Content',
  'VP of Crm',
  'VP of Customer Experience',
  'VP of Customer Loyalty',
  'VP of Customer Marketing',
  'VP of Customer Relationship Management',
  'VP of Customer Success',
  'VP of Data',
  'VP of Data Insights',
  'VP of Data Privacy',
  'VP of Dei',
  'VP of Demand Generation',
  'VP of Design',
  'VP of Digital',
  'VP of Digital Marketing',
  'VP of Digital Media',
  'VP of Digital Partnerships',
  'VP of Diversity',
  'VP of Diversity Inclusion',
  'VP of E-Comm',
  'VP of E-Commerce',
  'VP of Ecomm',
  'VP of Ecommerce',
  'VP of Employee Experience',
  'VP of Engineer',
  'VP of Engineering',
  'VP of Enterprise Marketing',
  'VP of Event',
  'VP of Event Marketing',
  'VP of Experiential Marketing',
  'VP of Field Marketing',
  'VP of Fraud',
  'VP of Global Dei Strategy',
  'VP of Global Diversity Inclusion',
  'VP of Global Learning',
  'VP of Growth',
  'VP of Hr',
  'VP of Human Resources',
  'VP of IT',
  'VP of IT Infrastructure',
  'VP of IT Infrastructure Operations',
  'VP of IT Operations',
  'VP of Inclusion',
  'VP of Information Security',
  'VP of Information Technology',
  'VP of Infosec',
  'VP of Knowledge',
  'VP of Knowledge Management',
  'VP of Leadership Development',
  'VP of Learning',
  'VP of Learning Design',
  'VP of Learning Development',
  'VP of Learning Experience',
  'VP of Learning Performance',
  'VP of Legal',
  'VP of Marketing',
  'VP of Marketing Operations',
  'VP of Marketing Ops',
  'VP of Media',
  'VP of Mobile',
  'VP of Omnichannel',
  'VP of Paid Media',
  'VP of Partner Marketing',
  'VP of Payments',
  'VP of Performance Management',
  'VP of Personalization',
  'VP of Procurement',
  'VP of Product',
  'VP of Product Development',
  'VP of Professional Services',
  'VP of Regional Marketing',
  'VP of Retention',
  'VP of Risk',
  'VP of Sales Training Enablement',
  'VP of Security',
  'VP of Software',
  'VP of Software Engineer',
  'VP of Software Engineering',
  'VP of Strategy',
  'VP of Systems Engineering',
  'VP of Talent',
  'VP of Talent Development',
  'VP of Talent Management',
  'VP of Telecom',
  'VP of Telecommunications',
  'VP of Tv',
  'Vice President of Account Based Marketing',
  'Vice President of Account-Based Marketing',
  'Vice President of Ai',
  'Vice President of Analytics',
  'Vice President of Applications',
  'Vice President of Brand',
  'Vice President of Brand Marketing',
  'Vice President of Broadcasting',
  'Vice President of Business Development',
  'Vice President of Community',
  'Vice President of Community Marketing',
  'Vice President of Compliance',
  'Vice President of Crm',
  'Vice President of Customer Experience',
  'Vice President of Customer Loyalty',
  'Vice President of Customer Marketing',
  'Vice President of Customer Relationship Management',
  'Vice President of Customer Success',
  'Vice President of Data',
  'Vice President of Data Insights',
  'Vice President of Data Privacy',
  'Vice President of Dei',
  'Vice President of Demand Generation',
  'Vice President of Design',
  'Vice President of Digital',
  'Vice President of Digital Marketing',
  'Vice President of Digital Media',
  'Vice President of Digital Partnerships',
  'Vice President of Diversity',
  'Vice President of Diversity Inclusion',
  'Vice President of E-Comm',
  'Vice President of E-Commerce',
  'Vice President of Ecomm',
  'Vice President of Ecommerce',
  'Vice President of Employee Experience',
  'Vice President of Engineer',
  'Vice President of Engineering',
  'Vice President of Enterprise Marketing',
  'Vice President of Event',
  'Vice President of Event Marketing',
  'Vice President of Experiential Marketing',
  'Vice President of Field Marketing',
  'Vice President of Fraud',
  'Vice President of Global Dei Strategy',
  'Vice President of Global Diversity Inclusion',
  'Vice President of Global Learning',
  'Vice President of Growth',
  'Vice President of Hr',
  'Vice President of Human Resources',
  'Vice President of IT',
  'Vice President of IT Infrastructure',
  'Vice President of IT Infrastructure Operations',
  'Vice President of IT Operations',
  'Vice President of Inclusion',
  'Vice President of Information Security',
  'Vice President of Information Technology',
  'Vice President of Infosec',
  'Vice President of Innovation',
  'Vice President of Knowledge',
  'Vice President of Knowledge Management',
  'Vice President of Leadership Development',
  'Vice President of Learning',
  'Vice President of Learning Design',
  'Vice President of Learning Development',
  'Vice President of Learning Experience',
  'Vice President of Learning Performance',
  'Vice President of Legal',
  'Vice President of Marketing',
  'Vice President of Marketing Operations',
  'Vice President of Media',
  'Vice President of Mobile',
  'Vice President of Paid Media',
  'Vice President of Partner Marketing',
  'Vice President of Partnership',
  'Vice President of Partnerships',
  'Vice President of Payments',
  'Vice President of Performance Management',
  'Vice President of Personalization',
  'Vice President of Procurement',
  'Vice President of Product',
  'Vice President of Product Development',
  'Vice President of Professional Services',
  'Vice President of Regional Marketing',
  'Vice President of Retention',
  'Vice President of Risk',
  'Vice President of Sales Training Enablement',
  'Vice President of Security',
  'Vice President of Software',
  'Vice President of Software Engineer',
  'Vice President of Software Engineering',
  'Vice President of Strategy',
  'Vice President of Systems Engineering',
  'Vice President of Talent',
  'Vice President of Talent Development',
  'Vice President of Talent Management',
  'Vice President of Telecom',
  'Vice President of Telecommunications',
  'Vice President of Tv',
  'Warehouse Lead',
  'Web Operations Manager',
  'Wholesale Ecommerce Manager',
];

export const clearbitIndustrySubCategoryMap: Readonly<Record<string, string>> =
  {
    Accounting: 'Accounting',
    Advertising: 'Marketing and Ads',
    'Aerospace & Defense': 'Government',
    'Agricultural Products': 'Agriculture',
    Airlines: 'Tourism and Travel',
    'Apparel, Accessories & Luxury Goods': 'Fashion',
    Architecture: 'Architecture and Design',
    'Asset Management & Custody Banks': 'Finance',
    Automation: 'Manufacturing',
    Automotive: 'Automotive',
    'Banking & Mortgages': 'Finance',
    Beverages: 'Food and Beverage',
    Biotechnology: 'Medical Devices and Biotech',
    Broadcasting: 'News',
    'Building Materials': 'Construction',
    'Business Supplies': 'Consumer Goods',
    'Capital Goods': 'Manufacturing',
    'Casinos & Gaming': 'Gambling',
    Chemicals: 'Manufacturing',
    'Civil Engineering': 'Architecture and Design',
    'Cloud Services': 'Software',
    'Commercial Printing': 'Publishing',
    'Commodity Chemicals': 'Manufacturing',
    Communications: 'Telecommunications',
    'Computer Hardware': 'Hardware',
    'Computer Networking': 'Telecommunications',
    Construction: 'Construction',
    'Construction & Engineering': 'Construction',
    Consulting: 'Management Consulting',
    'Consumer Discretionary': 'Consumer Goods',
    'Consumer Electronics': 'Electronics and Gadgets',
    'Consumer Goods': 'Consumer Goods',
    'Consumer Services': 'Consumer Services',
    'Consumer Staples': 'Consumer Goods',
    'Containers & Packaging': 'Trade and Shipping',
    'Corporate & Business': 'Management Consulting',
    Cosmetics: 'Consumer Goods',
    'Data Processing & Outsourced Services': 'Research',
    Design: 'Marketing and Ads',
    Distributors: 'Trade and Shipping',
    'Diversified Capital Markets': 'Finance',
    Education: 'Education',
    'Education Services': 'Education',
    Electrical: 'Energy',
    'Electrical Equipment': 'Electronics and Gadgets',
    Energy: 'Energy',
    Eyewear: 'Consumer Goods',
    'Family Services': 'Healthcare',
    Finance: 'Finance',
    'Financial Services': 'Finance',
    Food: 'Food and Beverage',
    'Food Production': 'Food and Beverage',
    'Food Retail': 'Food and Beverage',
    Fundraising: 'Finance',
    Gold: 'Consumer Goods',
    'Graphic Design': 'Media Production',
    'Ground Transportation': 'Tourism and Travel',
    'Health & Wellness': 'Wellness and Fitness',
    'Health Care': 'Healthcare',
    'Health Care Services': 'Healthcare',
    'Home Improvement Retail': 'Retail',
    'Homefurnishing Retail': 'Retail',
    'Hotels, Restaurants & Leisure': 'Hospitality',
    'Household Appliances': 'Consumer Goods',
    'Human Resource & Employment Services': 'Legal and HR',
    'Industrial Machinery': 'Manufacturing',
    Industrials: 'Manufacturing',
    'Industrials & Manufacturing': 'Manufacturing',
    Insurance: 'Insurance',
    'Integrated Telecommunication Services': 'Telecommunications',
    Internet: 'Software',
    'Internet Software & Services': 'Software',
    'Investment Banking & Brokerage': 'Finance',
    'Legal Services': 'Legal and HR',
    'Leisure Facilities': 'Wellness and Fitness',
    'Life Sciences Tools & Services': 'Medical Devices and Biotech',
    Machinery: 'Manufacturing',
    Marine: 'Trade and Shipping',
    'Mechanical Engineering': 'Construction',
    Media: 'News',
    'Metals & Mining': 'Manufacturing',
    'Movies & Entertainment': 'Media Production',
    Nanotechnology: 'Hardware',
    'Oil & Gas': 'Energy',
    'Packaged Foods & Meats': 'Food and Beverage',
    'Paper Products': 'Consumer Goods',
    Payments: 'Finance',
    Pharmaceuticals: 'Pharmaceuticals',
    Photography: 'Media Production',
    'Professional Services': 'Management Consulting',
    'Public Relations': 'Marketing and Ads',
    Publishing: 'Publishing',
    'Real Estate': 'Real Estate',
    'Renewable Energy': 'Energy',
    'Research & Consulting Services': 'Research',
    Restaurants: 'Food and Beverage',
    Retailing: 'Retail',
    Semiconductors: 'Hardware',
    'Shipping & Logistics': 'Trade and Shipping',
    'Specialized Consumer Services': 'Consumer Services',
    'Specialty Retail': 'Retail',
    'Sporting Goods': 'Sports',
    'Technology Hardware, Storage & Peripherals': 'Hardware',
    Textiles: 'Manufacturing',
    'Textiles, Apparel & Luxury Goods': 'Consumer Goods',
    Tobacco: 'Agriculture',
    'Trading Companies & Distributors': 'Trade and Shipping',
    Transportation: 'Trade and Shipping',
    Utilities: 'Energy',
    'Wireless Telecommunication Services': 'Telecommunications',
  };
