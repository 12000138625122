import { PropsWithChildren } from 'react';
import { AUTH0_CONFIG } from './variables/constants';
import { Auth0Provider } from '@auth0/auth0-react';
import { useAppDispatch } from './redux/store';
import { setReturnTo } from './redux/reducers/auth';

/**
 * Configures the Auth0Provider.
 * @param children
 * @constructor
 */
export function AppAuth0Provider({ children }: PropsWithChildren<{}>) {
  const dispatch = useAppDispatch();
  return (
    <Auth0Provider
      domain={AUTH0_CONFIG.domain}
      clientId={AUTH0_CONFIG.client_id}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      onRedirectCallback={(appState) => {
        if (appState?.returnTo) {
          dispatch(setReturnTo({ returnTo: appState.returnTo }));
        }
      }}
      authorizationParams={{
        audience: AUTH0_CONFIG.audience,
        redirect_uri: window.location.origin + '/auth/handle_login',
      }}
    >
      {children}
    </Auth0Provider>
  );
}
export default AppAuth0Provider;
