import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertColor } from '@mui/material';

interface SnackState {
  open: boolean;
  message: string;
  type?: AlertColor;
  /**
   * Set this to 0 to have the snackbar stay open indefinitely. Leave undefined to have it close after a default amount of time.
   */
  timeout?: number;
}

const initialState: SnackState | {} = {};

type SetSnacksQueuePayload = Partial<Omit<SnackState, 'open'>>;
export const snacks = createSlice({
  name: 'snacks',
  initialState,
  reducers: {
    closeSnackBar: (state) => {
      return { ...state, open: false };
    },
    setSnacksQueue: (state, action: PayloadAction<SetSnacksQueuePayload>) => {
      const newAlert = action.payload;
      return Object.assign({ type: 'error' }, newAlert, { open: true });
    },
  },
});

export const { setSnacksQueue, closeSnackBar } = snacks.actions;

export function selectSnack(state: {
  snacks: typeof initialState;
}): SnackState {
  return Object.assign(
    { open: false, message: '', type: undefined },
    state.snacks
  );
}

export default snacks.reducer;
