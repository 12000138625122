import { api } from './index';
import { strings } from '../../../variables/messages';
import { endpoints } from 'variables/endpoint-urls';
import { ApiResponseBase } from 'utils/api-response';
import { reportResultInSnack } from './util';
import { EDPTeamMember } from 'adapters/types';

const teamErrorMessages = strings.team.error;

// #region types
type PermissionKind = 'can_spend_credits' | 'can_edit_planner';
interface DirectoryResponse {
  team_name: string;
  team: DirectoryEntry[];
  average_deal_size: number;
  number_of_deals: number;
}
export interface DirectoryEntry {
  profile_uuid: string;
  name: string;
  email: string;
  avatar: string;
  url: string;
  is_requester: boolean;
  team_uuid: string;
  is_staff: boolean;
  permissions: Partial<Record<PermissionKind, boolean>>;
}

// #endregion

export const teamApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDirectory: builder.query<
      ApiResponseBase<DirectoryResponse>,
      string | null
    >({
      query: (teamUuid) => endpoints.team.get.directory(teamUuid),
      providesTags: [{ type: 'Directory', id: 'LIST' }],
    }),

    getTeamMembers: builder.query<
      ApiResponseBase<{
        results: {
          team_members: EDPTeamMember[];
        };
      }>,
      void
    >({
      query: () => ({
        url: endpoints.team.get.members,
        method: 'GET',
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: teamErrorMessages.members,
        });
      },
    }),

    updateTeamData: builder.mutation<
      ApiResponseBase<{
        average_deal_size: number;
      }>,
      {
        average_deal_size: number;
        team_uuid: string;
      }
    >({
      query: ({ average_deal_size, team_uuid }) => ({
        url: endpoints.team.update.team_data,
        method: 'PATCH',
        body: {
          average_deal_size,
          team_uuid: team_uuid,
        },
      }),
      invalidatesTags: [{ type: 'Directory', id: 'LIST' }],
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: teamErrorMessages.update,
          defaultSuccessMessage: 'Team data updated',
        });
      },
    }),
  }),
});
