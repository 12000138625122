import { UUID_REGEX } from '../../utils/regex';
import { AnyWizardQuestion, Option } from './WizardConfig';

/**
 * @file Functions for transforming localStorage data for different question types before submitting to the backend.
 */

export function processComplexInputUpload(
  question: AnyWizardQuestion,
  values: Option[]
) {
  return {
    // Extract items that are UUIDs.
    uuids: values
      .filter((o: Option) => o.value.match(UUID_REGEX))
      .map((o: Option) => o.value),
    // Otherwise assume there's a .meta prop containing the full json.
    raw: values.filter((o) => !!o.meta).map((o: Option) => o.meta),
  };
}

export function processSimpleInputUpload(values: Option[]) {
  return values.map((v: Option) => v.value);
}
