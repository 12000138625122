import { ActivatedFilters } from 'components/ProspectList/ICPBuilder/Filters/AvailableFiltersSection';
import { activatedFiltersInitialState } from 'components/ProspectList/ICPBuilder/presets';
import { FormState, Option } from 'hooks/Prospects/useIcpFilter';
import {
  ProspectListICP,
  ProspectListPermissions,
  ProspectListProfile,
  TeamMembersWithPermissions,
} from 'modules/prospects/types';

export const formatFormState = (
  profileData: ProspectListICP & {
    profiles: ProspectListProfile[];
  }
): FormState => {
  return {
    listName: profileData.name,
    status: profileData.smart_list_status,
    selectedFilterSection: profileData.icp_params.list_type || 'Personas',
    activatedFilters: formatActivatedFilters(profileData?.icp_params),
    dealValues: {
      averageDealSize: profileData.average_deal_size || 0,
    },
  };
};

export type GetIcpListResponse = {
  name: string;
  smart_list_status: 'ready' | 'pending' | null;
  average_deal_size: number;
  number_of_deals: number;
  icp_params: {
    list_type: 'Personas' | 'People' | 'Organization' | 'CRM';
    full_titles?: string[];
    include_job_titles?: string[];
    exclude_job_titles?: string[];
    include_prospect_list?: string[];
    include_prospect_list_options?: Option[];
    exclude_prospect_list?: string[];
    exclude_prospect_list_options?: Option[];
    industry?: string[];
    organization_size?: string[];
    organization_size_buckets?: string[];
    organization_options?: Option[];
    crm_company_options?: Option[];
    crm_people_options?: Option[];
    crm_organization_sizes?: Option[];
    crm_include_job_titles_options?: Option[];
    crm_exclude_job_titles_options?: Option[];
    crm_include_prospect_list_options?: Option[];
    crm_exclude_prospect_list_options?: Option[];
    include_deal_stage_options?: Option[];
    exclude_deal_stage_options?: Option[];
    include_deal_owner_options?: Option[];
    exclude_deal_owner_options?: Option[];
    people_options?: Option[];
    contact_owner_options?: Option[];
    company_owner_options?: Option[];
    deal?: {
      'Last activity date': {
        before: string;
        after: string;
      };
      'Close date': {
        before: string;
        after: string;
      };
      'Created date': {
        before: string;
        after: string;
      };
    };
  };
  my_permissions: ProspectListPermissions[];
  shared_with: TeamMembersWithPermissions[];
};

const formatActivatedFilters = (
  icp_params: GetIcpListResponse['icp_params']
): ActivatedFilters => {
  if (!icp_params) return activatedFiltersInitialState;

  const organization_sizes =
    icp_params['organization_size'] ||
    icp_params['organization_size_buckets'] ||
    [];

  return {
    Personas: {
      'include|Job titles':
        icp_params['include_job_titles']?.map((item) => ({
          label: item,
          value: item,
        })) ||
        icp_params['full_titles']?.map((item) => ({
          label: item,
          value: item,
        })) ||
        [],
      ...(icp_params['exclude_job_titles'] && {
        'exclude|Job titles': icp_params['exclude_job_titles'].map((item) => ({
          label: item,
          value: item,
        })),
      }),
      ...(icp_params['include_prospect_list_options'] && {
        'include|Prospect list': icp_params['include_prospect_list_options'],
      }),
      ...(icp_params['exclude_prospect_list_options'] && {
        'exclude|Prospect list': icp_params['exclude_prospect_list_options'],
      }),
      ...((icp_params['organization_size'] ||
        icp_params['organization_size_buckets']) && {
        'Organization size': organization_sizes.map((item) => ({
          label: item,
          value: item,
        })),
      }),
      ...(icp_params['industry'] && {
        Industry: icp_params['industry'].map((item) => ({
          label: item,
          value: item,
        })),
      }),
    },
    People: {
      ...(icp_params.people_options && {
        People: icp_params.people_options,
      }),
    },
    Organization: {
      ...(icp_params.organization_options && {
        Organization: icp_params.organization_options,
      }),
    },
    CRM: {
      ...(icp_params.deal?.['Last activity date'] && {
        'Deal last activity date': [
          {
            label: `${icp_params.deal?.['Last activity date'].after} - ${icp_params.deal?.['Last activity date'].before}`,
            value: `${icp_params.deal?.['Last activity date'].after} - ${icp_params.deal?.['Last activity date'].before}`,
          },
        ],
      }),
      ...(icp_params.deal?.['Close date'] && {
        'Deal close date': [
          {
            label: `${icp_params.deal?.['Close date'].after} - ${icp_params.deal?.['Close date'].before}`,
            value: `${icp_params.deal?.['Close date'].after} - ${icp_params.deal?.['Close date'].before}`,
          },
        ],
      }),
      ...(icp_params.deal?.['Created date'] && {
        'Deal created date': [
          {
            label: `${icp_params.deal?.['Created date'].after} - ${icp_params.deal?.['Created date'].before}`,
            value: `${icp_params.deal?.['Created date'].after} - ${icp_params.deal?.['Created date'].before}`,
          },
        ],
      }),
      ...(icp_params.include_deal_stage_options && {
        'include|Deal stage': icp_params.include_deal_stage_options,
      }),
      ...(icp_params.exclude_deal_stage_options && {
        'exclude|Deal stage': icp_params.exclude_deal_stage_options,
      }),
      ...(icp_params.include_deal_owner_options && {
        'include|Deal owner': icp_params.include_deal_owner_options,
      }),
      ...(icp_params.exclude_deal_owner_options && {
        'exclude|Deal owner': icp_params.exclude_deal_owner_options,
      }),
      ...(icp_params.crm_company_options && {
        Organization: icp_params.crm_company_options,
      }),
      ...(icp_params.crm_people_options && {
        People: icp_params.crm_people_options,
      }),
      ...(icp_params.crm_organization_sizes && {
        'Organization sizes': icp_params.crm_organization_sizes,
      }),
      ...(icp_params.crm_include_job_titles_options && {
        'include|Job titles': icp_params.crm_include_job_titles_options,
      }),
      ...(icp_params.crm_exclude_job_titles_options && {
        'exclude|Job titles': icp_params.crm_exclude_job_titles_options,
      }),
      ...(icp_params.crm_include_prospect_list_options && {
        'include|Prospect list': icp_params.crm_include_prospect_list_options,
      }),
      ...(icp_params.crm_exclude_prospect_list_options && {
        'exclude|Prospect list': icp_params.crm_exclude_prospect_list_options,
      }),
      ...(icp_params.company_owner_options && {
        'Company Owner': icp_params.company_owner_options,
      }),
      ...(icp_params.contact_owner_options && {
        'Contact Owner': icp_params.contact_owner_options,
      }),
    },
  };
};

export const mountPayload = (formState: FormState) => {
  const sanitizedFilters = Object.entries(
    formState.activatedFilters.Personas
  ).reduce((acc, [key, value]) => {
    acc[key] = value.map((item) => item.value.replace(/\r/g, ''));
    return acc;
  }, {} as Record<string, string[]>);
  const deal_dates = {
    ...(formState.activatedFilters.CRM['Deal created date'] && {
      'Created date':
        formState.activatedFilters.CRM['Deal created date'][0].value,
    }),
    ...(formState.activatedFilters.CRM['Deal last activity date'] && {
      'Last activity date':
        formState.activatedFilters.CRM['Deal last activity date'][0].value,
    }),
    ...(formState.activatedFilters.CRM['Deal close date'] && {
      'Close date': formState.activatedFilters.CRM['Deal close date'][0].value,
    }),
  };

  const payload = {
    list_type: formState.selectedFilterSection,
    full_titles: sanitizedFilters['include|Job titles'],
    include_job_titles: sanitizedFilters['include|Job titles'],
    exclude_job_titles: sanitizedFilters['exclude|Job titles'],
    include_prospect_list: formState.activatedFilters.Personas[
      'include|Prospect list'
    ]?.map((item) => item.value),
    include_prospect_list_options:
      formState.activatedFilters.Personas['include|Prospect list'],
    exclude_prospect_list: formState.activatedFilters.Personas[
      'exclude|Prospect list'
    ]?.map((item) => item.value),
    exclude_prospect_list_options:
      formState.activatedFilters.Personas['exclude|Prospect list'],
    organization_size: sanitizedFilters['Organization size'],
    industry: sanitizedFilters.Industry,
    organization: formState.activatedFilters.Organization['Organization']?.map(
      (item) => item.value
    ),
    organization_options:
      formState.activatedFilters.Organization['Organization'],
    people: formState.activatedFilters.People['People']?.map(
      (item) => item.value
    ),
    people_options: formState.activatedFilters.People['People'],
    deal: {
      ...(deal_dates['Created date'] && {
        'Created date': {
          before: deal_dates['Created date'].split(' - ')[1],
          after: deal_dates['Created date'].split(' - ')[0],
        },
      }),
      ...(deal_dates['Last activity date'] && {
        'Last activity date': {
          before: deal_dates['Last activity date'].split(' - ')[1],
          after: deal_dates['Last activity date'].split(' - ')[0],
        },
      }),
      ...(deal_dates['Close date'] && {
        'Close date': {
          before: deal_dates['Close date'].split(' - ')[1],
          after: deal_dates['Close date'].split(' - ')[0],
        },
      }),
      includes: [
        ...(formState.activatedFilters.CRM?.['include|Deal stage']
          ? formState.activatedFilters.CRM?.['include|Deal stage']?.map(
              (item) => item.value
            )
          : []),
        ...(formState.activatedFilters.CRM?.['include|Deal owner']
          ? formState.activatedFilters.CRM?.['include|Deal owner']?.map(
              (item) => item.value
            )
          : []),
      ],
      excludes: [
        ...(formState.activatedFilters.CRM?.['exclude|Deal stage']
          ? formState.activatedFilters.CRM?.['exclude|Deal stage'].map(
              (item) => item.value
            )
          : []),
        ...(formState.activatedFilters.CRM?.['exclude|Deal owner']
          ? formState.activatedFilters.CRM?.['exclude|Deal owner']?.map(
              (item) => item.value
            )
          : []),
      ],
    },
    contact: {
      includes: [
        ...(formState.activatedFilters.CRM?.['Contact Owner']
          ? formState.activatedFilters.CRM?.['Contact Owner']?.map(
              (item) => item.value
            )
          : []),
      ],
    },
    company: {
      includes: [
        ...(formState.activatedFilters.CRM?.['Company Owner']
          ? formState.activatedFilters.CRM?.['Company Owner']?.map(
              (item) => item.value
            )
          : []),
      ],
    },
    ...(formState.activatedFilters.CRM?.['include|Deal stage'] && {
      include_deal_stage_options:
        formState.activatedFilters.CRM?.['include|Deal stage'],
    }),
    ...(formState.activatedFilters.CRM?.['exclude|Deal stage'] && {
      exclude_deal_stage_options:
        formState.activatedFilters.CRM?.['exclude|Deal stage'],
    }),
    ...(formState.activatedFilters.CRM?.['include|Deal owner'] && {
      include_deal_owner_options:
        formState.activatedFilters.CRM?.['include|Deal owner'],
    }),
    ...(formState.activatedFilters.CRM?.['exclude|Deal owner'] && {
      exclude_deal_owner_options:
        formState.activatedFilters.CRM?.['exclude|Deal owner'],
    }),
    ...(formState.activatedFilters.CRM?.['Company Owner'] && {
      company_owner_options: formState.activatedFilters.CRM?.['Company Owner'],
    }),
    ...(formState.activatedFilters.CRM?.['Contact Owner'] && {
      contact_owner_options: formState.activatedFilters.CRM?.['Contact Owner'],
    }),
  };

  return JSON.stringify(payload);
};
