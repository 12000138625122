import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { css } from '@emotion/css';
import { MuiIconManifest } from 'utils/iconManifest';

const ButtonStyle = css`
  &:hover {
    color: #fff;
  }
`;

const NotFoundView = () => {
  return (
    <Box margin={16}>
      <Grid
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        container
      >
        <Grid item>
          <MuiIconManifest.ErrorOutlineIcon fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="h5" color="textPrimary" align="center">
            This page doesn't exist
          </Typography>
          <Typography variant="body1" color="textPrimary" align="center">
            Please try searching for something else
          </Typography>
        </Grid>
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="primary"
            href="/app/events/search/"
            startIcon={<MuiIconManifest.SearchIcon />}
            className={ButtonStyle}
          >
            Search events
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFoundView;
