import { Button } from '@mui/material';

interface ClearButtonProps {
  text: string;
  resetFn: () => void; // callback function to reset/clear something.
  disabled?: boolean;
  hidden?: boolean;
}

/**
 * ClearAggregationButton Component: Renders a text only button to reset something a data grid.
 * @returns {ReactNode} The component.
 */
export const ClearButton = ({
  text,
  resetFn,
  disabled = false,
  hidden = false,
}: ClearButtonProps) => {
  if (hidden) return null;
  return (
    <Button
      disableRipple
      disableFocusRipple
      disableTouchRipple
      sx={{
        fontWeight: 700,
        '&:hover': {
          backgroundColor: 'unset',
          textDecorationLine: 'underline',
        },
      }}
      onClick={resetFn}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
