import { WizardQuestionPage } from 'components/Wizard/WizardQuestionPage';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { listWizardConfig } from './listWizardConfig';
import { useHistory, useParams } from 'react-router';
import { useListWizard } from 'components/Wizard/useListWizard';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { useEffect, useState } from 'react';
import { WizardStorage } from 'components/Wizard/WizardConfig';
import { LoadingIcon } from 'components/common/Widgets/LoadingIcon';

function _EditListWizardPage() {
  const [hasBootstrapped, setHasBootstrapped] = useState<boolean>(false);
  const history = useHistory();
  const { listUuid: uuid, questionId } = useParams<{
    listUuid: string;
    questionId?: string;
  }>(); // Type assertion

  const { data, isLoading } = prospectsApi.useGetListQuery(uuid);

  const { wizard, callbacks } = useListWizard({
    config: listWizardConfig,
    mode: 'edit',
    uuid,
  });

  const wizard_state: WizardStorage =
    data?.results?.wizard_params?.wizard_state;

  // If we're loading the entry page for the first time, we want to bootstrap the wizard state.
  // Take wizard_state from the API, and populate localStorage with it.
  // This should only happen once when we hit the direct url (..../:uuid/edit) without questionId specified.
  // While navigating through the wizard afterwards, the questionId will always be set.
  useEffect(() => {
    if (data && !wizard_state) {
      console.warn('No wizard state found in the response.');
      history.push(`/app/myprospects/lists`);
    }
    if (!questionId && wizard_state && !hasBootstrapped) {
      // Check that the wizard version matches the state on the server. Right now we just throw an error.
      // In the future we could handle version discrepancies more gracefully.
      if (
        !wizard_state.wizardVersion ||
        wizard_state.wizardVersion !== wizard.version
      ) {
        throw new Error(
          `Wizard storage version mismatch: ${wizard.version} vs ${wizard_state.wizardVersion}`
        );
      }
      wizard.clearStorage();
      wizard.updateStorage(wizard_state);
      setHasBootstrapped(true);
    }
  }, [wizard_state, questionId, wizard, hasBootstrapped, data, history]);

  if (!hasBootstrapped || isLoading) {
    return <LoadingIcon />;
  }

  return (
    <WizardQuestionPage
      wizard={wizard}
      currentQuestionId={
        questionId ? questionId : listWizardConfig.questions[0].id
      }
      callbacks={callbacks}
    />
  );
}

export const EditListWizardPage =
  withAppAuthenticationRequired(_EditListWizardPage);

export default EditListWizardPage;
