import { Hit } from 'react-instantsearch-core';
import { Chip, Stack, Typography, useTheme } from '@mui/material';
import { formatNumber } from 'utils/util';
import { RefinementHit } from './ExploreFilterButton';

interface ExploreFilterButtonChipProps {
  item: Hit | RefinementHit;
  hideCount?: boolean;
  itemIsRefined: boolean;
  selectItem: (item: Hit | RefinementHit) => void;
}

export const ExploreFilterButtonChip = ({
  item,
  hideCount,
  itemIsRefined,
  selectItem,
}: ExploreFilterButtonChipProps) => {
  const theme = useTheme();

  return (
    <Chip
      label={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Typography variant="body2">{item.label}</Typography>
          {!hideCount && (
            <Typography
              variant="caption"
              sx={{
                bgcolor: itemIsRefined
                  ? theme.palette.common.white
                  : theme.palette.grey[300],
                color: itemIsRefined
                  ? theme.palette.primary.main
                  : theme.palette.grey[800],
                borderRadius: '100vh',
                px: 2,
              }}
            >
              {formatNumber(Number(item.count) ?? 0)}
            </Typography>
          )}
        </Stack>
      }
      color={itemIsRefined ? 'primary' : 'default'}
      onClick={() => {
        selectItem(item);
      }}
      variant={itemIsRefined ? 'filled' : 'outlined'}
    />
  );
};
