import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUserState } from 'hooks/common/useUserState';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Link as MuiLink,
  Tooltip,
  SvgIcon,
  Chip,
  Avatar,
  Stack,
  Hidden,
  Typography,
  ListItemIcon,
} from '@mui/material';
import LogoutButton from './LogoutButton';
import { formatNumber, pluralize } from 'utils/util';
import { css } from '@emotion/css';
import { Theme, useTheme } from '@mui/material/styles';
import { userApi } from 'redux/reducers/api/user';
import { useAppLogout } from 'utils/appLogout';
import { isNewFeature } from 'utils/isNewFeature';
import { MuiIconManifest } from 'utils/iconManifest';

const menuItem = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.palette.divider};
  &:hover {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.common.white};
    & .MuiListItemIcon-root {
      color: ${theme.palette.common.white};
    }
  }
`;

function MenuItemContent({
  icon: Icon,
  isNew = false,
  children,
}: PropsWithChildren<{ icon: typeof SvgIcon; isNew?: boolean }>) {
  return (
    <>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      {children}
      {isNew && (
        <Chip label={'NEW'} variant="outlined" color="pink" size="small" />
      )}
    </>
  );
}

export function UserIconWithDropdown() {
  const theme = useTheme();
  const {
    isAuthenticated,
    subscriptionInfo,
    isFreeUser,
    isFetching,
    team,
    profile: { image, url: profileUrl },
    impersonating,
  } = useUserState();
  const [clearImpersonation, clearImpersonationStatus] =
    userApi.useClearImpersonationMutation();
  const appLogout = useAppLogout();

  const credits = team?.credits ?? 0;

  // anchor avatar element to open the profile menu
  const avatarButtonRef = useRef<HTMLButtonElement>(null);
  const [profileOpen, setProfileOpen] = useState(false);

  // open and close the profile menu
  const closeProfileMenu = () => {
    setProfileOpen(false);
  };
  const openProfileMenu = () => {
    setProfileOpen(true);
  };
  const stopImpersonatingFn = useCallback(async () => {
    await clearImpersonation().unwrap();
    appLogout();
  }, [appLogout, clearImpersonation]);
  if (!isAuthenticated) return null;

  return (
    <Stack direction="row" spacing={2}>
      <Hidden smDown>
        <Tooltip arrow title="Help center">
          <IconButton
            color="white"
            component={MuiLink}
            href={'https://help.vendelux.com/'}
            target={'_blank'}
          >
            <MuiIconManifest.HelpOutlineIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Settings">
          <IconButton color="white" component={Link} to={'/app/settings'}>
            <MuiIconManifest.SettingsOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Hidden>
      <IconButton
        aria-controls="navBarProfileMenu"
        aria-label="Show more"
        aria-haspopup="true"
        ref={avatarButtonRef}
        onClick={openProfileMenu}
        size="large"
        disableRipple
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <Avatar
          sx={
            impersonating
              ? { border: `3px dashed ${theme.palette.error.main}` }
              : {}
          }
          alt={'Avatar'}
          src={image}
        />
      </IconButton>
      <Menu
        id="navBarProfileMenu"
        anchorEl={avatarButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={profileOpen}
        onClose={closeProfileMenu}
        MenuListProps={{
          sx: { p: 0 },
        }}
      >
        <Stack
          sx={{
            py: 2,
            px: 4,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            {subscriptionInfo?.shortName ?? 'Free'} plan
          </Typography>
          {isFetching ? (
            <Skeleton width="132px" height="40px" />
          ) : (
            <>
              {isFreeUser ? (
                <Link
                  to="/pricing"
                  onClick={() => {
                    closeProfileMenu();
                  }}
                >
                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    size="small"
                    aria-label="Upgrade"
                    sx={{ mt: 1 }}
                  >
                    Upgrade
                  </Button>
                </Link>
              ) : (
                <Typography variant="body1">{`${formatNumber(
                  credits
                )} ${pluralize('credit', credits, '', 's')}`}</Typography>
              )}
            </>
          )}
        </Stack>
        <MenuItem
          className={menuItem(theme)}
          component={Link}
          onClick={closeProfileMenu}
          to={profileUrl}
        >
          <MenuItemContent icon={MuiIconManifest.AccountCircleOutlinedIcon}>
            Profile
          </MenuItemContent>
        </MenuItem>
        <MenuItem
          className={menuItem(theme)}
          onClick={closeProfileMenu}
          component={MuiLink}
          href={'https://help.vendelux.com/'}
          target={'_blank'}
        >
          <MenuItemContent
            icon={MuiIconManifest.HelpOutlineIcon}
            isNew={isNewFeature('help-center-menu-item')}
          >
            Help center
          </MenuItemContent>
        </MenuItem>
        {team?.uuid && (
          <MenuItem
            className={menuItem(theme)}
            component={Link}
            onClick={closeProfileMenu}
            to={'/app/settings'}
          >
            <MenuItemContent icon={MuiIconManifest.SettingsOutlinedIcon}>
              Settings
            </MenuItemContent>
          </MenuItem>
        )}
        <MenuItem
          className={menuItem(theme)}
          component={MuiLink}
          onClick={closeProfileMenu}
          href={'https://vendelux.com/referrals/'}
          target={'_blank'}
        >
          <MenuItemContent
            icon={MuiIconManifest.CardGiftcardOutlinedIcon}
            isNew={isNewFeature('referral-menu-item')}
          >
            Referral
          </MenuItemContent>
        </MenuItem>
        {impersonating && (
          <MenuItem
            className={menuItem(theme)}
            onClick={stopImpersonatingFn}
            disabled={clearImpersonationStatus.isLoading}
            // disabled menu items are transparent for some reason??? looks bad
            style={{ opacity: 1 }}
          >
            <MenuItemContent icon={MuiIconManifest.BlockOutlinedIcon}>
              {clearImpersonationStatus.isLoading
                ? 'Stopping impersonation...'
                : 'Stop impersonating'}
            </MenuItemContent>
          </MenuItem>
        )}
        <LogoutButton>
          <MenuItem className={menuItem(theme)} onClick={closeProfileMenu}>
            <MenuItemContent icon={MuiIconManifest.LogoutOutlinedIcon}>
              Log out
            </MenuItemContent>
          </MenuItem>
        </LogoutButton>
      </Menu>
    </Stack>
  );
}
