import { CrmFilterOptions } from 'redux/reducers/api/crm';
import {
  FilterItemFullOptions,
  FilterSection,
} from 'hooks/Prospects/useIcpFilter';
import { ProspectList } from 'adapters/types';
import { ActivatedFilters } from './Filters/AvailableFiltersSection';

export const jobTitles = [
  'Chief Executive Officer',
  'Chief Operating Officer',
  'Chief Financial Officer',
  'Software Engineer',
  'Software Developer',
  'Software Architect',
  'Software Consultant',
  'Software Development Manager',
  'Technical Program Manager',
  'Technical Project Manager',
  'Technical Product Manager',
  'Product Manager',
];

// NOTE: some overlap with type EmployeesRange in src/modules/event/types.ts
export const organizationSizeRanges = [
  '1-10',
  '11-50',
  '51-250',
  '251-1K',
  '1K-5K',
  '5K-10K',
  '10K-50K',
  '50K-100K',
  '100K+',
];

export const organizations = [
  'Airtable',
  'Google',
  'Facebook',
  'Microsoft',
  'Amazon',
  'Apple',
  'Netflix',
  'Twitter',
  'Uber',
  'Lyft',
  'Salesforce',
  'LinkedIn',
  'Dropbox',
  'Slack',
  'Zoom',
  'TikTok',
  'Snapchat',
  'Twitch',
  'Tinder',
  'Spotify',
  'Shopify',
  'Square',
  'Stripe',
  'Robinhood',
  'Coinbase',
  'Reddit',
  'PayPal',
  'Pinterest',
  'DoorDash',
  'Instacart',
  'Postmates',
  'Airbnb',
];

export const orgSizes = ['SMB', 'Midmarket', 'Enterprise'];

export const datesOptions: FilterItemFullOptions = [
  {
    title: 'Today',
    description: 'From midnight today until the current time',
  },
  {
    title: 'Yesterday',
    description: 'The previous 24-hour day',
  },
  {
    title: 'Tomorrow',
    description: 'The next 24-hour day',
  },
  {
    title: 'This week',
    description: 'The current calendar week',
  },
  {
    title: 'This week so far',
    description: 'The current calendar week up to now',
  },
  {
    title: 'Last week',
    description: 'The previous calendar week',
  },
];

export const activatedFiltersInitialState: ActivatedFilters = {
  Personas: {
    'include|Job titles': [],
  },
  People: {
    People: [],
  },
  Organization: {
    Organization: [],
  },
  CRM: {
    'Company Owner': [],
  },
};

export const formatAvailableFilters: (
  prospectLists: ProspectList[],
  crmFilterOptions: CrmFilterOptions['results']['grouped_crm_filters'],
  subscriptionInfo: string,
  hasCrmData: boolean
) => FilterSection[] = (
  prospectLists,
  crmFilterOptions,
  subscriptionInfo,
  hasCrmData
) => [
  {
    title: 'Personas',
    subtitle: 'Apply at least one filter to define your persona.',
    description: 'Ex. CMOs in tech',
    filters: [
      {
        title: 'Job titles',
        description: 'include',
        options: jobTitles.map((title) => ({
          label: title,
          value: title,
          disabled: false,
        })),
        allowCsvUpload: true,
        disabled: () => false,
      },
      {
        title: 'Job titles',
        description: 'exclude',
        options: jobTitles.map((title) => ({
          label: title,
          value: title,
          disabled: false,
        })),
        allowCsvUpload: true,
        disabled: () => false,
      },
      {
        title: 'Prospect list',
        description: 'include',
        options: prospectLists.map((list) => ({
          label: list.name,
          value: list.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        isLink: true,
        disabled: (condition) => condition || false,
      },
      {
        title: 'Prospect list',
        description: 'exclude',
        options: prospectLists.map((list) => ({
          label: list.name,
          value: list.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        isLink: true,
        disabled: (condition) => condition || false,
      },
      {
        title: 'Organization size',
        options: orgSizes.map((size) => ({
          label: size,
          value: size,
          disabled: false,
        })),
        allowCsvUpload: false,
        disabled: () => false,
      },
    ],
  },
  {
    title: 'People',
    subtitle: "Add at least one name to create 'people' list",
    description: 'Find or upload leads or contacts',
    filters: [
      {
        title: 'People',
        allowCsvUpload: true,
        fetch: true,
        disableCustomValues: true,
      },
    ],
  },
  {
    title: 'Organization',
    subtitle: "Add at least one name to create 'organization' list",
    description: 'Find or upload target companies',
    filters: [
      {
        title: 'Organization',
        fetch: true,
        allowCsvUpload: true,
        disableCustomValues: true,
      },
    ],
  },
  {
    title: 'CRM',
    isBeta: true,
    subtitle:
      'Create a smart list to automatically pull all contacts or all companies from your CRM',
    description: hasCrmData
      ? 'Your integrated data'
      : 'Integrate your CRM data',
    filters: [
      {
        title: 'Company Owner',
        allowCsvUpload: false,
        options: [
          {
            label: 'Anyone (All company owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: subscriptionInfo !== 'Enterprise',
          }))
        ),
        disabled: () =>
          subscriptionInfo !== 'Enterprise' && subscriptionInfo !== 'Plus',
        disableCustomValues: true,
      },
      {
        title: 'Contact Owner',
        allowCsvUpload: false,
        options: [
          {
            label: 'Anyone (All contact owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: subscriptionInfo !== 'Enterprise',
          }))
        ),
        disabled: () =>
          subscriptionInfo !== 'Enterprise' && subscriptionInfo !== 'Plus',
        disableCustomValues: true,
      },
      {
        title: 'Deal owner',
        description: 'include',
        options: [
          {
            label: 'Anyone (All deal owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: false,
          }))
        ),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },
      {
        title: 'Deal owner',
        description: 'exclude',
        options: [
          {
            label: 'Anyone (All deal owners)',
            value: 'Anyone',
            disabled: false,
          },
        ].concat(
          crmFilterOptions.OWNER.map((option) => ({
            label: option.label,
            value: option.uuid,
            disabled: false,
          }))
        ),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },

      {
        title: 'Deal created date',
        fullOptions: datesOptions,
        options: datesOptions!.map((option) => ({
          label: option.title,
          value: option.title,
          disabled: false,
        })),
        unique: true,
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
        type: 'date',
      },
      {
        title: 'Deal last activity date',
        fullOptions: datesOptions,
        options: datesOptions!.map((option) => ({
          label: option.title,
          value: option.title,
          disabled: false,
        })),
        unique: true,
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
        type: 'date',
      },
      {
        title: 'Deal stage',
        description: 'include',
        options: crmFilterOptions.DEAL_STAGE.map((option) => ({
          label: option.label,
          value: option.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },
      {
        title: 'Deal stage',
        description: 'exclude',
        options: crmFilterOptions.DEAL_STAGE.map((option) => ({
          label: option.label,
          value: option.uuid,
          disabled: false,
        })),
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
      },
      {
        title: 'Deal close date',
        fullOptions: datesOptions,
        options: datesOptions!.map((option) => ({
          label: option.title,
          value: option.title,
          disabled: false,
        })),
        unique: true,
        allowCsvUpload: false,
        disabled: () => subscriptionInfo !== 'Enterprise',
        disableCustomValues: true,
        type: 'date',
      },
    ],
  },
];
