import React from 'react';
import { Snackbar as MuiSnackbar, Alert as MuiAlert } from '@mui/material/';
import { useDispatch } from 'react-redux';
import { closeSnackBar, selectSnack } from 'redux/reducers/snacks';
import { useAppSelector } from 'redux/store';

export function Snackbar() {
  const dispatch = useDispatch();
  const alert = useAppSelector(selectSnack);

  const handleSnackBarClose = () => {
    dispatch(closeSnackBar());
  };

  const timeout =
    alert?.timeout == null
      ? 6000 // default timeout
      : alert?.timeout === 0
      ? undefined // stay open
      : alert?.timeout; // custom timeout

  return (
    <MuiSnackbar
      open={alert?.open}
      autoHideDuration={timeout}
      onClose={handleSnackBarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <MuiAlert
        onClose={handleSnackBarClose}
        severity={alert?.type}
        elevation={6}
        variant="filled"
      >
        {alert?.message}
      </MuiAlert>
    </MuiSnackbar>
  );
}
