import React from 'react';
import { Avatar, AvatarGroup, Typography } from '@mui/material';
import { avatarColors } from 'utils/theme';
import { AvatarTooltip } from 'components/Prospects/AvatarTooltip';
import { OverflowCardGroup } from 'components/Prospects/OverflowCardGroup';
import { SelectedFilterMap } from 'modules/event/types';
import { ProspectOwner } from 'adapters/types';

interface AvatarTooltipGroupProps {
  objects: ProspectOwner[];
  max: number;
  size?: 'small' | 'medium' | 'large';
  setFilterState?: (sfm: SelectedFilterMap) => void;
}

function getAvatarOrImage(
  index: number,
  object: any,
  size?: {
    width: number;
    height: number;
    fontSize: number;
  }
) {
  const colorIndex = index % avatarColors.length;

  return object.image !== '' ? (
    <Avatar
      alt={object.name}
      src={object.image}
      sx={{
        width: size?.width,
        height: size?.height,
        fontSize: size?.fontSize,
      }}
    />
  ) : (
    <Avatar
      sx={{
        bgcolor: avatarColors[colorIndex],
        width: size?.width,
        height: size?.height,
        fontSize: size?.fontSize,
      }}
      alt={object.name}
    >
      {object.name[0]}
    </Avatar>
  );
}

/**
 * Renders a group of avatars with tooltips
 * @param {Array<ProspectOwner | MyProspectList>} objects - Array of objects to be displayed.
 * @param {number} max - Maximum avatars to show (+NUM counts as one avatar).
 * @returns {JSX.Element} - Group of avatars with tooltips.
 */
export const AvatarTooltipGroup = ({
  objects,
  max,
  size = 'medium',
  setFilterState,
}: AvatarTooltipGroupProps) => {
  const overflowObjects = objects.slice(max).map((object, index) => ({
    title: object.name,
    subheader: object.title,
    url: object.url,
    avatar: getAvatarOrImage(index, object),
    uuid: object.uuid,
  }));
  const visibleObjects =
    overflowObjects.length > 1 ? objects.slice(0, max) : objects;

  const width = size === 'small' ? 24 : size === 'medium' ? 32 : 40;
  const height = size === 'small' ? 24 : size === 'medium' ? 32 : 40;
  const fontSize = size === 'small' ? 12 : size === 'medium' ? 16 : 20;

  return (
    <>
      <AvatarGroup
        sx={{
          '& .MuiAvatar-root': { width, height },
          '& .MuiAvatarGroup-avatar': { fontSize },
        }}
      >
        {visibleObjects.map((object, index) => {
          const avatarOrImage = getAvatarOrImage(index, object, {
            width,
            height,
            fontSize,
          });
          return (
            <AvatarTooltip
              key={`object-${index}`}
              url={object.url ?? ''}
              title={object.name}
              subheader={object.title}
              children={avatarOrImage}
              avatar={avatarOrImage}
              filterCallback={() => {
                if (setFilterState)
                  setFilterState(new Map([[object.uuid, true]]));
              }}
            />
          );
        })}
      </AvatarGroup>
      {overflowObjects.length > 1 && (
        <OverflowCardGroup
          setFilterState={setFilterState}
          objects={overflowObjects}
          keyPrefix="avatar-tooltip"
        >
          <Typography
            variant="body2"
            sx={{ cursor: 'pointer', textDecoration: 'underline', pl: 1 }}
          >
            +{overflowObjects.length}
          </Typography>
        </OverflowCardGroup>
      )}
    </>
  );
};
