import React from 'react';
import ReactDOM from 'react-dom';
// react library for routing
import 'setupAxios.ts';
import { API_KEY } from 'utils/algoliaUtils';
import aa from 'search-insights';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/scss/vendelux-react-new.scss?v1.0.0';
import './styles/theme.scss';
import { LicenseInfo } from '@mui/x-license-pro';
import { App } from 'app';
import { AppProvider } from 'app-provider';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY);

aa('init', {
  appId: process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
  apiKey: API_KEY,
});

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root')
);
