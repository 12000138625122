import React from 'react';
import { StateResultsProvided, Hit } from 'react-instantsearch-core';
import {
  connectHitInsights,
  connectStateResults,
} from 'react-instantsearch-dom';
import aa from 'search-insights';
import { useCancelableSWR } from 'hooks/common/useCancelableSWR';
import EventCard from '../common/Widgets/EventCard';
import { useUserState } from 'hooks/common/useUserState';
import { AvatarData } from './AvatarList';
import { EventForCard } from 'modules/search/types';
import { useAppSelector } from 'redux/store';

const getCacheKey = (
  url: string,
  sponsors: unknown[] = [],
  attendees: unknown[] = [],
  selectedProspects: unknown[] = []
) => {
  //  Using sort so that: `ibm&microsoft` == `microsoft&ibm`
  return `${url}${[
    ...(sponsors || []),
    ...(attendees || []),
    ...(selectedProspects || []),
  ]
    .sort()
    .join('&')}`;
};

interface AlgoliaEventCardProps extends StateResultsProvided {
  hit: Hit<EventForCard>;
  insights: (method: string, payload: object) => void;
}

const _AlgoliaEventCard = ({
  hit,
  insights,
  searchState,
}: AlgoliaEventCardProps) => {
  const { isAuthenticated, isFreeUser } = useUserState();
  const { refinementList = {} } = searchState ?? {};
  const attendees = refinementList['attendees.n'] ?? [];
  const sponsors = refinementList['sponsors'] ?? [];
  const { selectedProspectLists } = useAppSelector((state) => state.search);

  const showAvatars =
    attendees?.length || sponsors?.length || selectedProspectLists?.length;
  /*
   * hit.url is of format: /app/event/ces-2022/db89cbfc-8144-43de-9192-5773a7802022
   * and we are extracting uuid it by using: hit?.url.split('/')[4]
   */
  const uuid = hit?.url.split('/')[4];
  const slug = hit?.url.split('/')[3];
  const url = `/ajax/search_event_images/${uuid}/`;

  const [{ data, error }, { isLoading }] = useCancelableSWR<AvatarData, any>(
    hit?.objectID && showAvatars
      ? getCacheKey(url, sponsors, attendees, selectedProspectLists)
      : null,
    { url },
    'POST',
    {
      event_results: uuid,
      people_facets: attendees || [],
      company_facets: sponsors || [],
      prospect_lists: selectedProspectLists || [],
    }
  );

  return (
    <EventCard
      event={{
        ...hit,
        uuid,
        slug,
        bimage: hit.image,
      }}
      onItemClick={(event) => {
        if (event.__queryID) {
          insights('clickedObjectIDsAfterSearch', {
            eventName: 'Event search result clicked',
          });
        }
      }}
      avatars={{
        show: showAvatars,
        data: data ?? { matches: [], additional_result_count: 0 },
        id: hit?.objectID,
        error,
        isLoading,
      }}
      hideCount={isFreeUser || !isAuthenticated}
      algolia // a flag to correct the timestamp for Algolia results
    />
  );
};

const AlgoliaEventCard = connectHitInsights(aa)(
  connectStateResults<AlgoliaEventCardProps>(_AlgoliaEventCard)
);

export default AlgoliaEventCard;
