import { useCallback, useEffect, useMemo, useState } from 'react';
import type {
  AnyWizardQuestion,
  WizardConfig,
  WizardResponse,
} from './WizardConfig';
import { MultipleChoiceQuestion } from './QuestionTypes/MultipleChoiceQuestion';
import { Wizard } from './Wizard';
import { Stack, Grid, Typography, Link, Paper, Button } from '@mui/material';
import { AutocompleteWithUploadQuestion } from './QuestionTypes/AutocompleteWithUploadQuestion';
import { TextInputQuestion } from 'components/Wizard/QuestionTypes/TextInputQuestion';
import { ListValueQuestion } from 'components/Wizard/QuestionTypes/ListValueQuestion';
import { WizardBreadcrumbs } from './WizardBreadcrumbs';
import { SectionLayout } from 'components/common/Widgets/SectionLayout';
import { TreeSelectAutocompleteQuestion } from './QuestionTypes/TreeSelectAutocompleteQuestion';
import { Option as TreeSelectOption } from 'components/common/Inputs/TreeSelectAutocomplete';
import { UseListWizardReturnType } from './useListWizard';
import { CreateProspectListModal } from 'components/ProspectList/CreateProspectListModal';
import { sendAnalyticsEvent, sendPendoEvent } from 'analytics';
import { MuiIconManifest } from 'utils/iconManifest';

export interface WizardQuestionPageProps<TWizard = Wizard<WizardConfig>> {
  currentQuestionId: AnyWizardQuestion['id'];
  wizard: TWizard;
  customQuestions?: JSX.Element | null;
  callbacks: UseListWizardReturnType['callbacks'];
}

/**
 * Core component for rendering a single question in the wizard.
 *
 * Jobs of this component:
 *
 * - Fetch any existing response for this question from temp persistence (i.e., local storage).
 * - Render the given question's form based on its type, with a value if it exists.
 * - Handle form submission and navigation to the next question.
 */
export function WizardQuestionPage({
  currentQuestionId,
  wizard,
  customQuestions = null,
  callbacks: {
    onMultipleChoiceResponse,
    onInputUploadResponse,
    onTextInputResponse,
    onTreeSelectAutocompleteResponse,
    onReset,
  },
}: WizardQuestionPageProps) {
  const question = wizard.questions.byId(currentQuestionId);
  const description = wizard.description;
  const [hasSentAnalytics, setHasSentAnalytics] = useState<boolean>(false);

  const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!hasSentAnalytics) {
      const params = {
        questionId: currentQuestionId,
        wizardId: wizard.config.id,
        wizardVersion: wizard.config.version,
        questionLabel: question.label,
        questionType: question.type,
        questionSubType: question.subType || '',
      };

      sendPendoEvent('List Wizard > Question Page Viewed', params);
      sendAnalyticsEvent(
        'List Wizard',
        'List Wizard > Question Page Viewed',
        undefined,
        undefined,
        params
      );
      setHasSentAnalytics(true);
    }
  }, [currentQuestionId, question, wizard, hasSentAnalytics]);

  // Fetch any existing response for this question from temp persistence (i.e., local storage).
  const responseValue: WizardResponse['value'] | undefined = useMemo(
    () => wizard.storage.fetchResponse(currentQuestionId)?.value,
    [currentQuestionId, wizard.storage]
  );

  const createLegacyListOnClick = useCallback((e) => {
    e.preventDefault();
    setCreateModalIsOpen(true);
  }, []);

  if (!question) {
    return <div>Question not found.</div>;
  }

  return (
    <SectionLayout
      title={wizard.config.title}
      slotProps={{
        container: {
          maxWidth: 'lg',
        },
      }}
      rightsideToolbar={
        <Button
          variant="rounded"
          onClick={() => {
            onReset();
          }}
          startIcon={<MuiIconManifest.DeleteIcon />}
        >
          Discard list
        </Button>
      }
    >
      <Stack spacing={4}>
        {/**
         *  TODO: the below is hardcoded for the list wizard. This will have to be extracted
         *   when we add any new wizards based on this framework. But first we should decide
         *   how this will work across wizards.
         */}
        {description ? (
          <Typography fontWeight={500} variant="body1">
            {description}

            {wizard.mode && wizard.mode === 'create' ? (
              <Typography variant="body1">
                (
                <Link
                  onClick={createLegacyListOnClick}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline !important',
                  }}
                >
                  Click here
                </Link>{' '}
                to use our old list builder.)
              </Typography>
            ) : null}
          </Typography>
        ) : null}

        <CreateProspectListModal
          open={createModalIsOpen}
          setOpen={setCreateModalIsOpen}
        />

        <Grid container gap={6} wrap="nowrap">
          <Grid item xs={9}>
            <Paper sx={{ p: 4 }}>
              <Stack direction="column" spacing={8}>
                {question.type === 'multiple-choice' || !question.type ? (
                  <MultipleChoiceQuestion
                    wizard={wizard}
                    question={question}
                    onAnswerClick={async (answer) => {
                      await onMultipleChoiceResponse(question, answer);
                    }}
                  />
                ) : null}

                {question.type === 'input-upload' ? (
                  <AutocompleteWithUploadQuestion
                    initialValue={responseValue || []}
                    question={question}
                    wizard={wizard}
                    onSubmit={async (value) => {
                      await onInputUploadResponse(question, value);
                    }}
                  />
                ) : null}

                {question.type === 'text-input' ? (
                  <TextInputQuestion
                    wizard={wizard}
                    initialValue={responseValue as string}
                    question={question}
                    onSubmitForm={async (value) => {
                      await onTextInputResponse(question, value);
                    }}
                  />
                ) : null}

                {question.type === 'list-value' ? (
                  <ListValueQuestion
                    wizard={wizard}
                    question={question}
                    initialValue={responseValue as string}
                  />
                ) : null}

                {question.type === 'tree-select-autocomplete' ? (
                  <TreeSelectAutocompleteQuestion
                    wizard={wizard}
                    question={question}
                    initialValue={responseValue as TreeSelectOption<string>[]}
                    onSubmit={async (value) => {
                      await onTreeSelectAutocompleteResponse(question, value);
                    }}
                  />
                ) : null}

                {customQuestions}
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={3}>
            <Paper sx={{ p: 4 }}>
              <WizardBreadcrumbs
                wizard={wizard}
                currentQuestionId={currentQuestionId}
              />
            </Paper>
          </Grid>
        </Grid>
      </Stack>
    </SectionLayout>
  );
}
