export type Feature = {
  expires: Date;
};

export const FEATURES: Record<string, Feature> = {
  'help-center-menu-item': {
    expires: new Date('2024-07-01'),
  },
  'referral-menu-item': {
    expires: new Date('2024-07-01'),
  },
};

export type FeatureKind = keyof typeof FEATURES;

export function isNewFeature(kind: FeatureKind): boolean {
  const feature = FEATURES[kind];
  if (!feature) {
    return false;
  }
  return feature.expires > new Date();
}
