import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AuthSliceState {
  returnTo: string;
  userIdentifiers: null | {
    userId: string;
    email: string;
  };
}

const _auth = createSlice({
  name: 'auth',
  initialState: {
    returnTo: '',
    userIdentifiers: null,
  } as AuthSliceState,
  reducers: {
    setReturnTo(
      state,
      { payload: { returnTo } }: PayloadAction<{ returnTo: string }>
    ) {
      state.returnTo = returnTo;
    },
    setUserIdentifiers(
      state,
      action: PayloadAction<AuthSliceState['userIdentifiers']>
    ) {
      state.userIdentifiers = action.payload;
    },
  },
});

export default _auth.reducer;
export const { setReturnTo, setUserIdentifiers } = _auth.actions;
