import React, { useRef, InputHTMLAttributes } from 'react';
import { css } from '@emotion/css';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  ButtonProps,
  Theme,
  Typography,
} from '@mui/material';
import { theme } from 'utils/theme';
import { FileUpload } from 'components/common/Buttons/FileUpload';
import {
  CsvUploadModal,
  CsvUploadModalFieldInfo,
} from 'components/ProspectList/ICPBuilder/CsvUploadModal';
import { FilterItemFullOptions, Option } from 'hooks/Prospects/useIcpFilter';
import { SimpleModal } from 'components/common/Modal/SimpleModal';
import { AutoCompleteTags } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteCustomTags';
import { TextFieldInput } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteInput';
import { AutoCompleteOption } from 'components/ProspectList/AutoCompleteWithUpload/AutoCompleteOption';
import { useAutoCompleteWithUpload } from 'hooks/Prospects/useAutoCompleteWithUpload';
import { endpoints } from 'variables/endpoint-urls';

export const textFieldWithAutocompleteStyle = (theme: Theme) => css`
  display: flex;
  background-color: ${theme.palette.background.default};
  border: 1px solid ${theme.palette.grey[400]};
  border-radius: ${theme.shape.borderRadius}px;
  flex: 1;
  align-items: center;
`;

export const autoCompleteStyle = (theme: Theme) => css`
  width: 100%;
  padding: ${theme.spacing(2)}px;
  outline: none;
  border: none;
  & .MuiAutocomplete-endAdornment {
    top: 8px;
  }
  & .MuiAutocomplete-endAdornment > *:nth-child(-n + 2) {
    display: none;
  }
  &:hover {
    & .MuiAutocomplete-endAdornment > *:nth-child(1) {
      display: flex;
    }
  }
`;

export const uploadBtnStyle = (theme: Theme) => css`
  margin: ${theme.spacing(2)}px;
  color: ${theme.palette.primary.main};
  min-width: 90px;
  white-space: nowrap;
`;

export type TextFieldWithUploadProps = {
  // Static list of popup options.
  options: Option[];
  placeholder?: string;
  id?: string;
  onValueChanged: (value: Option[]) => void;
  // Current selected values. Externally controlled.
  value: Option[];
  label: string;
  disabled?: boolean;
  allowUpload?: boolean;
  fullOptions?: FilterItemFullOptions;
  isLink?: boolean;
  unique?: boolean;
  fetch?: boolean;
  disabledCustomValues?: boolean;
  onRemoveItemClicked?: (value: Option) => void;
  // Returns the url to fetch from if props.fetch is true. Takes a string query.
  endpointFunction?: (inputValue: string) => string;
  // Transforms the fetched data into the Option format.
  filterFetchedOptions?: (data: any) => Option[];
  // Which column matching fields to display in the upload modal.
  uploadModalFields: CsvUploadModalFieldInfo[];
  // Additional props for the text input.
  textInputProps?: AutocompleteRenderInputParams['inputProps'];
  // Additional props for the file input.
  fileInputProps?: InputHTMLAttributes<HTMLInputElement>;
  // Additional props for the upload button.
  uploadButtonProps?: ButtonProps;
};

export const TextFieldWithUpload = ({
  options,
  placeholder,
  id,
  onValueChanged,
  value,
  label,
  disabled,
  allowUpload,
  isLink,
  fullOptions,
  unique,
  fetch,
  disabledCustomValues,
  onRemoveItemClicked,
  endpointFunction = endpoints.autocomplete.get.prospects,
  filterFetchedOptions,
  uploadModalFields,
  textInputProps,
  fileInputProps,
  uploadButtonProps,
}: TextFieldWithUploadProps) => {
  const autoCompleteRef = useRef<HTMLDivElement>(null);
  const {
    openModal,
    setOpenModal,
    mainLabel,
    showConfirmUpload,
    csvData,
    formatedLabel,
    file,
    loadingFetch,
    inputValue,
    mapOptions,
    handleChange,
    fetchAutocompleteDebounced,
    handleFile,
    handleSubmit,
    setShowConfirmUpload,
    selectedColumns,
    setSelectedColumns,
    setInputValue,
  } = useAutoCompleteWithUpload({
    options,
    onValueChanged,
    value,
    label,
    unique,
    fetch,
    disabledCustomValues,
    autoCompleteRef,
    endpointFunction,
    filterFetchedOptions,
  });

  return (
    <div className={textFieldWithAutocompleteStyle(theme)}>
      <SimpleModal
        maxWidth="sm"
        open={openModal}
        setOpen={setOpenModal}
        disableConfirm={!selectedColumns.some((col) => col.value.trim())}
        title={'Upload ' + mainLabel}
        subtitle={
          showConfirmUpload
            ? `Uploading ${csvData.rows.length} rows.  We can only display 100 rows in your input`
            : `Select the column in your file containing the ${mainLabel}.`
        }
        children={
          showConfirmUpload ? (
            <Typography variant="body1">
              The remaining rows will be processed in the backend and reflected
              in your results when complete.
            </Typography>
          ) : (
            <CsvUploadModal
              label={formatedLabel}
              file={file}
              mainLabel={mainLabel}
              columns={csvData.columns}
              csvError={csvData.csvError}
              setSelectedColumn={(col) => {
                // Merge selected column names into array.
                setSelectedColumns(
                  // TODO: filter duplicates using uniqBy, and fix TS.
                  // TODO: extract to function higher up in the tree.
                  // @ts-expect-error
                  (selectedColumns) => {
                    return [...selectedColumns, col];
                  }

                  // uniq([...selectedColumns, col as string])
                );
              }}
              fields={uploadModalFields}
            />
          )
        }
        closeAfterSubmit={false}
        onConfirm={() => {
          if (!showConfirmUpload && csvData.rows.length > 100) {
            setShowConfirmUpload(true);
            setOpenModal(true);
            return;
          }
          handleSubmit();
        }}
        onCancel={() => setOpenModal(false)}
      />
      <Autocomplete
        id={id || 'auto-complete'}
        ref={autoCompleteRef}
        filterSelectedOptions
        value={value}
        multiple
        noOptionsText={
          fetch
            ? loadingFetch
              ? 'Loading...'
              : inputValue.length > 0
              ? 'No options found'
              : 'Type to search'
            : `No ${formatedLabel.toLowerCase()} found`
        }
        limitTags={unique ? 1 : undefined}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => {
          return option.value?.toLowerCase() === value.value?.toLowerCase();
        }}
        size="small"
        options={mapOptions()}
        className={autoCompleteStyle(theme)}
        onChange={handleChange}
        renderTags={(value, getTagProps) => (
          <AutoCompleteTags
            value={value.slice(0, 100) as Option[]}
            isLink={isLink || false}
            getTagProps={getTagProps}
            allowCsvUpload={allowUpload}
            length={value.length}
            handleDelete={onRemoveItemClicked}
          />
        )}
        renderInput={(params) => {
          const combinedParams = {
            ...params,
            inputProps: {
              ...params.inputProps,
              ...(textInputProps ? textInputProps : {}),
            },
          };

          return (
            <TextFieldInput
              inputValue={inputValue}
              setInputValue={fetch ? fetchAutocompleteDebounced : setInputValue}
              placeholder={placeholder || `Add ${formatedLabel}`}
              params={combinedParams}
            />
          );
        }}
        renderOption={(props, option) => {
          const optionAdapted = option as Option;
          const fullOption = fullOptions
            ? fullOptions.find((item) => item.title === optionAdapted.label)
            : null;

          if (!fullOption)
            return (
              <AutoCompleteOption
                key={optionAdapted.value}
                {...props}
                option={{
                  label: optionAdapted.label,
                  value: optionAdapted.value,
                  disabled: optionAdapted.disabled,
                }}
              />
            );

          return (
            <AutoCompleteOption
              key={optionAdapted.value}
              {...props}
              option={{
                label: fullOption.title,
                value: optionAdapted.value,
                description: fullOption.description,
              }}
            />
          );
        }}
      />
      {allowUpload && (
        <FileUpload
          buttonProps={{
            className: uploadBtnStyle(theme),
            ...uploadButtonProps,
          }}
          accept=".csv, .xls, .xlsx"
          label="Upload"
          handleFile={handleFile}
          inputProps={fileInputProps}
        />
      )}
    </div>
  );
};
