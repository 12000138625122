import React, { useCallback } from 'react';
import { fetchIsAuthenticated } from 'utils/fetchIsAuthenticated';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export interface UseSignInIfNeededOptions {
  screen_hint?: string;
}

/**
 * Return a function that redirects a user to the login page if they are not
 * currently authenticated.
 * @param screen_hint The screen hint to pass to Auth0 if and when the user gets
 * redirected to the login page.
 * @returns A function that redirects a user to the login page if they are not
 * currently authenticated. The authentication status is checked by fetching it
 * from the server directly, bypassing the RTK Query cache.
 *
 * @details Use this when a user arrives to the app from elsewhere. For regular
 * navigation within the app, use `withAppAuthenticationRequired` instead.
 */
export function useSignInIfNeeded({
  screen_hint,
}: UseSignInIfNeededOptions = {}) {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  return useCallback(() => {
    fetchIsAuthenticated()
      .then((isAuthenticated) =>
        isAuthenticated
          ? history.replace('/app/events/search')
          : loginWithRedirect({
              authorizationParams: {
                screen_hint,
              },
              appState: { returnTo: '/app/events/search' },
            })
      )
      .catch(console.error);
    return <></>;
  }, [history, loginWithRedirect, screen_hint]);
}
