import { Stack, Typography, Grid } from '@mui/material';
import { FilterBox } from '../../ProspectList/ICPBuilder/Filters/FilterBox';
import {
  WizardAnswer,
  WizardConfig,
  WizardMultipleChoiceQuestion,
} from '../WizardConfig';
import { WizardPagination } from '../WizardPagination';
import { useHistory } from 'react-router';
import { Wizard } from '../Wizard';

export interface MultipleChoiceQuestionProps<TWizard = Wizard<WizardConfig>> {
  wizard: TWizard;
  question: WizardMultipleChoiceQuestion;
  onAnswerClick: (answer: WizardAnswer) => void;
}

export function MultipleChoiceQuestion({
  wizard,
  question,
  onAnswerClick,
}: MultipleChoiceQuestionProps) {
  const history = useHistory();

  function onPreviousClick() {
    history.goBack();
  }

  return (
    <Stack spacing={8}>
      <Stack
        flexWrap="wrap"
        alignItems="flex-start"
        key={question.id}
        spacing={2}
      >
        <Typography fontWeight={500} variant="h6">
          {question.label}
        </Typography>
        <Grid
          container
          rowGap={3}
          columnGap={10}
          direction={'row'}
          flexWrap={'wrap'}
        >
          {question.answers?.map((answer, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <FilterBox
                  title={answer.label}
                  onClick={() => onAnswerClick(answer)}
                  activated={false}
                  icon={answer.icon}
                  slotProps={{
                    button: {
                      sx: {
                        width: '16rem',
                        height: '6rem',
                        justifyContent: 'flex-start',
                        alignItems: 'none',
                      },
                    },
                  }}
                  description={answer.description}
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
      <WizardPagination
        wizard={wizard}
        question={question}
        canGoForward={false}
        onPreviousClick={onPreviousClick}
      />
    </Stack>
  );
}
