import React, { FunctionComponent, useState } from 'react';
import { AutocompleteImpl } from './AutocompleteImpl';
import { urlToSearchState } from 'utils/algoliaUtils';

interface AutocompleteStandaloneProps {
  scrollFix?: () => void;
  isSearchPage?: boolean;
  rounded?: boolean;
}

export const AutocompleteStandalone: FunctionComponent<
  AutocompleteStandaloneProps
> = ({ scrollFix, isSearchPage, rounded }) => {
  const { searchState: urlSearchState } = urlToSearchState('');
  const [searchState, setSearchState] = useState(urlSearchState);

  return (
    <AutocompleteImpl
      routerPush={(url) => {
        if (window?.location) {
          window.location.href = url;
        }
      }}
      routerLocationSearch={''}
      searchState={searchState}
      setSearchState={setSearchState}
      scrollFix={scrollFix}
      isSearchPage={isSearchPage}
      rounded={rounded}
      size={'small'}
    />
  );
};
