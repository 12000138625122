import { RefinementHit, ExploreFilterButton } from './ExploreFilterButton';
import { ProspectListShort } from 'adapters/types';
import { prospectsApi } from 'redux/reducers/api/prospects';
import { SearchState } from 'react-instantsearch-core';
import { QueryParameters } from './types';
import { useQueryParams } from 'hooks/common/useQueryParams';
import { useAppDispatch } from 'redux/store';
import {
  setSelectedProspects,
  setSelectedProspectLists,
} from 'redux/features/search';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { arraysEqual } from 'utils/util';

// #region Types

interface ProspectListsForAlgoliaFilter
  extends Omit<ProspectListShort, 'orgs' | 'profiles'>,
    RefinementHit {
  orgs: string[];
  profiles: string[];
}

interface ChooseProspectsProps {
  searchState: SearchState;
  pushToHistoryWithNewSearchState: (
    state: SearchState,
    queryParameters: QueryParameters
  ) => void;
}

// #endregion

// formats the returned data from the API into useable data for the algolia filter
function formatProspectLists(
  prospectLists: ProspectListShort[],
  selectedProspects: string[]
): ProspectListsForAlgoliaFilter[] {
  return prospectLists
    .map((pl: ProspectListShort) => ({
      ...pl,
      orgs: pl.orgs.map((org) => org.name),
      profiles: pl.profiles.map((profile) => profile.name),
      isRefined: selectedProspects.includes(pl.uuid),
      label: pl.name,
      count: pl.profiles.length + pl.orgs.length,
      value: [pl.uuid],
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
}

// find the orgs and profiles of given prospect lists for use as a filter in algolia
const getSelectedProspectsForAlgoliaFilter = (
  pls: ProspectListsForAlgoliaFilter[]
): string[] => {
  if (!pls.length) return [];

  const selectedProspects = pls.reduce<string[]>((acc, cv) => {
    acc.push(
      ...cv.orgs.map((org) => `sponsors:'${org.replace(/'/g, "\\'")}'`),
      ...cv.profiles.map(
        (profile) => `attendees.n:'${profile.replace(/'/g, "\\'")}'`
      )
    );
    return acc;
  }, []);

  return selectedProspects;
};

export const ChooseProspects = ({
  searchState,
  pushToHistoryWithNewSearchState,
}: ChooseProspectsProps) => {
  const dispatch = useAppDispatch();
  const { queryParams } = useQueryParams(['prospect_lists']);
  const selectedProspectListUuids = queryParams.prospect_lists;
  const searchStateRedux = useAppSelector((state) => state.search);
  const [searchText, setSearchText] = useState('');

  const { data, isLoading } =
    prospectsApi.useGetMyProspectListsShortQuery(true);

  const allProspectLists = useMemo(
    () =>
      !isLoading && data?.success
        ? formatProspectLists(data.prospect_lists, selectedProspectListUuids)
        : [],
    [data, isLoading, selectedProspectListUuids]
  );

  // get the prospect lists that are currently refined
  const refinedProspectListsFromUrl = allProspectLists.filter((pl) => {
    return pl.isRefined;
  });

  const refine = useCallback(
    (vals: string[]) => {
      if (isLoading) return;

      // create a set for the vals
      const valsSet = new Set(vals);

      // get the prospect lists that are currently refined
      const refinedProspectLists =
        allProspectLists.filter((pl) => valsSet.has(pl.uuid)) ?? [];

      if (refinedProspectLists.length) {
        dispatch(
          setSelectedProspects(
            getSelectedProspectsForAlgoliaFilter(refinedProspectLists)
          )
        );
        dispatch(setSelectedProspectLists(vals));
        pushToHistoryWithNewSearchState(searchState, {
          prospect_lists: refinedProspectLists.map((pl) => pl.uuid),
        });
      } else {
        dispatch(setSelectedProspects([]));
        dispatch(setSelectedProspectLists([]));
        pushToHistoryWithNewSearchState(searchState, {
          prospect_lists: [],
        });
      }
    },
    [
      allProspectLists,
      dispatch,
      isLoading,
      pushToHistoryWithNewSearchState,
      searchState,
    ]
  );

  // update the redux store when the prospect list data has finished loading
  useEffect(() => {
    if (!isLoading && data?.success) {
      const selectedProspectLists = allProspectLists.filter((pl) =>
        selectedProspectListUuids.includes(pl.uuid)
      );
      const selectedProspects = getSelectedProspectsForAlgoliaFilter(
        selectedProspectLists
      );
      if (!arraysEqual(selectedProspects, searchStateRedux.selectedProspects)) {
        dispatch(setSelectedProspects(selectedProspects));
        dispatch(setSelectedProspectLists(selectedProspectListUuids));
      }
    }
  }, [
    dispatch,
    searchStateRedux.selectedProspects,
    isLoading,
    allProspectLists,
    selectedProspectListUuids,
    data,
  ]);

  // various labels and pluralization for the button / filter
  const label =
    refinedProspectListsFromUrl.length > 0 &&
    !isLoading &&
    allProspectLists.length !== 0
      ? `${refinedProspectListsFromUrl[0].label}${
          refinedProspectListsFromUrl.length > 1
            ? ` + ${refinedProspectListsFromUrl.length - 1}`
            : ''
        }`
      : 'Prospects attending';

  const searchForItems = (newText: string) => {
    setSearchText(newText);
  };

  return (
    <ExploreFilterButton
      customRefine={refine}
      customItems={allProspectLists.filter((pl) =>
        pl.name.toLowerCase().startsWith(searchText.toLowerCase())
      )}
      label={label}
      placeholder="Search for your prospect lists"
      isLoading={isLoading}
      attribute="prospect_lists"
      customSearchForItems={searchForItems}
    />
  );
};
