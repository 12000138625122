import { FC, PropsWithChildren } from 'react';
import { useUserState } from 'hooks/common/useUserState';
import { CircularProgress } from '@mui/material';
import { UserIconWithDropdown } from './UserIconWithDropDown';

export type UserIconWithFallbackProps = PropsWithChildren<{
  /**
   * Customize rendering the user icon component.
   *
   * The user icon dropdown component will be passed to this callback as its
   * children. The default value is: `({ children }) => <>{children}</>`
   */
  render?: FC;
}>;

/**
 * Display the user icon component if a user is authenticated, the given
 * fallback content if not, and a progress spinner if the user state is
 * still loading.
 *
 * @param children The fallback content to display if the user is not
 * authenticated.
 */
export function UserIconWithFallback({
  children: fallback,
  render: Render = ({ children }) => <>{children}</>,
}: UserIconWithFallbackProps) {
  const { isAuthenticated, isFetching } = useUserState();
  if (!isAuthenticated && isFetching) {
    return <CircularProgress />;
  } else if (!isAuthenticated) {
    return <>{fallback}</>;
  } else {
    return (
      <Render>
        <UserIconWithDropdown />
      </Render>
    );
  }
}
