import React from 'react';
import { useTheme, Stack, Typography, Tooltip } from '@mui/material';
import { Option } from 'hooks/Prospects/useIcpFilter';

export function AutoCompleteOption({
  option,
  ...props
}: {
  option: Option & {
    description?: string;
  };
}) {
  const theme = useTheme();

  return (
    <Tooltip title={option.disabled ? 'Upgrade to unlock CRM features' : ''}>
      <Stack
        {...props}
        {...(option.disabled && { sx: { opacity: 0.5 }, onClick: undefined })}
        direction="row"
        gap={theme.spacing(1)}
      >
        <Stack>
          <Typography
            fontWeight={theme.typography.fontWeightMedium}
            textTransform="capitalize"
          >
            {option.label}
          </Typography>
          <Typography fontSize={theme.typography.fontSize}>
            {option?.description}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
}
