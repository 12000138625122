import React, {
  ReactChild,
  ReactFragment,
  ReactPortal,
  CSSProperties,
  MouseEventHandler,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserState } from './useUserState';

interface DisabledPillProps {
  tooltip: {
    title: boolean | ReactChild | ReactFragment | ReactPortal;
    disableHoverListener?: boolean;
  };
  span: {
    onClick?: MouseEventHandler;
    style?: CSSProperties;
  };
}

/**
 * Create the properties to pass to the wrapper elements of a disabled pill
 */
export function useDisabledPill(
  disabled: boolean,
  disabledTooltip: string,
  customLink?: string
): DisabledPillProps {
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useUserState();

  if (!disabled || disabledTooltip === '') {
    return {
      tooltip: {
        title: false,
        disableHoverListener: true,
      },
      span: {},
    };
  }

  return {
    tooltip: {
      title: <>{disabledTooltip}</>,
    },
    span: {
      onClick: !isAuthenticated
        ? () =>
            loginWithRedirect({
              authorizationParams: {
                screen_hint: 'signup',
              },
            }).catch(console.error)
        : () => (window.location.href = customLink ?? '/pricing'),
      style: {
        cursor: 'pointer',
      },
    },
  };
}
